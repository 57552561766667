import React from 'react'
import { useDispatch } from 'react-redux'
import { Trans } from 'react-i18next'

import { getDateFromUnix, getYearFromUnix } from 'utils/date'
import TooltipText from 'components/TooltipTextComp'
import { TDocumentDetails } from 'state/liteDocuments/types'
import TextComp, { BODY_LEVEL } from 'components/TextComp'
import Container from 'components/Container'
import ButtonComp from 'components/ButtonComp/ButtonCopm'
import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from 'utils/mui'
import { setMlt, setShowMltModal } from 'state/mainFilter/actions'
import { isR2Document } from 'utils/document'

import './styles.scss'

type TProps = {
  documentId: string
  title: string
  showMltButton: boolean
  details: TDocumentDetails
  type: string
  creationDate: string
  isFullView?: boolean
}

export default function DocumentInfo({
  documentId,
  creationDate,
  details,
  isFullView,
  title,
  showMltButton,
  type
}: TProps) {
  const dispatch = useDispatch()
  const { source_id } = details

  const handleMLT = (e: any) => {
    e.stopPropagation()
    dispatch(setMlt({ documentId, title, source_id }))
    dispatch(setShowMltModal(true))
  }

  const seleniumIdPrefix = isFullView ? 'document-full-view' : `document-list_item_${source_id}`
  
  const getDateLabel = () => {
    if (isR2Document(type)) {
      const year = details.year ?? getYearFromUnix(+creationDate)
      return <TextComp
        seleniumId={`${seleniumIdPrefix}_year`}
        color={COMPONENT_COLOR.NEUTRAL}
        level={BODY_LEVEL.MEDIUM}
        className='mr-6'
      >
        Budget year: {year}
      </TextComp>
    } else {
      return <TextComp
        seleniumId={`${seleniumIdPrefix}_creation-date`}
        color={COMPONENT_COLOR.NEUTRAL}
        level={BODY_LEVEL.MEDIUM}
      >
        {getDateFromUnix(+creationDate)}
      </TextComp>
    }
  }

  return (
    <Container className={'document_info'} flex={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Container className={'document_info__date'} direction={'row'} spacing={1}>
        {getDateLabel()}
        {details.service_agency_name && (
          <TextComp seleniumId={`${seleniumIdPrefix}_service-agency`} color={COMPONENT_COLOR.NEUTRAL} level={BODY_LEVEL.MEDIUM}>
            <TooltipText>{details.service_agency_name}</TooltipText>
          </TextComp>
        )}
      </Container>
      <Container className={'document_info__similar'} direction={'row'}>
        {showMltButton &&
          <ButtonComp
            seleniumId={`${seleniumIdPrefix}_show-similar`}
            color={COMPONENT_COLOR.SUCCESS}
            size={COMPONENT_SIZE.SMALL}
            type={COMPONENT_VARIANT.LINK}
            onClick={handleMLT}
          >
            <Trans i18nKey={'main.document.mlt'}/>
          </ButtonComp>
        }
      </Container>
    </Container>
  )
}
