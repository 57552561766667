import React from 'react'
import { CircularProgress, CircularProgressProps } from '@mui/joy'

import './style.scss'
import { COMPONENT_SIZE } from '../../utils/mui'

const LoadingComp = ({ 
  className, 
  variant, 
  size = COMPONENT_SIZE.SMALL,
  color,
  value,
  thickness = 2
}: CircularProgressProps) => {

  return (
    <div className={`spinner_container ${className}`}>
      <CircularProgress
        thickness={thickness}
        value={value}
        variant={variant}
        size={size}
        color={color}
      />
    </div>
  )
}

export default LoadingComp
