import React from 'react'
import { Helmet } from 'react-helmet'

type TProps = {
  title: string,
  url: string
}
export default function MetaInfo({ title, url }: TProps) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {title && <meta property="og:title" content={title} />}
      {title && <meta property="og:description" content={title} />}
      <meta property="og:url" content={url}/>
      <link rel="canonical" href={url} />
    </Helmet>
  )
}