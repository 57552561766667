import React from 'react'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux'
import { Select as Dropdown, Option } from '@mui/joy'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { selectDateRange, selectDateRangeMode } from 'state/mainFilter/selectors'
import { setDateRange, setDateRangeMode } from 'state/mainFilter/actions'
import { selectHistogramSelector } from 'state/liteDocuments/selectors'
import { getDateRangePeriod } from 'utils/mainFilter'
import Container from 'components/Container'
import RangePickerComp from 'components/RangePickerComp/RangePicker'
import { ALL_TIME } from 'constants/global'

import './style.scss'

export enum DATE_MODES {
  ALL = 'All',
  CUSTOM = 'custom',
  LAST_1_YEAR = '1_years',
  LAST_4_YEARS = '4_years',
  LAST_10_YEARS = '10_years',
  LAST_50_YEARS = '50_years',
  LAST_100_YEARS = '100_years',
}

const MODE_OPTIONS = [
  { label: ALL_TIME, value: DATE_MODES.ALL },
  { label: 'Last century', value: DATE_MODES.LAST_100_YEARS },
  { label: 'Last half century', value: DATE_MODES.LAST_50_YEARS },
  { label: 'Last decade', value: DATE_MODES.LAST_10_YEARS },
  { label: 'Last quadrennial', value: DATE_MODES.LAST_4_YEARS },
  { label: 'Last year', value: DATE_MODES.LAST_1_YEAR },
  { label: 'Custom range', value: DATE_MODES.CUSTOM },
]

export default function DateRange() {
  const dispatch = useDispatch()
  const rangeMode = useSelector(selectDateRangeMode)
  const dateRange = useSelector(selectDateRange)
  const histogramData = useSelector(selectHistogramSelector)

  const minDate = histogramData.length > 0 && histogramData[0][0] ? dayjs(histogramData[0][0]) : undefined
  const maxDate = histogramData.length > 0 && histogramData[histogramData.length - 1][0] ? dayjs(histogramData[histogramData.length - 1][0]) : undefined

  const handleModeChange = (mode: DATE_MODES) => {
    dispatch(setDateRangeMode(mode))
    if (mode === DATE_MODES.CUSTOM) return
    dispatch(setDateRange(getDateRangePeriod(mode)))
  }

  const handleDatePicker = (date: dayjs.ConfigType[]) => {
    dispatch(setDateRange(date))
  }

  return (
    <div className='search-lite-select'>
      <Container spacing={1} direction={'row'}>
        <Dropdown
          selenium-id='search-lite_period'
          sx={{ minWidth: isMobile ? 105 : 150 }}
          defaultValue={DATE_MODES.ALL}
          value={rangeMode}
          indicator={<KeyboardArrowDown />}
        >
          {MODE_OPTIONS.map(it => <Option key={it.value} value={it.value} onClick={() => handleModeChange(it.value)}>{it.label}</Option>)}
        </Dropdown>
        {rangeMode === DATE_MODES.CUSTOM && (
          <Container className='search-lite_range-picker' seleniumId='search-lite_range-picker'>
            <RangePickerComp
              // @ts-ignore
              value={dateRange}
              className="date-range-picker-mui"
              onChange={handleDatePicker}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Container>
        )}
      </Container>
    </div>
  )
}
