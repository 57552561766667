export const isCustomColor = (c: string) =>
  Object.keys(customColorsObj).find((cc: string) => c === cc)

export const customColorsObj = {
  azure: '#74ABE2',
  salmon: '#EF8D5D',
  jade: '#3FB68E',
  rose: '#F06A93',
  lavender: '#A97DD8',
  turquoise: '#3AB5C2',
  periwinkle: '#6973F6',
  magenta: '#CD59B1',
  seaglass: '#8CA2AB',
  tawny: '#F38787',
  slate: '#4D78A2',
}

export const customColorsObjRGBA = {
  azure: 'rgba(116, 171, 226, 1)',
  salmon: 'rgba(239, 141, 93, 1)',
  jade: 'rgba(63, 182, 142, 1)',
  rose: 'rgba(240, 106, 147, 1)',
  lavender: 'rgba(169, 125, 216, 1)',
  turquoise: 'rgba(58, 181, 194, 1)',
  periwinkle: 'rgba(105, 115, 246, 1)',
  magenta: 'rgba(205, 89, 177, 1)',
  seaglass: 'rgba(140, 162, 171, 1)',
  tawny: 'rgba(243, 135, 135, 1)',
  slate: 'rgba(77, 120, 162, 1)',
};

export const customColors = {
  azure: {
    900: '#436E9E',
    800: '#4E79A9',
    700: '#5A88B5',
    600: '#6797C1',
    500: '#74ABE2',
    400: '#91BCED',
    300: '#A5C9F1',
    200: '#B9D6F5',
    100: '#CDE3F9',
    50: '#E1F0FD'
  },
  salmon: {
    900: '#D7724A',
    800: '#DE8560',
    700: '#E49775',
    600: '#E9AA8A',
    500: '#EF8D5D',
    400: '#F4A67D',
    300: '#F6B693',
    200: '#F9C9A8',
    100: '#FBDDBD',
    50: '#FDE2C9'
  },
  jade: {
    900: '#2F8A65',
    800: '#3A9E78',
    700: '#45B28C',
    600: '#50C6A0',
    500: '#3FB68E',
    400: '#6FD1B3',
    300: '#8FDAC6',
    200: '#AFE4D9',
    100: '#CFFEEC',
    50: '#E6FFF6'
  },
  rose: {
    900: '#E54E74',
    800: '#EE6389',
    700: '#F7779E',
    600: '#FF8CB3',
    500: '#F06A93',
    400: '#F489A7',
    300: '#F7A8BB',
    200: '#FAC7CF',
    100: '#FCE6E4',
    50: '#FEEEF0'
  },
  lavender: {
    900: '#8C63B8',
    800: '#9971C3',
    700: '#A67FD0',
    600: '#B48CDB',
    500: '#A97DD8',
    400: '#BE9DE0',
    300: '#CAB4E4',
    200: '#D6CAE8',
    100: '#E2E0EC',
    50: '#EAE7F1'
  },
  turquoise: {
    900: '#2E8C9D',
    800: '#3E9FAE',
    700: '#4FB2BF',
    600: '#5FC5D0',
    500: '#3AB5C2',
    400: '#6FD0D9',
    300: '#8FD8DE',
    200: '#AFDFE3',
    100: '#CFF7F6',
    50: '#E6FFFA'
  },
  periwinkle: {
    900: '#4C51CC',
    800: '#5A61D3',
    700: '#686FD9',
    600: '#777EE0',
    500: '#6973F6',
    400: '#8D94F9',
    300: '#A3A8FA',
    200: '#B8BAFB',
    100: '#CDDCFC',
    50: '#E3EDFD'
  },
  magenta: {
    900: '#AF3D87',
    800: '#BF4E97',
    700: '#CE5FA8',
    600: '#DE70B8',
    500: '#CD59B1',
    400: '#E482C1',
    300: '#EB9ED0',
    200: '#F3BADF',
    100: '#FAC6E9',
    50: '#FDEAF7'
  },
  seaglass: {
    900: '#6F8992',
    800: '#7C95A0',
    700: '#8BA1AD',
    600: '#99AEBB',
    500: '#8CA2AB',
    400: '#A5B4BD',
    300: '#B1C0CA',
    200: '#BDCBD8',
    100: '#C9D6E5',
    50: '#D4E2F3'
  },
  tawny: {
    900: '#D97171',
    800: '#E27F7F',
    700: '#EB8D8D',
    600: '#F39B9B',
    500: '#F38787',
    400: '#F8A3A3',
    300: '#FAB0B0',
    200: '#FCCDCD',
    100: '#FDEAEA',
    50: '#FEF5F5'
  },
  slate: {
    900: '#3B5C80',
    800: '#476D91',
    700: '#537EA2',
    600: '#5F8FA3',
    500: '#4D78A2',
    400: '#6B8EA9',
    300: '#7DA1B3',
    200: '#8FB5BD',
    100: '#A1C8C7',
    50: '#B3DBD1'
  }
}
