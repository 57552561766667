import React from 'react'
import { Stack, StackProps } from '@mui/joy'

type TProps = {
  seleniumId?: string
}
export default function Container(
  {
    id,
    children,
    direction,
    justifyContent,
    alignItems,
    spacing,
    className,
    sx,
    onClick,
    seleniumId,
  }: StackProps & TProps) {
  return (
    <Stack
      selenium-id={seleniumId}
      id={id}
      onClick={onClick}
      className={className}
      direction={direction}
      justifyContent={justifyContent}
      alignItems={alignItems}
      spacing={spacing}
      sx={sx}
    >
      {children}
    </Stack>
  )
}
