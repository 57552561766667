import ActionTypes from './action-types'
import { createReducer } from '../utils'
import initState from './initState'
import { TDocumentLite, TFeedbackStorage, THistogram, TLiteDocumentsState } from './types'

const mapReducerRaw = createReducer<TLiteDocumentsState, string, any>(initState, {
  [ActionTypes.setIsLoading](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      isLoading: payload,
    }
  },
  [ActionTypes.setList](state, { payload, resetState }: { payload: TDocumentLite[], resetState: boolean }) {
    return {
      ...state,
      list: resetState ? payload : [...state.list, ...payload],
    }
  },
  [ActionTypes.setIsOpen](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      isOpen: payload,
    }
  },
  [ActionTypes.setViewType](state, { payload }: { payload: string }) {
    return {
      ...state,
      viewType: payload,
    }
  },
  [ActionTypes.setLang](state, { payload }: { payload: string }) {
    return {
      ...state,
      lang: payload,
    }
  },
  [ActionTypes.setTotal](state, { payload }: { payload: number }) {
    return {
      ...state,
      total: payload,
    }
  },
  [ActionTypes.setFetchedDocumentsCount](state, { payload }: { payload: number }) {
    return {
      ...state,
      fetchedDocumentCount: payload,
    }
  },
  [ActionTypes.setIsDocLoading](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      isDocLoading: payload,
    }
  },
  [ActionTypes.setDocument](state, { payload }: { payload: TDocumentLite }) {
    return {
      ...state,
      fullDocument: payload,
    }
  },
  [ActionTypes.setIsHistogramLoading](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      histogramLoading: payload,
    }
  },
  [ActionTypes.setHistogramData](state, { payload }: { payload: THistogram[] }) {
    return {
      ...state,
      histogram: payload,
    }
  },
  [ActionTypes.setFirstLoading](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      firstLoading: payload,
    }
  },
  [ActionTypes.setMltList](state, { payload }: { payload: TDocumentLite[] }) {
    return {
      ...state,
      mltList: payload,
    }
  },
  [ActionTypes.setPdfMode](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      isPdfMode: payload,
    }
  },
  [ActionTypes.setPositiveFeedback](state, { payload }: { payload: TFeedbackStorage[] }) {
    return {
      ...state,
      positiveFeedback: payload,
    }
  },
  [ActionTypes.setNegativeFeedback](state, { payload }: { payload: TFeedbackStorage[] }) {
    return {
      ...state,
      negativeFeedback: payload,
    }
  },
  [ActionTypes.setSnippetDocumentId](state, { payload }: { payload: string | null }) {
    return {
      ...state,
      snippetDocumentId: payload,
    }
  },
  [ActionTypes.setDocumentSorting](state, { payload }: { payload: string }) {
    return {
      ...state,
      sorting: payload,
    }
  },
  [ActionTypes.setOpenedDocumentId](state, { payload }: { payload: string | null}) {
    return {
      ...state,
      openedDocumentId: payload,
    }
  },
})

export default mapReducerRaw
