import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsOpen as setIsFilterOpen } from 'state/mainFilter/actions'
import { selectSelectedFilterItemsSelector } from 'state/mainFilter/selectors'
import { ReactComponent as Filter } from 'images/icons/filter.svg'
import { ReactComponent as FilterSolid } from 'images/icons/filterSolid.svg'

import './styles.scss'

export default function FilterBlock() {
  const dispatch = useDispatch()

  const selectedItems = useSelector(selectSelectedFilterItemsSelector)
  const filtersCount = selectedItems.length;

  const handleFilterClick = () => {
    dispatch(setIsFilterOpen(true))
  }

  const hasFilter = filtersCount > 0

  return (
    <div selenium-id='main-filter_open-button' className={`filter_closed ${hasFilter ? 'solid' : ''}`} onClick={handleFilterClick}>
      <div className='filter_closed_icon'>
        {hasFilter  ? <FilterSolid/> : <Filter/>}
      </div>
      {hasFilter && (
        <span className='applied-wrapper'>{filtersCount}</span>
      )}
    </div>
  )
}
