import React from 'react'
import SearchIcon from '@mui/icons-material/Search';
import TextComp, { BODY_LEVEL, H_LEVEL } from '../TextComp'
import Container from '../Container'

import './styles.scss'
import { Trans } from 'react-i18next'

type TNoResultsFoundProps = {
  title: string,
}

const NoResultsFound = ({
  title,
}: TNoResultsFoundProps) => {

  // const handleCopyUrl = () => {
  //   navigator.clipboard.writeText(window.location.href)
  // }

  return (
    <Container direction={'row'} className={'no_results_found'} spacing={1}>
      <Container>
        <SearchIcon />
      </Container>
      <Container spacing={1}>
        <TextComp level={H_LEVEL.H3} alignItems={'center'}>{title}</TextComp>
        <TextComp level={BODY_LEVEL.LARGE}>
          <Trans i18nKey='main.document.no_results_description_start' />
          {/*<ButtonComp color={COMPONENT_COLOR.SUCCESS} type={COMPONENT_VARIANT.LINK} size={'xs'} onClick={handleCopyUrl}>*/}
          {/*  <Trans i18nKey='main.document.share_your_query' />*/}
          {/*</ButtonComp>*/}
          <Trans i18nKey='main.document.no_results_description_end' />
        </TextComp>
      </Container>
    </Container>
  )
}

export default NoResultsFound;
