import React from 'react'
import classnames from 'classnames'
import { Modal, ModalOverflow, ModalDialog, ModalProps } from '@mui/joy'
import { Transition } from 'react-transition-group'
import { isMobile } from 'react-device-detect'

import './style.scss'

const ModalComp: React.FC<ModalProps & { width?: number, grayBackground?: boolean, fullContent?: boolean }> = ({
  children,
  open,
  onClose,
  className= '',
  keepMounted= false,
  width = 400,
  grayBackground = false,
  fullContent = false,
}) => {
  const classNames = classnames('modal_comp', className, { 'modal_comp_gray' : grayBackground } )
  const getSlotProps = (state: string) => {
    return  keepMounted  ? {
      backdrop: {
        sx: {
          opacity: 0,
          backdropFilter: 'none',
          transition: 'opacity 400ms, backdrop-filter 400ms',
          ...{
            entering: { opacity: 1, backdropFilter: 'blur(8px)' },
            entered: { opacity: 1, backdropFilter: 'blur(8px)' },
          }[state],
        }
      }
    } : {}
  }

  const getDialogSx = (state: string) => {
    return keepMounted ? {
      width,
      opacity: 0,
      transition: 'opacity 300ms',
      ...{
        entering: { opacity: 1 },
        entered: { opacity: 1 },
      }[state],
    } : { width }
  }

  return (
    <Transition in={open} timeout={400}>
      {(state: string) => (
        <Modal
          keepMounted={keepMounted}
          onClose={onClose}
          open={open}
          className={classNames}
          slotProps={getSlotProps(state)}
          sx={{
            visibility: state === 'exited' ? 'hidden' : 'visible',
          }}
        >
          <ModalOverflow className={'modal_overflow'}>
            <ModalDialog aria-labelledby="modal-dialog-overflow"
              sx={getDialogSx(state)}
              layout={isMobile ? 'fullscreen' : 'center'}
              className={classnames({ 'modal_comp__dialog' : fullContent })}
            >
              {children}
            </ModalDialog>
          </ModalOverflow>
        </Modal>)}
    </Transition>
  )
}

export default React.memo(ModalComp)
