import { ThunkAction, ThunkDispatch } from 'state/types'
import dayjs from 'dayjs'
import axiosInstance from 'api/api'
import API from 'api/urls'
import { DOCUMENTS_LIMIT } from 'constants/global'
import {
  TDocumentLite,
  TFeedbackStorage,
  TFetchDocumentIdAction,
  TGenerateLink,
  THistogram,
} from './types'
import ActionTypes from './action-types'
import {
  serializeClickedNodeEdgeToQuery,
  serializeDateRangeQuery,
  serializeFilterMltToQuery,
  serializeFilterToQuery,
  serializeSortToQuery,
} from 'utils/mainFilter'
import { deserializeHistogramData } from 'utils/histogram'
import { TClickedNodeOrEdgeState } from 'state/graph/types';
import { getQueryString } from 'utils/global'
import { TMainFilterItem, TSetMltProps } from '../mainFilter/types'
import notificator from 'services/notifications.service';
import axios from 'axios';

export const setList = (value: TDocumentLite[], resetState: boolean) => ({
  type: ActionTypes.setList,
  payload: value,
  resetState
})

export const setIsLoading = (value: boolean) => ({
  type: ActionTypes.setIsLoading,
  payload: value,
})

export const setFirstLoading = (value: boolean) => ({
  type: ActionTypes.setFirstLoading,
  payload: value,
})

export const setIsOpen = (value: boolean) => ({
  type: ActionTypes.setIsOpen,
  payload: value,
})

export const setLang = (value: string) => ({
  type: ActionTypes.setLang,
  payload: value,
})

export const setViewType = (value: string) => ({
  type: ActionTypes.setViewType,
  payload: value,
})

export const setTotal = (value: number) => ({
  type: ActionTypes.setTotal,
  payload: value,
})

export const setDocumentsOffsetCount = (value: number) => ({
  type: ActionTypes.setFetchedDocumentsCount,
  payload: value,
})

export const setFullDocument = (value: TDocumentLite | null) => ({
  type: ActionTypes.setDocument,
  payload: value,
})

export const setFullDocLoading = (value: boolean) => ({
  type: ActionTypes.setIsDocLoading,
  payload: value,
})

export const setHistogramList = (value: THistogram[]) => ({
  type: ActionTypes.setHistogramData,
  payload: value,
})

export const setHistogramLoading = (value: boolean) => ({
  type: ActionTypes.setIsHistogramLoading,
  payload: value,
})

export const setMltList = (value: TDocumentLite[]) => ({
  type: ActionTypes.setMltList,
  payload: value,
})

export const setPdfMode = (value: boolean) => ({
  type: ActionTypes.setPdfMode,
  payload: value,
})

export const setPositiveFeedback = (value: TFeedbackStorage[]) => ({
  type: ActionTypes.setPositiveFeedback,
  payload: value,
})

export const setNegativeFeedback = (value: TFeedbackStorage[]) => ({
  type: ActionTypes.setNegativeFeedback,
  payload: value,
})

export const setSnippetDocumentId = (value: string | null) => ({
  type: ActionTypes.setSnippetDocumentId,
  payload: value,
})

export const setOpenedDocumentId = (value: string | null) => ({
  type: ActionTypes.setOpenedDocumentId,
  payload: value,
})

export const setSorting = (value: string) => ({
  type: ActionTypes.setDocumentSorting,
  payload: value,
})

export type TFetchDocumentList = {
  query: string,
  page: number
  limit: number
  filter: TMainFilterItem[]
  dateRange: dayjs.ConfigType[]
  sort: string
  clickedNodeOrEdge?: TClickedNodeOrEdgeState | null
  cancelToken?: any
}
export const fetchDocumentsLiteAction = ({
  query = '',
  cancelToken,
  page = 0,
  limit = DOCUMENTS_LIMIT,
  filter = [],
  dateRange = [],
  clickedNodeOrEdge = null,
  sort
}: TFetchDocumentList): ThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    try {
      if (!page) {
        dispatch(setIsLoading(true))
      }

      if (!page && getState().liteDocuments.list.length > 0) {
        dispatch(setList([], true))
      }

      const response = await axiosInstance.get(API.public.getDocuments, {
        params: { query: query.trim(), limit, offset: page },
        paramsSerializer: function paramsSerializer(params: object) {
          const queryString = getQueryString(params)
          return queryString
            + serializeDateRangeQuery(dateRange)
            + serializeFilterToQuery(filter)
            + serializeSortToQuery(sort)
            + serializeClickedNodeEdgeToQuery(clickedNodeOrEdge)
        },
        cancelToken
      })

      if (response.data) {
        dispatch(setList(response.data.data, !page))
        dispatch(setTotal(response.data.total))

        if (query) {
          const offset = Math.ceil(getState().liteDocuments.list.length / DOCUMENTS_LIMIT) * DOCUMENTS_LIMIT
          dispatch(setDocumentsOffsetCount(offset))
        }
        dispatch(setIsLoading(false))
      }

      return response.data
    } catch (e) {
      // empty
    }
  }
}

export type TFetchMltListActionPayload = {
  query: string,
  cancelToken: any,
  page: number,
  limit: number,
  filter: TMainFilterItem[],
  dateRange: dayjs.ConfigType[],
  mlt: TSetMltProps | null,
}

export const fetchMltListAction = ({
  query = '',
  cancelToken,
  page = 0,
  limit = DOCUMENTS_LIMIT,
  filter = [],
  dateRange,
  mlt = null
}: TFetchMltListActionPayload): ThunkAction<Promise<void>>  => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true))

      const response = await axiosInstance.get(API.public.getDocuments, {
        params: { query: query.trim(), limit, offset: page },
        paramsSerializer: function paramsSerializer(params: any) {
          const query = Object.entries(Object.assign({}, params)).map(([key, value]) => `${key}=${value}`).join('&');
          return query
            + serializeDateRangeQuery(dateRange)
            + serializeFilterToQuery(filter)
            + serializeFilterMltToQuery(mlt)
        },
        cancelToken
      })

      if (response.data) {
        dispatch(setMltList(response.data.data))
      }
    } catch (e) {
      // empty
    }
    finally {
      dispatch(setIsLoading(false))
    }
  }
}

export const fetchDocumentIdAction = ({
  document_indicator,
}: TFetchDocumentIdAction): any => {
  return async (dispatch: ThunkDispatch) => {
    const source = axios.CancelToken.source();
    try {
      const response = await axiosInstance.get(API.public.getDocumentId(document_indicator), {
        cancelToken: source.token
      })
      if (!response?.data?.document_id) return notificator.error()
      dispatch(setOpenedDocumentId(response.data.document_id))
    } catch (e) {
      notificator.error()
    }
  }
}

export const fetchSingleDocumentAction = (
  id: string,
): ThunkAction<Promise<void>> => {
  return async (dispatch) => {
    try {
      dispatch(setFullDocLoading(true))
      const source = axios.CancelToken.source();
      const response = await axiosInstance.get(API.public.getDocumentById(id), {
        cancelToken: source.token
      })

      dispatch(setFullDocument(response.data))
    } catch (e) {
      /* empty */
    }
    finally {
      dispatch(setFullDocLoading(false))
    }
  }
}

export const fetchHistogramAction = (
  query: string = '',
  cancelToken: any,
  filter: TMainFilterItem[] = [],
): ThunkAction<Promise<void>> => {
  return async (dispatch) => {
    try {
      dispatch(setHistogramLoading(true))

      const response = await axiosInstance.get(API.public.getHistogram, {
        params: { query: query.trim() },
        paramsSerializer: function paramsSerializer(params: object) {
          const queryString = getQueryString(params)
          return queryString + serializeFilterToQuery(filter)
        },
        cancelToken
      })

      dispatch(setHistogramList(deserializeHistogramData(response.data)))
    } catch (e) {
      /* empty */
    }
    finally {
      dispatch(setHistogramLoading(false))
    }
  }
}

export const fetchDocumentPDFAction = (
  id: string,
  cancelToken: any,
) => {
  return async () => {
    try {
      const response = await axiosInstance.get(API.public.getDocumentPDFById(id), {
        cancelToken,
        responseType: 'blob',
      })
      const pdfBlob = response.data
      return await new Response(pdfBlob).arrayBuffer()
    } catch (e) {
      /* empty */
    }
  }
}

export const generateLink = ({
  documentId,
}: TGenerateLink): any => {
  return async () => {
    try {
      const source = axios.CancelToken.source();
      const response = await axiosInstance.get(API.public.generateLink(documentId), {
        cancelToken: source.token
      })
      return response.data
    } catch (e) {
      notificator.error()
    }
  }
}
