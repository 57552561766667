import EN from './en/index'

export const languagesConst = {
  en: { value: 'en', label: 'EN' },
}

const languagesList = {
  [languagesConst.en.value]: {
    translation: EN,
  },
}

export default languagesList
