import React from 'react'

import SkeletonItem from './Item';


const SkeletonLoading = () => {
  return (
    <div className="skeleton-loading">
      <SkeletonItem />
      <SkeletonItem />
      <SkeletonItem />
    </div>
  );
}

export default React.memo(SkeletonLoading)
