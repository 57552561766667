import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider } from '@mui/joy'

import { TDocumentHighlight, TDocumentHighlightModel, TDocumentLite } from 'state/liteDocuments/types'
import { TSetMltProps } from 'state/mainFilter/types';
import { DOCUMENT_DESCRIPTION, DOCUMENT_HIGHLIGHTS_FIELDS, DOCUMENT_TYPES } from 'constants/document'
import { groupBy } from 'utils/global'
import { textHighlighting } from 'utils/highlightText'
import { isR2Project } from 'utils/document'
import Container from 'components/Container'
import TextComp, { BODY_LEVEL, H_LEVEL } from 'components/TextComp'

import DocumentContent from '../../components/Content'
import TagsTabsComponent from '../../components/TagsTabs'
import DocumentInfo from '../../components/Info'
import DocumentTypeHeader from '../../components/DocumentType'
import CollapseDoc from '../../components/CollapseDoc'
import AboveHeaderDocument from 'pages/Public/MainPage/Documents/components/AboveHeaderDocument';

import './style.scss'

type TProps = {
  document: TDocumentLite,
  mlt?: TSetMltProps | null,
  highlights?: TDocumentHighlight[]
  childHighlights?: TDocumentHighlightModel
}

const DetailsComponent: React.FC<TProps> = ({ document, mlt, highlights, childHighlights }) => {
  const { t } = useTranslation()
  const tags = [...document.entities, ...document.tags]
  const descriptionLabel = DOCUMENT_DESCRIPTION[document.type]
  const contentLabel = descriptionLabel ? t(descriptionLabel) : undefined
  const showMltButton = !mlt && document.details.mlt
  const showDocumentInfo = !isR2Project(document.type) || showMltButton

  const isCollapse = [DOCUMENT_TYPES.R2_ACCOMPLISHMENT_PLANNED_PROGRAM, DOCUMENT_TYPES.R2_COST_ITEM].includes(document.type)
  if (isCollapse) {
    return null
  }

  const renderChildDoc = (doc: TDocumentLite) => {
    if (doc.type === DOCUMENT_TYPES.R2_PROJECT) {
      // @ts-ignore
      const groupedByType = groupBy(doc.children, 'type')
      const accomplishments = groupedByType[DOCUMENT_TYPES.R2_ACCOMPLISHMENT_PLANNED_PROGRAM]
      const costItems = groupedByType[DOCUMENT_TYPES.R2_COST_ITEM] || []
      // @ts-ignore
      const constItemGroup = groupBy(costItems, 'details', 'group_name')
      const subHighlights = doc.document_id === childHighlights?.documentId ? childHighlights.highlights : []

      return <Container spacing={2} key={doc.document_id} id={doc.document_id} seleniumId={`document-full-view_child-${doc.source_id || doc.document_id}`}>
        <Divider />
        <DetailsComponent key={doc.document_id} document={doc} highlights={subHighlights} />
        {!!accomplishments?.length && (
          <Container spacing={1}>
            <TextComp level={H_LEVEL.H4}>{t('main.document.accomplishments')}</TextComp>
            <CollapseDoc documentType={accomplishments[0].type} title={''} list={accomplishments}/>
          </Container>
        )}
        {!!costItems?.length && (
          <Container spacing={1}>
            <TextComp level={H_LEVEL.H4}>{t('main.document.cost_items')}</TextComp>
            {Object.keys(constItemGroup).map((name: string) => (
              <CollapseDoc
                key={name}
                documentType={DOCUMENT_TYPES.R2_COST_ITEM}
                title={name}
                list={constItemGroup[name]}
              />
            ))}
          </Container>
        )}
      </Container>
    }
    return <DetailsComponent key={doc.document_id} document={doc} />
  }

  const typeLabel = document.type === DOCUMENT_TYPES.R2_PROJECT ? document.type_label : ''

  const highlightedTitle = <TextComp seleniumId={'document-full-view_title'} className='document_header_h1' level={H_LEVEL.H1}>
    {highlights ?
      textHighlighting({
        raw: document.title,
        highlights,
        field: DOCUMENT_HIGHLIGHTS_FIELDS.TITLE,
        level: H_LEVEL.H1,
        seleniumId: 'document-full-view_title'
      }) : document.title
    }
  </TextComp>

  const highlightedContent = <TextComp seleniumId={'document-full-view_content'} level={BODY_LEVEL.LARGE}>
    {highlights ?
      textHighlighting({
        raw: document.text,
        highlights,
        field: DOCUMENT_HIGHLIGHTS_FIELDS.TEXT,
        level: BODY_LEVEL.LARGE,
        seleniumId: 'document-full-view_content'
      }) : document.text
    }
  </TextComp>

  return (
    <Container spacing={2}>
      <Container spacing={2} seleniumId={`document-full-view_container-${document.source_id || document.document_id}`}>
        <Container spacing={1}>
          <AboveHeaderDocument document={document}/>
          <DocumentTypeHeader
            title={highlightedTitle}
            text={typeLabel}
            documentType={document.type}
          />
          {showDocumentInfo && <DocumentInfo
            documentId={document.document_id}
            details={document.details}
            creationDate={document.creation_date}
            type={document.type}
            title={document.title}
            showMltButton={showMltButton}
            isFullView
          />}
        </Container>

        <TagsTabsComponent
          seleniumId={'document-full-view_tags'}
          documentType={document.type}
          list={tags}
        />

        {document.text && (
          <DocumentContent title={contentLabel} text={highlightedContent}/>
        )}
      </Container>
      {document.children.map((doc: TDocumentLite) => renderChildDoc(doc))}
    </Container>
  )
}

export default React.memo(DetailsComponent)
