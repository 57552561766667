import { useEffect, useState } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import axios from 'axios'
import config, { PROD_VERSION } from 'configuration'

import { decoded, encoded } from 'utils/global'
import { QUERY_PARAMS } from 'constants/global'
import { DATE_MODES } from 'pages/Public/MainPage/Search/components/DateRange'

import {
  selectDateRange,
  selectDateRangeMode,
  selectMltSelector,
  selectSearchSelector,
  selectSelectedFilterItemsSelector,
} from '../mainFilter/selectors'
import { setDateRange, setDateRangeMode, setMlt, setSearch, setSelectedItems } from '../mainFilter/actions'
import {
  selectFetchedDocsCountSelector,
  selectIsPdfModeSelector,
  selectSnippetDocumentIdSelector,
  selectSortingSelector
} from '../liteDocuments/selectors'
import {
  setDocumentsOffsetCount,
  setPdfMode,
  setSnippetDocumentId,
  setSorting
} from '../liteDocuments/actions'
import {
  selectGroupBySelector,
  selectSelectedEdgesFilterSelector,
  selectSelectedNodesFilterSelector,
  selectSelectedRolesFilterSelector,
} from '../graph/selectors'
import { setGrouping, setSelectedEdges, setSelectedNodes, setSelectedRoles } from '../graph/actions'

export type TQueryParams = {
  query?: string,
  start?: string,
  end?: string,
  filter?: string
  documentsOffset?: string
  // clickedNodeOrEdge?: string//TODO waiting decision
}
export const useQueryParams = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()
  const [firstRender, setFirstRender] = useState<boolean>(true)

  const mlt = useSelector(selectMltSelector)
  const search = useSelector(selectSearchSelector)
  const isPdf = useSelector(selectIsPdfModeSelector)
  const dateRange = useSelector(selectDateRange)
  const dateRangeMode = useSelector(selectDateRangeMode)
  const filter = useSelector(selectSelectedFilterItemsSelector)
  const filterNames = filter.map(it => it.value)
  const documentsOffset = useSelector(selectFetchedDocsCountSelector)
  const snippetDocumentId = useSelector(selectSnippetDocumentIdSelector)
  const selectedNodesFilter = useSelector(selectSelectedNodesFilterSelector)
  const selectedEdgesFilter = useSelector(selectSelectedEdgesFilterSelector)
  const selectedRolesFilter = useSelector(selectSelectedRolesFilterSelector)
  const sorting = useSelector(selectSortingSelector)
  const graphGroupBy = useSelector(selectGroupBySelector)
  // const clickedNodeOrEdge = useSelector(selectClickedNodeOrEdgeSelector)

  const getParamsFromState = (): TQueryParams => {
    const [start, end] = dateRange

    return {
      ...search ? { [QUERY_PARAMS.QUERY]: search } : {},
      ...documentsOffset ? { [QUERY_PARAMS.DOCUMENTS_OFFSET]: documentsOffset } : {},
      ...sorting ? { [QUERY_PARAMS.SORT]: sorting } : {},
      ...selectedNodesFilter.length > 0 ? { [QUERY_PARAMS.NODES]: selectedNodesFilter.join(',') } : {},
      ...selectedEdgesFilter.length > 0 ? { [QUERY_PARAMS.EDGES]: selectedEdgesFilter.join(',') } : {},
      ...selectedRolesFilter.length > 0 ? { [QUERY_PARAMS.ROLES]: selectedRolesFilter.join(',') } : {},
      ...snippetDocumentId ? { [QUERY_PARAMS.SNIPPET_ID]: snippetDocumentId } : {},
      ...start ? { [QUERY_PARAMS.START]: dayjs(start).unix().toString() } : {},
      ...end ? { [QUERY_PARAMS.END]: dayjs(end).unix().toString() } : {},
      ...dateRangeMode && dateRangeMode !== DATE_MODES.ALL ? { [QUERY_PARAMS.DATE_RANGE_MODE]: dateRangeMode } : {},
      ...isPdf ? { [QUERY_PARAMS.PDF]: isPdf } : {},
      ...graphGroupBy ? { [QUERY_PARAMS.GROUP_BY]: graphGroupBy } : {},
      // ...clickedNodeOrEdge.length > 0 ? { [QUERY_PARAMS.CLICKED_NODE_OR_EDGE]: encoded(clickedNodeOrEdge) } : {},
      ...filter.length > 0 ? { [QUERY_PARAMS.FILTER]: encoded(filter) } : {},
      ...mlt ? { [QUERY_PARAMS.MLT]: encoded([mlt]) } : {},
    } as TQueryParams
  }

  const setQueryParamsToState = () => {
    const search = searchParams.get(QUERY_PARAMS.QUERY) || ''
    const start = searchParams.get(QUERY_PARAMS.START)
    const end = searchParams.get(QUERY_PARAMS.END)
    const mode = searchParams.get(QUERY_PARAMS.DATE_RANGE_MODE) || DATE_MODES.ALL
    const filterParam = searchParams.get(QUERY_PARAMS.FILTER)
    const mltParam = searchParams.get(QUERY_PARAMS.MLT)
    const documentsOffset = searchParams.get(QUERY_PARAMS.DOCUMENTS_OFFSET)
    const pdf = searchParams.get(QUERY_PARAMS.PDF)
    const snippetId = searchParams.get(QUERY_PARAMS.SNIPPET_ID)
    const nodes = searchParams.get(QUERY_PARAMS.NODES)
    const edges = searchParams.get(QUERY_PARAMS.EDGES)
    const roles = searchParams.get(QUERY_PARAMS.ROLES)
    const sorting = searchParams.get(QUERY_PARAMS.SORT)
    const groupBy = searchParams.get(QUERY_PARAMS.GROUP_BY)
    // const clickedNodeOrEdge = searchParams.get(QUERY_PARAMS.CLICKED_NODE_OR_EDGE)

    dispatch(setSearch(search))

    if (documentsOffset) {
      dispatch(setDocumentsOffsetCount(+documentsOffset))
    }

    if (sorting) {
      dispatch(setSorting(sorting))
    }

    if (start || end) {
      dispatch(setDateRange([dayjs.unix(Number(start)), dayjs.unix(Number(end))]))
      dispatch(setDateRangeMode(mode))
    }

    if (mltParam) {
      const mlt = decoded(mltParam)[0]
      dispatch(setMlt(mlt))
    }

    if (pdf) {
      dispatch(setPdfMode(!!pdf))
    }

    if (snippetId) {
      dispatch(setSnippetDocumentId(snippetId))
    }

    if (nodes) {
      dispatch(setSelectedNodes(nodes.split(',')))
    }

    if (edges) {
      dispatch(setSelectedEdges(edges.split(',')))
    }

    if (roles) {
      dispatch(setSelectedRoles(roles.split(',')))
    }

    if (groupBy) {
      dispatch(setGrouping(groupBy))
    }

    if (filterParam) {
      const filterObject = decoded(filterParam)
      dispatch(setSelectedItems(filterObject))
    }


    // if (clickedNodeOrEdge) {
    //   const decodedClickedNodeOrEdge = decoded(clickedNodeOrEdge)
    //   dispatch(setClickedNodeOrEdge(decodedClickedNodeOrEdge))
    // }
  }

  // Initialize State from URL query string
  useEffect(() => {
    setQueryParamsToState()
  }, [])

  // Set query params into URL
  useEffect(() => {
    if (firstRender) {
      setFirstRender(false)
      return
    }

    const params = getParamsFromState()
    setSearchParams(params);
  }, [
    search,
    location.pathname,
    documentsOffset,
    isPdf,
    snippetDocumentId,
    selectedNodesFilter,
    selectedEdgesFilter,
    selectedRolesFilter,
    sorting,
    graphGroupBy,
    JSON.stringify(filterNames),
    JSON.stringify(dateRange),
    JSON.stringify(dateRangeMode),
    JSON.stringify(mlt),
    // JSON.stringify(clickedNodeOrEdge),
  ])
}

export const useAWSCaptchaLoading = (enabled: boolean) => {
  const [appLoading, setAppLoading] = useState<boolean>(enabled)

  useEffect(() => {
    //@ts-ignore
    if (!appLoading || window.AwsWafCaptcha) return

    const timer = setInterval(function(){
      //@ts-ignore
      if (window.AwsWafCaptcha !== undefined) {
        window.clearInterval(timer);
        setAppLoading(false)
      }
    }, 100);
  }, [])

  return { appLoading }
}

const currentVersion = await axios.get(  `${process.env.PUBLIC_URL}/manifest.json`).then(r => r.data)
const CURRENT_VERSION = currentVersion?.version

export const useCheckVersion = () => {
  const [show, setShow] = useState(false)

  const checkVersion = async () => {
    try {
      const remoteVersion = await axios.get(`${config.hostUrl}/manifest.json`).then(r => r.data)

      if (CURRENT_VERSION !== remoteVersion?.version) {
        setShow(true)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!PROD_VERSION) return

    const interval = setInterval(() => {
      checkVersion()
    }, config.check_version_timeout)

    return () => {
      clearInterval(interval)
      setShow(false)
    }
  }, [])

  return {
    newVersion: show,
    setNewVersion: setShow
  }
}