import React from 'react'

import SkeletonComp from 'components/SkeletonComp';
import { SKELETON_VARIANT } from 'components/SkeletonComp/types';


const SkeletonLoading = () => {
  return (
    <div className="document-skeleton-loading-wrapper">
      <div className="document-skeleton-loading">
        <SkeletonComp  level="h1" sx={{ mb: 1 }}/>
        <div className="info-line-skeleton">
          <div><SkeletonComp width={60} level="body-lg" /></div>
          <div><SkeletonComp width={60} level="body-lg" /></div>
        </div>
        <SkeletonComp width={250} level="h2" sx={{ mb: 1 }}/>
        <SkeletonComp width="40%" level="body-lg" />
        <SkeletonComp width={100} level="h2" sx={{ mb: 1 }}/>
        <SkeletonComp height={150} variant={SKELETON_VARIANT.RECT} level="body-lg" sx={{ mb: 1 }}/>
      </div>
    </div>
  );
}

export default React.memo(SkeletonLoading)
