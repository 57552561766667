import React from 'react'
import { useSelector } from 'react-redux'
import { Accordion, AccordionGroup, AccordionDetails, AccordionSummary, AccordionProps, AccordionGroupProps } from '@mui/joy'
import { COMPONENT_VARIANT } from 'utils/mui'
import { selectSnippetDocumentIdSelector } from 'state/liteDocuments/selectors'

export type TListForAccordionComp = {
  key: string,
  title: React.ReactElement,
  text: React.ReactElement,
  isContentNotExist: boolean,
}

type Props = {
  list: TListForAccordionComp[]
  textVariant?: COMPONENT_VARIANT
}

export default function AccordionComp(
  {
    list,
    variant = COMPONENT_VARIANT.DEFAULT,
    textVariant,
    color,
    transition,
    className
  }: Props & AccordionGroupProps & AccordionProps)
{
  const snippetDocumentId = useSelector(selectSnippetDocumentIdSelector)
  return (
    <AccordionGroup
      className={className}
      variant={variant}
      color={color}
      transition={transition}
    >
      {list.map(item => (
        <Accordion
          defaultExpanded={item.key === snippetDocumentId}
          id={item.key}
          key={item.key}
          disabled={item.isContentNotExist}
        >
          <AccordionSummary>{item.title}</AccordionSummary>
          <AccordionDetails variant={textVariant}>
            {item.text}
          </AccordionDetails>
        </Accordion>
      ))}
    </AccordionGroup>
  )

}
