import dayjs from 'dayjs'
import { DATE_LOCALE_FORMAT, DATE_YEAR_FORMAT } from '../constants/global'

export const getDateFromUnix = (date: number) => {
  return dayjs.unix(date).format(DATE_LOCALE_FORMAT)
}

export const getYearFromUnix = (date: number) => {
  return dayjs.unix(date).format(DATE_YEAR_FORMAT)
}

