import { DOCUMENT_TYPES } from '../constants/document'

export const isR2Program = (type: string) => {
  return type === DOCUMENT_TYPES.R2_PROGRAM_ELEMENT
}

export const isR2Project = (type: string) => {
  return type === DOCUMENT_TYPES.R2_PROJECT
}

export const isR2ProjectAccomplishment = (type: string) => {
  return type === DOCUMENT_TYPES.R2_ACCOMPLISHMENT_PLANNED_PROGRAM
}

export const isR2ProjectCostItem = (type: string) => {
  return type === DOCUMENT_TYPES.R2_COST_ITEM
}

export const isR2ProjectSubTypes = (type: string) => {
  return isR2ProjectCostItem(type) || isR2ProjectAccomplishment(type)
}

export const isR2Document = (type: string) => {
  return isR2Program(type) || isR2Project(type) || isR2ProjectSubTypes(type)
}