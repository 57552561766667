import React from 'react'
import { Button } from '@mui/joy';
import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from 'utils/mui'

type TProps = {
  children: any,
  type?: COMPONENT_VARIANT
  onClick: (e: any) => void
  color?: COMPONENT_COLOR
  loading?: boolean
  size?: COMPONENT_SIZE | 'xs'
  seleniumId?: string
  className?: string
  disabled?: boolean
  startDecorator?: any
  endDecorator?: any
  sx?: any
}
export default function ButtonComp(
  {
    children,
    startDecorator,
    endDecorator,
    onClick,
    color = COMPONENT_COLOR.PRIMARY,
    type = COMPONENT_VARIANT.DEFAULT,
    disabled = false,
    loading = false,
    className = '',
    size = COMPONENT_SIZE.MEDIUM,
    seleniumId = '',
    sx
  }: TProps) {

  return (
    <Button
      onClick={onClick}
      selenium-id={seleniumId}
      className={className}
      color={color}
      variant={loading ? COMPONENT_VARIANT.DEFAULT : type}
      disabled={disabled}
      loading={loading}
      size={size}
      startDecorator={startDecorator}
      endDecorator={endDecorator}
      sx={sx}
    >
      {children}
    </Button>
  )
}
