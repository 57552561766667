import { factoryAction } from '../utils'

const ACTION = factoryAction('mainFilter')

// ACTION TYPES
const setSearch = ACTION('setSearch')
const setIsLoading = ACTION('setIsLoading')
const setList = ACTION('setList')
const setIsOpen = ACTION('setIsOpen')
const setSelectedItems = ACTION('setSelectedItems')
const setFilterSearch = ACTION('setFilterSearch')
const setDateRangeMode = ACTION('setDateRangeMode')
const setDateRange = ACTION('setDateRange')
const setMlt = ACTION('setMlt')
const showMltModal = ACTION('showMltModal')
const setMetadata = ACTION('setMetadata')
const setAppliedFiltersCollapse = ACTION('setAppliedFiltersCollapse')

const TYPES = {
  setSearch,
  setIsLoading,
  setList,
  setIsOpen,
  setSelectedItems,
  setFilterSearch,
  setDateRangeMode,
  setDateRange,
  setMlt,
  showMltModal,
  setMetadata,
  setAppliedFiltersCollapse
}

export default TYPES
