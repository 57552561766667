import React from 'react'
import { Input, InputProps } from '@mui/joy'

type TProps = {
  seleniumId?: string
}

const InputComp: React.FC<TProps & InputProps & React.InputHTMLAttributes<HTMLInputElement>> = (
  {
    className,
    seleniumId = '',
    placeholder,
    disabled,
    onChange,
    value,
    onFocus,
    onBlur,
    endDecorator,
    error,
    required,
    type,
    onKeyDown,
    startDecorator,
  }) => {
  return (
    <Input
      selenium-id={seleniumId}
      className = {className}
      disabled={disabled}
      placeholder={placeholder}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      value={value}
      error={error}
      endDecorator={endDecorator}
      startDecorator={startDecorator}
      required={required}
      type={type}
    />
  )
}

export default InputComp
