const USER = 'user'

export const ROUTES_CONST = {
  LOGIN: {
    link: 'login',
    title: 'Log-in',
  },
  USER: {
    link: USER,
  },
  EXPLORATIONS: {
    link: `${USER}/explorations`,
    title: 'Explorations',
  },
}
