import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Divider } from '@mui/joy';
import { isMobile } from 'react-device-detect'

import { selectIsOpenGraphSelector } from 'state/graph/selectors'
import { selectIsOpenDocsSelector } from 'state/liteDocuments/selectors'
import { setIsOpen } from 'state/graph/actions'
import { setIsOpen as setDocumentsOpen } from 'state/liteDocuments/actions'
import DocumentsLite from '../Documents'
import GraphLite from './Graph'

import { ReactComponent as GraphIco } from 'images/icons/graph_icon.svg'
import { ReactComponent as DocIco } from 'images/icons/doc_icon.svg'
import CloseButtonComp from 'components/CloseButtonComp/CloseButtonCopm'
import Container from 'components/Container'
import TextComp, { H_LEVEL } from 'components/TextComp';

import './style.scss'


const ContentLite: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isOpenDocuments = useSelector(selectIsOpenDocsSelector)
  const isOpenGraph = useSelector(selectIsOpenGraphSelector)

  const handleOpenGraph = (value: boolean) =>
    dispatch(setIsOpen(value))

  const handleOpenDocuments = (value: boolean) =>
    dispatch(setDocumentsOpen(value))

  const classname = classNames('content',
    { graph_open: isOpenGraph, documents_open: isOpenDocuments })

  return (
    <Container
      className={classname}
      direction={'row'}
    >
      {!isMobile &&
        <Container className='content__graph' direction={'row'}>
          <Container className={'content__graph__wrap'} width={'100%'}>
            <Container className='content__title padding-8' direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <TextComp level={H_LEVEL.H4} sx={{ paddingLeft: '8px' }}>
                <Trans i18nKey='main.graph.title_graph' />
              </TextComp>
              {isOpenDocuments &&
                <CloseButtonComp hint={t('main.graph.close_graph')} onClick={() => handleOpenGraph(false)} />
              }
            </Container>
            <Divider />
            <GraphLite />
          </Container>
          <Container className='content_closed' onClick={() => handleOpenGraph(!isOpenGraph)}>
            <Container className={'content_closed_icon'} >
              <GraphIco />
            </Container>
          </Container>
        </Container>
      }
      {
        <Container className='content__documents'>
          { isOpenDocuments && <DocumentsLite />}
          {!isOpenDocuments &&
            <>
              <Container className='content_closed' onClick={() => handleOpenDocuments(!isOpenDocuments)}>
                <Container className={'content_closed_icon'} >
                  <DocIco />
                </Container>
              </Container>
            </>
          }
        </Container>
      }
    </Container>
  )
}

export default React.memo(ContentLite)
