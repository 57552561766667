import React from 'react'
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedFilterItemsSelector } from 'state/mainFilter/selectors';
import { setIsOpen as setIsFilterOpen } from 'state/mainFilter/actions';
import { ReactComponent as FilterSolid } from 'images/icons/filterSolid.svg';
import { ReactComponent as Filter } from 'images/icons/filter.svg';

import './style.scss'


const FilterButton = () => {
  const dispatch = useDispatch()

  const selectedItems = useSelector(selectSelectedFilterItemsSelector)
  const filtersCount = selectedItems.length;

  const handleFilterClick = () => {
    dispatch(setIsFilterOpen(true))
  }

  const hasFilter = filtersCount > 0
  return (
    <div
      className={classNames('FilterButton', { 'selected': hasFilter })}
      onClick={handleFilterClick}
      selenium-id={'filter_button'}
    >
      <div className='filter_closed_icon'>
        {hasFilter  ? <FilterSolid/> : <Filter/>}
      </div>
      {hasFilter && (
        <span className='applied-wrapper'>{filtersCount}</span>
      )}
    </div>
  )
}

export default FilterButton;
