import React from 'react'
import { t } from 'i18next'
import { toast } from 'react-toastify';

const notificator = {
  success: (text?: React.ReactNode, duration?: number) => {
    toast.success(text || t('global.notification_success'), {
      autoClose: (duration || 4.5) * 1000,
    })
  },
  warning: (text: React.ReactNode, duration?: number) => {
    toast.warn(text, {
      autoClose: (duration || 4.5) * 1000,
    })
  },
  error: (text?: React.ReactNode, duration?: number) => {
    toast.error(text || t('global.notification_error'), {
      autoClose: (duration || 4.5) * 1000,
    })
  }
}

export default notificator
