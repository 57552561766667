import React from 'react'
import { CheckboxProps, Checkbox } from '@mui/joy'

type TProps = {
  seleniumId?: string
}
export default function CheckboxComp(
  {
    seleniumId,
    id,
    color,
    checked,
    onChange,
    onClick,
    label,
    size,
    variant,
    indeterminate
  }: CheckboxProps & TProps
) {
  return(
    <Checkbox
      selenium-id={seleniumId}
      id={id}
      size={size}
      checked={checked}
      onChange={onChange}
      onClick={onClick}
      label={label}
      color={color}
      variant={variant}
      indeterminate={indeterminate}
    />
  )
}