import React from 'react'
import { Typography, TypographyProps } from '@mui/joy'

export enum H_LEVEL {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
}

export enum TITLE_LEVEL {
  LARGE = 'title-lg',
  MEDIUM = 'title-md',
  SMALL = 'title-sm',
}

export enum BODY_LEVEL {
  LARGE = 'body-lg',
  MEDIUM = 'body-md',
  SMALL = 'body-sm',
  X_SMALL = 'body-xs',
}

type TProps = {
  seleniumId?: string
}
export default function TextComp({
  children,
  level = BODY_LEVEL.MEDIUM,
  variant, 
  color,
  noWrap, 
  className, 
  onClick,
  sx,
  seleniumId
}: TypographyProps & TProps) {
  return (
    <Typography
      selenium-id={seleniumId}
      className={className}
      level={level}
      variant={variant}
      color={color}
      noWrap={noWrap}
      onClick={onClick}
      sx={sx}
    >
      {children}
    </Typography>
  )

}
