import dayjs from 'dayjs'
import ActionTypes from './action-types'
import { ThunkAction } from '../types'
import axiosInstance from '../../api/api'
import API from '../../api/urls'
import {
  deserializeMainFilter,
  serializeDateRangeQuery,
  getUpdatedSelectedItems,
  serializeFilterSearchLimitToQuery,
  serializeFilterSearchToQuery,
  serializeFilterToQuery,
} from 'utils/mainFilter'
import { TMainFilter, TMainFilterItem, TSetMltProps } from './types'
import { getQueryString } from '../../utils/global'

export const setSearch = (value: string) => ({
  type: ActionTypes.setSearch,
  payload: value,
})

export const setMainFilter = (value: TMainFilter[]) => ({
  type: ActionTypes.setList,
  payload: value,
})

export const setIsLoading = (value: boolean) => ({
  type: ActionTypes.setIsLoading,
  payload: value,
})

export const setIsOpen = (value: boolean) => ({
  type: ActionTypes.setIsOpen,
  payload: value,
})

export const setSelectedItems = (values: TMainFilterItem[]) => ({
  type: ActionTypes.setSelectedItems,
  payload: values,
})

export const setMlt = (values: TSetMltProps | null) => ({
  type: ActionTypes.setMlt,
  payload: values,
})

export const setShowMltModal = (showMltModal: boolean) => ({
  type: ActionTypes.showMltModal,
  payload: showMltModal,
})

export const setFilterSearch = (value: object) => ({
  type: ActionTypes.setFilterSearch,
  payload: value,
})

export const setDateRangeMode = (value: string) => ({
  type: ActionTypes.setDateRangeMode,
  payload: value,
})

export const setDateRange = (values: dayjs.ConfigType[]) => ({
  type: ActionTypes.setDateRange,
  payload: values,
})

export const setMeta = (values: object) => ({
  type: ActionTypes.setMetadata,
  payload: values,
})

export const setAppliedFiltersCollapse = (value: boolean) => ({
  type: ActionTypes.setAppliedFiltersCollapse,
  payload: value,
})

export type TFetchMainFilterActionProps = {
  query: string,
  cancelToken: any,
  filter: TMainFilterItem[],
  filterSearch: object,
  dateRange: [dayjs.ConfigType, dayjs.ConfigType],
  mlt: TSetMltProps | null,
}

export const fetchMainFilterAction = (
  query: string = '',
  cancelToken: any,
  filter: TMainFilterItem[] = [],
  filterSearch: object = {},
  dateRange: [dayjs.ConfigType, dayjs.ConfigType],
): ThunkAction<Promise<void>> => {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true))

      const response = await axiosInstance.get(`${API.public.getMainFilters}`, {
        params: { query: query.trim() },
        paramsSerializer: function paramsSerializer(params: object) {
          const queryString = getQueryString(params)
          return queryString
            + serializeDateRangeQuery(dateRange)
            + serializeFilterToQuery(filter)
            + serializeFilterSearchToQuery(filterSearch)
            + serializeFilterSearchLimitToQuery(filterSearch)
        },
        cancelToken
      })

      const data = deserializeMainFilter(response.data)
      dispatch(setMainFilter(data))
      dispatch(setMeta(response.data.metadata))

      const selectedItems = getState().mainFilter.selectedItems
      if (selectedItems.length) {
        dispatch(setSelectedItems(getUpdatedSelectedItems(selectedItems, data)))
      }
    } catch (e) {
      /* empty */
    }
    finally {
      dispatch(setIsLoading(false))
    }
  }
}
