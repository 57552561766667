import React from 'react'
import { Trans } from 'react-i18next'

import './style.scss'

const ExplorationPage = () => {
  return (
    <div className='exploration-page'>
      exploration page
      <div>
        <Trans i18nKey='pages.login.login_btn' />
      </div>
    </div>
  )
}

export default React.memo(ExplorationPage)
