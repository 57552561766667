import colors from '@mui/joy/colors';
import {
  extendTheme as extendJoyTheme,
  Theme as JoyTheme,
  ThemeCssVar as JoyThemeCssVar
} from '@mui/joy/styles';

import {
  experimental_extendTheme as extendMuiTheme,
  PaletteColor,
  TypeText,
  TypeAction,
  Overlays,
  PaletteColorChannel,
  PaletteAlert,
  PaletteAppBar,
  PaletteAvatar,
  PaletteChip,
  PaletteFilledInput,
  PaletteLinearProgress,
  PaletteSlider,
  PaletteSkeleton,
  PaletteSnackbarContent,
  PaletteSpeedDialAction,
  PaletteStepConnector,
  PaletteStepContent,
  PaletteSwitch,
  PaletteTableCell,
  PaletteTooltip,
  Shadows,
  ZIndex
} from '@mui/material/styles';
import { CommonColors } from '@mui/material/styles/createPalette';
import { TypeBackground } from '@mui/material';

// eslint-disable-next-line no-duplicate-imports
import type { PaletteRange } from '@mui/joy/styles';
import { customColors, isCustomColor } from 'theme/customColors';

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    azure: true;
    salmon: true;
    jade: true;
    rose: true;
    lavender: true;
    turquoise: true;
    periwinkle: true;
    magenta: true;
    seaglass: true;
    tawny: true;
    slate: true;
  }

  interface Palette {
    azure: PaletteRange;
    salmon: PaletteRange;
    jade: PaletteRange;
    rose: PaletteRange;
    lavender: PaletteRange;
    turquoise: PaletteRange;
    periwinkle: PaletteRange;
    magenta: PaletteRange;
    seaglass: PaletteRange;
    tawny: PaletteRange;
    slate: PaletteRange;
  }
}

declare module '@mui/joy/Button' {
  interface ButtonPropsSizeOverrides {
    xs: true;
    // xl: true;
  }
  interface ButtonPropsColorOverrides {
    azure: true;
    salmon: true;
    jade: true;
    rose: true;
    lavender: true;
    turquoise: true;
    periwinkle: true;
    magenta: true;
    seaglass: true;
    tawny: true;
    slate: true;
  }
}

declare module '@mui/joy/Chip' {
  interface ChipPropsColorOverrides {
    azure: true;
    salmon: true;
    jade: true;
    rose: true;
    lavender: true;
    turquoise: true;
    periwinkle: true;
    magenta: true;
    seaglass: true;
    tawny: true;
    slate: true;
  }
}

declare module '@mui/joy/Checkbox' {
  interface CheckboxPropsColorOverrides {
    azure: true;
    salmon: true;
    jade: true;
    rose: true;
    lavender: true;
    turquoise: true;
    periwinkle: true;
    magenta: true;
    seaglass: true;
    tawny: true;
    slate: true;
  }
}

declare module '@mui/joy/styles' {
  interface Palette {
    highlights: {
      search: string
      mention: string
      linked_entity: string
    };
  }
}

export const COLORS = {
  PRIMARY: '#44bba2',
  GRAY: '#71777E',
  ERROR: '#bb4456',
  TEXT: '#1a2235',
  LINK: '#3678ae',
  BACKGROUND: '#f5f7fa',
}

declare module '@mui/joy/styles' {
  interface Palette {
    secondary: PaletteColorChannel;
    error: PaletteColorChannel;
    dividerChannel: string;
    action: TypeAction;
    Alert: PaletteAlert;
    AppBar: PaletteAppBar;
    Avatar: PaletteAvatar;
    Chip: PaletteChip;
    FilledInput: PaletteFilledInput;
    LinearProgress: PaletteLinearProgress;
    Skeleton: PaletteSkeleton;
    Slider: PaletteSlider;
    SnackbarContent: PaletteSnackbarContent;
    SpeedDialAction: PaletteSpeedDialAction;
    StepConnector: PaletteStepConnector;
    StepContent: PaletteStepContent;
    Switch: PaletteSwitch;
    TableCell: PaletteTableCell;
    Tooltip: PaletteTooltip;
  }
  interface PalettePrimary extends PaletteColor {}
  interface PaletteInfo extends PaletteColor {}
  interface PaletteSuccess extends PaletteColor {}
  interface PaletteWarning extends PaletteColor {}
  interface PaletteCommon extends CommonColors {}
  interface PaletteText extends TypeText {}
  interface PaletteBackground extends TypeBackground {}

  interface ThemeVars {
    // attach to Joy UI `theme.vars`
    shadows: Shadows;
    overlays: Overlays;
    zIndex: ZIndex;
  }
}

type MergedThemeCssVar = { [k in JoyThemeCssVar]: true };
declare module '@mui/material/styles' {
  interface Theme {
    // put everything back to Material UI `theme.vars`
    vars: JoyTheme['vars'];
  }

  // makes Material UI theme.getCssVar() sees Joy theme tokens
  interface ThemeCssVarOverrides extends MergedThemeCssVar {}
}

export const muiTheme = extendMuiTheme({
  // This is required to point to `var(--joy-*)` because we are using `CssVarsProvider` from Joy UI.
  cssVarPrefix: 'joy',
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: COLORS.PRIMARY,
        },
        grey: colors.grey,
        error: {
          main: colors.red[500],
        },
        info: {
          main: colors.blue[500],
        },
        success: {
          main: colors.blue[500],
        },
        warning: {
          main: colors.yellow[200],
        },
        common: {
          white: '#FFF',
          black: COLORS.TEXT,
        },
        divider: colors.grey[200],
        text: {
          primary: COLORS.TEXT,
          secondary: COLORS.GRAY,
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: '#44BBA2',
        },
        grey: colors.grey,
        error: {
          main: colors.red[600]
        },
        info: {
          main: colors.blue[600],
        },
        success: {
          main: colors.blue[600],
        },
        warning: {
          main: colors.yellow[300],
        },
        common: {
          white: '#FFF',
          black: '#09090D',
        },
        divider: colors.grey[800],
        text: {
          primary: colors.grey[100],
          secondary: colors.grey[300],
        },
      },
    },
  },
});

export const joyTheme = extendJoyTheme({
  colorSchemes: {
    light: {
      palette: {
        highlights: {
          search: '#FFFEA9',
          mention: '#CCF8ED',
          linked_entity: '#7ADFC5',
          // add here text highlighting colors
        },
        primary: {
          50: '#E8FDF7',
          100: '#CCF8ED',
          200: '#AFF2E1',
          300: '#94E9D3',
          400: '#7ADFC5',
          500: '#44BBA2',
          600: '#51C7AB',
          700: '#44BBA2',
          800: '#36AE98',
          900: '#219B89'
        },
        success: {
          900: '#21629B',
          800: '#3678AE',
          700: '#4487BB',
          600: '#5196C7',
          500: '#21629B',
          400: '#7AB8DF',
          300: '#94CAE9',
          200: '#AFDBF2',
          100: '#CCE9F8',
          50: '#E8F6FD'
        },
        ...customColors,
      }
    },
  },
  components: {
    JoyChip: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const ownerColor = ownerState.color;
          return {
            ...(ownerColor && isCustomColor(ownerColor) && {
              color: theme.vars.palette.common.white,
            }),
            borderRadius: '4px',
          }
        },
        action: ({ ownerState, theme }) => {
          const ownerColor = ownerState.color;
          return {
            ...(ownerColor && isCustomColor(ownerColor) && {
              backgroundColor: theme.vars.palette[ownerColor][500],
            }),
          }
        }
      },
    },
    JoyCheckbox: {
      styleOverrides: {
        label: {
          fontSize: '16px',
          color: COLORS.TEXT,
          fontWeight: 400,
        },
        root: ({ ownerState, theme }) => {
          const ownerColor = ownerState.color;
          return {
            ...(ownerColor && isCustomColor(ownerColor) && {
              color: theme.vars.palette[ownerColor][500],
            }),
          }
        },
        checkbox: ({ ownerState, theme }) => {
          const ownerColor = ownerState.color;
          return {
            borderRadius: '4px',
            ...(ownerColor && isCustomColor(ownerColor) && {
              border: `1px solid ${theme.vars.palette[ownerColor][500]}`,
              color: 'white',
              background: ownerState.checked ? theme.vars.palette[ownerColor][500] : 'white'
            }),
          }
        },
      }
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => {
          const ownerColor = ownerState.color;
          return {
            ...(ownerState.size === 'xs' && {
              '--Icon-fontSize': '0.5rem',
              '--Button-gap': '0.25rem',
              minHeight: 'var(--Button-minHeight, 0.5rem)',
              fontSize: theme.vars.fontSize.sm,
              paddingBlock: '2px',
              paddingInline: '8px',
            }),
            ...(ownerColor && isCustomColor(ownerColor) && {
              backgroundColor: theme.vars.palette[ownerColor][500],
              '&:hover:not(:disabled)': {
                backgroundColor: theme.vars.palette[ownerColor][400],
              },
              '&:disabled': {
                opacity: 0.3
              },
            }),
          }
        }
      }
    },

    JoyTypography: {
      defaultProps: {
        levelMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          'title-lg': 'p',
          'title-md': 'p',
          'title-sm': 'p',
          'body-md': 'span',
          'body-sm': 'span',
          'body-xs': 'span',
        },
      },
    },
  },

  typography: {
    h2: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 500,
    },
    h3: {
      fontSize: '20px',
      lineHeight: '24px',
      fontWeight: 500,
      letterSpacing: 0,
    },
    h4: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: 0,
    },
    'title-lg': {
      fontSize: '16px',
      lineHeight: '28px',
    },
    'body-lg': {
      fontSize: '16px',
      lineHeight: '28px',
    },
    'body-md': {
      fontWeight: 400
    }
  },
  fontFamily: {
    display: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});
