import React from 'react'
import Container from 'components/Container'

import SkeletonItem from './Item';


const SkeletonLoading = ({ sx }: {sx?: any}) => {
  return (
    <Container className="skeleton-loading" sx={sx}>
      <SkeletonItem />
      <SkeletonItem />
    </Container>
  );
}

export default React.memo(SkeletonLoading)
