import React from 'react'
import { Navigate, useParams, useRoutes } from 'react-router-dom'

import { PrivateRoute } from './utils'
import { ROUTES_CONST } from './consts'
import ExplorationPage from '../pages/Private/ExplorationPage'
import { NotFoundPage } from 'pages/Public/NotFoundPage'
// import { LoginPageComponent } from "../pages/Public/LoginPage";
import PrivatePage from '../pages/Private'
import MainPage from '../pages/Public/MainPage'
import { documentSharedOpened } from '../services/tracking.service'

const RedirectWithParams = () => {
  const { document_indicator } = useParams();
  const sharedLink = `/shared/${document_indicator}`
  documentSharedOpened(sharedLink)
  return <Navigate to={`/document/${document_indicator}${window.location.search}`} replace />;
};

export default function RoutesPage() {
  const routes = [
    { path: '/', element: <MainPage /> },
    { path: '/document/:document_indicator', element: <MainPage /> },
    { path: '/shared/:document_indicator', element: <RedirectWithParams /> },
    {
      path: `/${ROUTES_CONST.USER.link}`,
      element: <PrivateRoute roles={['user']} component={PrivatePage} />,
      children: [
        {
          index: true,
          element: <Navigate to={`/${ROUTES_CONST.EXPLORATIONS.link}`} replace />,
        },
        {
          path: `/${ROUTES_CONST.EXPLORATIONS.link}`,
          element: <ExplorationPage />,
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
      ],
    },
    // { path: `/${ROUTES_CONST.LOGIN.link}`, element: <LoginPageComponent /> },
    { path: '*', element: <Navigate to="/" replace /> },
  ]

  return useRoutes(routes)
}
