/* eslint-disable */
export default {
  global: {
    notification_success: 'Operation was complete.',
    notification_error: 'Something went wrong.',
    ok: 'Ok',
    save: 'Save',
    clear: 'Clear',
    close: 'Close',
    open: 'Open',
    search: 'Search',
    reset: 'Reset',
    reset_all: 'Reset all',
    new_version: 'There is a new version available.',
    refresh: 'Refresh now'
  },
  pages: {
    login: {
      login_btn: 'Login',
      title_page: 'Login',
      input_login_mess: 'Enter your login',
      input_login_placeholder: 'E-mail',
      input_pass_mess: 'Enter your password',
      input_pass_placeholder: 'Enter your password',
      notification_login_or_pass_err: 'Login or password is wrong.',
    },
  },

  main: {
    menu: {
      home: 'Home',
      about: 'About',
      products_services: 'Products & Services',
      submit_documents: 'Submit Documents',
      sign_in: 'Sign in',
      operating_status: 'Operating Status',
    },
    search_bar: {
      btn: 'Search',
      input_placeholder: 'Search by concept',
      feedback_nav: 'Feedback',
    },
    graph: {
      title_graph: 'Graph',
      evidence: 'Search results',
      recent_evidence: 'Recent documents',
      close_graph: 'Close graph',
      edges: 'Edges',
      nodes: 'Nodes',
      nodes_limit: 'Nodes limit',
      nodes_types: 'Nodes types',
      roles: 'Roles',
      relationships: 'Relationships',
    },
    document: {
      abstract: 'Abstract',
      download_pdf: 'Download PDF',
      full_text: 'Full text',
      description: 'Description',
      program_description: 'Program description',
      project_description: 'Project description',
      project: 'Project',
      accomplishments: 'Accomplishments',
      readers: 'Readers',
      tags: 'Tags',
      entities: 'Entities',
      evidence: 'Evidence',
      copied: 'Copied',
      show_all: 'Show all',
      hide: 'Hide',
      mentioned_tags: 'Associated with this document',
      mentioned_tags_project: 'Associated with this project',
      mlt: 'Show similar documents',
      mlt_no_results_title: 'No results found.',
      similar_documents_to: 'Similar documents to ',
      no_results_title: 'No results found. Try widening your search?',
      share_your_query: 'share your query',
      no_results_description_start: 'If you believe that this is an error,',
      no_results_description_end: ' please copy the URL from your browser and send it to our support team for assistance.',

      cost_items: 'Cost items',
      performing_activity: 'Performing activity:',
      prior_year: 'Prior year',
      current_year: 'Current year',
      total: 'Total cost',
      close: 'Close documents',

      thumbs_up_hint: 'This document is relevant to my query',
      thumbs_down_hint: 'This document is irrelevant to my query',
      share_hint: 'Copy direct link to this document'
    },
    filter: {
      header: 'Filter',
      applied: 'applied',
      close: 'Close filter',
      show_less: 'Show less',
      show_more: 'Show more',
      clear_all: 'Clear all filters',
      collapse: 'Collapse',
      applied_filters: 'Applied filters:',
      selected_on_graph: 'Selected on Graph:',
    },
    identify: {
      email_validation: 'Not a valid email address.',
      input_placeholder: 'yours@example.com',
      btn_send: 'Continue',
      btn_close: 'Close',
      modal_title: 'Unlock unlimited access!',
      modal_text_step1:
        'Unregistered users have access to three documents. For free, unlimited access, please provide your email address. You’ll never receive spam from us.',
      modal_text_step2: 'Explore our complete document library now. Enjoy unlimited resources.',
      modal_title_access: 'Access granted!',
    },
  },
  mobile: {
    tab_link: {
      graph: 'Graph',
      documents: 'Documents',
    }
  }
}
