import { Action } from 'redux'

type Handlers<State, Types extends string, Actions extends Action<Types>> = {
  readonly [Type in Types]: (state: State, action: Actions) => State
}

export const factoryAction =
  <K extends string>(key: K) =>
    <T extends string>(type: T) =>
      `${key}/${type}`

export const createReducer =
  <State, Types extends string, Actions extends Action<Types>>(
    initialState: State,
    handlers: Handlers<State, Types, Actions>,
  ) =>
    (state: State = initialState, action: Actions) =>
      Object.prototype.hasOwnProperty.call(handlers, action.type)
        ? handlers[action.type as Types](state, action)
        : state
