import React from 'react'

import { DOCUMENT_HIGHLIGHTS_FIELDS } from '../constants/document'
import TextComp, { BODY_LEVEL, H_LEVEL, TITLE_LEVEL } from '../components/TextComp'
import { TDocumentHighlight, TDocumentHighlightToken } from '../state/liteDocuments/types'
import { COMPONENT_VARIANT } from './mui'

type TProps = {
  raw: string,
  highlights: TDocumentHighlight[],
  field: DOCUMENT_HIGHLIGHTS_FIELDS,
  level: H_LEVEL | BODY_LEVEL | TITLE_LEVEL,
  limit?: number
  seleniumId?: string
}
export const textHighlighting = ({ raw, highlights, field , level, limit, seleniumId }: TProps) => {
  const result = []
  const rule = highlights?.find(item  => item.field === field)
  if (!rule) return limit && raw.length > limit ? raw.slice(0, limit) + ' ...' : raw

  let characterPointer = 0
  let breakHighlights = false

  rule.tokens.forEach((token: TDocumentHighlightToken, index: number) => {
    if (limit && breakHighlights) return

    const { matched_token, position } = token
    const startPos = +position
    const endPos = +position + matched_token.length

    const limited = limit && characterPointer > 0
    if (limited && (startPos - characterPointer) > (limit / 2)) {
      breakHighlights = true
      return
    }

    const startText = raw.substring(characterPointer, startPos)
    const start = limit && startText.length > limit ?  startText.slice(0, limit / 2) + ' ... ' : startText

    result.push(start)
    result.push(
      <TextComp
        seleniumId={`${seleniumId}_highlighted_${matched_token}`}
        key={matched_token + index}
        variant={COMPONENT_VARIANT.TEXT}
        level={level}
        sx={{ background: (theme) => theme.vars.palette.highlights.search }}
      >
        {matched_token}
      </TextComp>
    )
    characterPointer = endPos
  })

  const endText = raw.substring(characterPointer)
  const end = limit && endText.length > limit ? endText.slice(0, limit / 2) + ' ...' : endText
  result.push(end)

  return result
}