export enum COMPONENT_VARIANT {
  PRIMARY = 'solid',
  LINK = 'plain',
  TEXT = 'soft',
  DEFAULT = 'outlined',
}

export enum COMPONENT_SIZE {
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
}

export enum COMPONENT_COLOR {
  PRIMARY = 'primary',
  NEUTRAL = 'neutral',
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
  AZURE = 'azure',
  SALMON = 'salmon',
  JADE = 'jade',
  ROSE = 'rose',
  LAVENDER = 'lavender',
  TURQUOISE = 'turquoise',
  PERIWINKLE = 'periwinkle',
  MAGENTA = 'magenta',
  SEAGLASS = 'seaglass',
  TAWNY = 'tawny',
  SLATE = 'slate',
}

