import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import CloseButtonComp from 'components/CloseButtonComp/CloseButtonCopm'
import ButtonComp from 'components/ButtonComp/ButtonCopm'
import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from 'utils/mui'
import Container from 'components/Container'
import TextComp, { BODY_LEVEL, H_LEVEL } from 'components/TextComp'

import './style.scss'

type TProps = {
  onClose: () => void
  onClear: () => void
  selectedCount: number
}
const FilterHeader: React.FC<TProps> = ({ onClose, onClear, selectedCount }) => {
  const { t } = useTranslation()
  return (
    <Container direction={'row'} justifyContent={'space-between'} alignItems={'center'} className={'padding-8'}>
      <Container>
        <TextComp level={H_LEVEL.H4}><Trans i18nKey='main.filter.header' /></TextComp>
      </Container>

      <Container direction={'row'} justifyContent={'space-between'}>
        {selectedCount > 0 && (
          <Container spacing={1} direction={'row'} alignItems={'center'} >
            <TextComp level={BODY_LEVEL.MEDIUM} seleniumId={'main-filter_applied-count'}>
              {selectedCount}{' '}
              <Trans i18nKey='main.filter.applied'/>
            </TextComp>
            <ButtonComp
              seleniumId={'main-filter_clear-all'}
              color={COMPONENT_COLOR.SUCCESS}
              type={COMPONENT_VARIANT.LINK}
              size={COMPONENT_SIZE.SMALL}
              onClick={onClear}
              className='padding-0'
            >
              <Trans i18nKey='global.clear'/>
            </ButtonComp>
          </Container>
        )}
        <CloseButtonComp seleniumId={'main-filter_close'} hint={t('main.filter.close')} onClick={onClose}/>
      </Container>
    </Container>
  )
}

export default FilterHeader
