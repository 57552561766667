import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Divider } from '@mui/joy'

import ModalComp from 'components/ModalComp'
import Container from 'components/Container'
import { DetailsHeaderComponent } from 'pages/Public/MainPage/Documents/Details/сomponents/DetailsHeaderComponent'
import {
  fetchSingleDocumentAction,
  setFullDocument,
  setOpenedDocumentId,
  setPdfMode,
  setSnippetDocumentId,
} from 'state/liteDocuments/actions'
import {
  selectFullDocumentSelector,
  selectIsDocLoadingSelector,
  selectIsPdfModeSelector,
  selectListSelector,
  selectOpenedDocumentIdSelector,
  selectSnippetDocumentIdSelector,
} from 'state/liteDocuments/selectors'
import { TDocumentHighlight, TDocumentHighlightModel, TDocumentLite } from 'state/liteDocuments/types'
import { selectMltSelector } from 'state/mainFilter/selectors'
import { documentViewed, textCopied } from 'services/tracking.service'
import { isR2Document, isR2Project } from 'utils/document'

import PdfDocumentComponent from './сomponents/PdfDocumentComponent'
import DetailsComponent from './сomponents/DetailsComponent'
import ExtendTrial from '../Modals/ExtendTrial'
import { isEndTrial } from '../utils'
import SkeletonLoading from './сomponents/SkeletonLoading'

import './style.scss'

const MODAL_WIDTH = 1280

let source: any
const DocumentDetails = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const doc = useSelector(selectFullDocumentSelector)
  const rootDoc = doc?.root_document
  const documentList = useSelector(selectListSelector)
  const id = useSelector(selectOpenedDocumentIdSelector)

  const isLoading = useSelector(selectIsDocLoadingSelector)
  const mlt = useSelector(selectMltSelector)
  const isPdf = useSelector(selectIsPdfModeSelector)
  const snippetDocumentId = useSelector(selectSnippetDocumentIdSelector)
  const [showExtendTrialModal, setShowExtendTrialModal] = useState<boolean>(false)
  const [showDoc, setShowDoc] = useState<boolean>(false)
  const [highlights, setHighlights] = useState<TDocumentHighlight[]>([])
  const [childHighlights, setChildHighlights] = useState<TDocumentHighlightModel>()
  const hasDocument = window.location.pathname.includes('document')

  const getDocument = () => {
    setShowDoc(true)
    setShowExtendTrialModal(false)
    source = axios.CancelToken.source()
    // @ts-ignore
    dispatch(fetchSingleDocumentAction(id, source.token))

    return source
  }
  useEffect(() => {
    if (!doc) return
    const id = doc.root_document?.document_id || doc.document_id

    if (isEndTrial(id)) {
      setShowExtendTrialModal(true)
      setShowDoc(false)
    }
  }, [doc?.document_id])

  // Document fetching
  useEffect(() => {
    if (!id || !hasDocument) return

    const source = getDocument()

    return () => {
      if (!hasDocument) closeDocument()
      if (source) {
        source.cancel('Documents component unmounted')
      }
    }
  }, [id, hasDocument])

  useEffect(() => {
    if (isLoading) setShowDoc(true)
  }, [isLoading])

  // Set pdf mode and event tracking
  useEffect(() => {
    if (!doc) return

    if (doc.pdf_representation && !isR2Document(doc.type) && !doc.text) {
      setIsPdf(true)
    }

    // scroll to div
    const scrollId = snippetDocumentId || id
    if (scrollId) {
      const element = document.getElementById(scrollId)
      if (!element) return
      element.scrollIntoView({ behavior: 'smooth' })
    }

    documentViewed(doc.document_id, doc.type_label, doc.title, doc.source_id)
    document.addEventListener('copy', handleCopied)

    return () => document.removeEventListener('copy', handleCopied)
  }, [doc?.document_id])

  // Highlighting
  useEffect(() => {
    if (!doc || !documentList.length) return

    const target = documentList.find((item: TDocumentLite) => !item.root_document && item.document_id === doc.document_id)
    if (target) {
      setHighlights(target?.meta?.highlights || [])
      setChildHighlights(undefined)
      return
    }

    const project = documentList.find((item: TDocumentLite) => isR2Project(item.type) && item.document_id === doc.document_id)
    if (project) {
      setHighlights(project?.root_document?.meta?.highlights || [])
      setChildHighlights( { documentId: project.document_id, highlights: project?.meta?.highlights || [] })
      return
    }

    setHighlights([])
    setChildHighlights(undefined)
  }, [doc?.document_id, JSON.stringify(documentList)])

  const handleCopied = () => {
    if (!doc?.document_id) return

    const text = window.getSelection()?.toString() || ''
    textCopied(doc.document_id, text)
  }

  const closeDocument = () => {
    dispatch(setFullDocument(null))
    dispatch(setOpenedDocumentId(null))
    dispatch(setSnippetDocumentId(null))
    dispatch(setPdfMode(false))
    setShowDoc(false)
  }

  const handleClose = () => {
    closeDocument()
    navigate('/')
  }

  const handleCloseTrialModal = () => {
    navigate('/')
    setShowExtendTrialModal(false)
  }

  const setIsPdf = (value: boolean) => {
    dispatch(setPdfMode(value))
  }

  const headLabel = rootDoc?.type_label || doc?.type_label || ''
  const showPdf = rootDoc?.pdf_representation  || doc?.pdf_representation

  return (
    <>
      <ModalComp
        className={'document-details'}
        onClose={handleClose}
        open={showDoc}
        width={MODAL_WIDTH}
        grayBackground={isPdf}
        keepMounted
        fullContent
      >
        <>
          <DetailsHeaderComponent
            label={headLabel}
            isPdf={isPdf}
            setIsPdf={setIsPdf}
            onClose={handleClose}
            showPdfButton={showPdf}
            seleniumId={'document-full-view'}
          />
          <Container justifyContent={'center'} direction={'row'} className='modal_content'>
            {isLoading ? (
              <SkeletonLoading />
            ) : (
              <div className={'document-details_container'}>
                <Divider />
                {!isPdf && doc &&
                  <DetailsComponent
                    document={rootDoc || doc}
                    highlights={highlights}
                    childHighlights={childHighlights}
                    mlt={mlt}
                  />
                }
                {isPdf && <PdfDocumentComponent id={id!} />}
              </div>
            )}
          </Container>
        </>
      </ModalComp>
      <ExtendTrial open={showExtendTrialModal} onClose={handleCloseTrialModal} onSend={getDocument}/>
    </>
  )
}

export default DocumentDetails
