import React from 'react'

import './style.scss'

export const AccessDeniedPage = () => {
  return (
    <div className='access-denied'>
      <h1>Access Denied</h1>
    </div>
  )
}
