import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'

import { TMainFilterItem } from 'state/mainFilter/types'
import ButtonComp from 'components/ButtonComp/ButtonCopm'
import { selectAppliedFiltersCollapse, selectSelectedFilterItemsSelector } from 'state/mainFilter/selectors'
import { selectClickedNodeOrEdgeSelector } from 'state/graph/selectors';
import { setClickedNodeOrEdge } from 'state/graph/actions';
import { setAppliedFiltersCollapse, setSelectedItems } from 'state/mainFilter/actions'
import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from 'utils/mui';
import TagComp from 'components/TagComp'
import DeleteButton from 'components/TagComp/chipDelete'
import TextComp, { BODY_LEVEL } from 'components/TextComp';
import Container from 'components/Container'

import './styles.scss'

export default function AppliedFilterTags() {
  const dispatch = useDispatch()
  const selectedFilterItems = useSelector(selectSelectedFilterItemsSelector)
  const clickedNodeOrEdge = useSelector(selectClickedNodeOrEdgeSelector)
  const appliedFiltersCollapse = useSelector(selectAppliedFiltersCollapse)
  const filterItems = appliedFiltersCollapse ? selectedFilterItems.slice(0, 3) : selectedFilterItems;

  if (!selectedFilterItems.length && !clickedNodeOrEdge ) return null

  const setCollapse = (value: boolean) => {
    dispatch(setAppliedFiltersCollapse(value))
  }

  const clearAll = () => {
    dispatch(setSelectedItems([]))
    dispatch(setClickedNodeOrEdge(null))
  }

  const onFilterRemove = (item: TMainFilterItem) => {
    const filtered = selectedFilterItems.filter((it: TMainFilterItem) => it.value !== item.value)
    dispatch(setSelectedItems(filtered))
  }

  const onRemoveClickedNode = () =>
    dispatch(setClickedNodeOrEdge(null))

  return (
    <div className='applied-filters mb-16'>
      <div className='applied-filters-tags' selenium-id={'documents-filter_applied_tags'}>
        {filterItems?.length > 0 &&
            <Container>
              <Container>
                <TextComp level={BODY_LEVEL.SMALL}>
                  <Trans i18nKey='main.filter.applied_filters' />
                </TextComp>
              </Container>
              <Container direction={'row'} sx={{ flexWrap: 'wrap', gap: '4px' }}>
                {filterItems.map((item: TMainFilterItem) => (
                  <TagComp
                    seleniumId={`documents-filter-tag_${item.value}`}
                    key={item.value}
                    sx={{ maxWidth: 300, fontWeight: 400 }}
                    endDecorator={<DeleteButton seleniumId={`documents-filter-tag_remove_${item.value}`} onDelete={() => onFilterRemove(item)}/>}
                  >
                    {item.label}
                  </TagComp>
                ))}
                {selectedFilterItems.length > 3 &&
                    <>
                      {appliedFiltersCollapse
                        ? (<TagComp
                          sx={{ fontWeight: 400 }}
                          onClick={() => setCollapse(false)}
                        >
                          {`+ ${selectedFilterItems.length - filterItems.length}`}
                        </TagComp>)
                        : (
                          <ButtonComp
                            seleniumId={'documents-filter_collapse'}
                            color={COMPONENT_COLOR.SUCCESS}
                            size={COMPONENT_SIZE.SMALL}
                            type={COMPONENT_VARIANT.LINK}
                            onClick={() => setCollapse(true)}
                            className='padding-0'
                          >
                            <Trans i18nKey={'main.filter.collapse'} />
                          </ButtonComp>
                        )
                      }
                    </>
                }
                <ButtonComp
                  seleniumId={'documents-filter-clear_all_filters'}
                  color={COMPONENT_COLOR.SUCCESS}
                  size={COMPONENT_SIZE.SMALL}
                  type={COMPONENT_VARIANT.LINK}
                  onClick={clearAll}
                  className='padding-0'
                >
                  <Trans i18nKey={'main.filter.clear_all'} />
                </ButtonComp>
              </Container>
            </Container>
        }
        {!!clickedNodeOrEdge?.data.length &&
            <Container>
              <Container>
                <TextComp level={BODY_LEVEL.SMALL}>
                  <Trans i18nKey='main.filter.selected_on_graph'/>
                </TextComp>
              </Container>
              {clickedNodeOrEdge?.target?.label &&
                  <TagComp
                    seleniumId={'documents-filter-tag_'}
                    sx={{ maxWidth: 500, fontWeight: 400, background: clickedNodeOrEdge?.target.color }}
                    endDecorator={
                      <DeleteButton seleniumId={`documents-filter-tag_remove_${'qwe'}`}
                        onDelete={() => onRemoveClickedNode()}/>}
                  >
                    {clickedNodeOrEdge?.target.label}
                  </TagComp>
              }
            </Container>
        }
      </div>
    </div>
  )
}
