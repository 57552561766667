export const PRODUCTION = 'prod';
export const STAGING = 'stage';
export const DEV = 'dev';

export const PROD_VERSION = process.env.REACT_APP_ENV === PRODUCTION;
export const STAGE_VERSION = process.env.REACT_APP_ENV === STAGING;
export const DEV_VERSION = process.env.REACT_APP_ENV === DEV;
export const LOCAL_VERSION = !process.env.REACT_APP_ENV;

const config = () => {
  let baseConfig = {
    env: process.env.REACT_APP_ENV,
    enable_doorbell : false,
    doorbell_id: '4226',
    doorbell_apikey: 'E7AWqBAL3StbaJLG4qzCSMilLVVmDbJeG4BlOQUbEoibL3OQp8ZvrBRXNk3IgYoz',
    hostUrl: 'https://dev-dtic.minsky.ai',

    //Google Analytics configs
    enable_ga : false,
    trackingId: 'G-XDCRWQ2FHH',

    //AWS WAF configs
    enabled_waf: false,
    awsChallengeUrl: 'https://079a5c49674a.us-east-1.sdk.awswaf.com/079a5c49674a/deee56b65fbc/challenge.js',
    awsCaptchaUrl: 'https://079a5c49674a.us-east-1.captcha-sdk.awswaf.com/079a5c49674a/jsapi.js',
    // eslint-disable-next-line max-len
    awsCaptchaApiKey: 'ghsoTM5UXUAxQxvVlpV+F9sjmK3JErHRqMdbEE0Np3baHlBbiOMKjV5vY2t17GhXTHMdePmSHcWPoTfao4k/kBKI8C/noqSnWpZGXK1tlfeynks3e8KhiJ8sBUrWc956vEMLj6okXftyZLjT5cJ026Ba5OvQSoDUjcEIwvvdw1sSuvhMTJLz5ZqykGhl1Bpaq9xmgI4WG6KCeGGwcOwlmluFim7gvhodYS6XFKfyv7k9q+cLkhYUx7PzryeW63tsMjeq2Z1oSlgvVO81l4YGdiDhEw90PSRxh/vz78AWyNNrwfC434RTEXwrnksx1UQCIdfmVBkTzJU6Hyxm+SeqvxjWw3UtIo+Eaw9+L6D/ipihJ/AEw6IKTrcc/0yz7s9f9PzL22htb+rvI+SjwuA0CCUw5QCFr25tT74z1z+c3Zc/JxmJy3mBS6LBFikEFhx6io+dOW2R494Pw5NkVaNLqIq8P5PLPJLxdRjz5rnD2AM+rPtoryoLU16vUtwUB3Y2WxUcTAp8QEf+9CHv9sS8b/rraNVmwHA3iNLB5z5SnfFa0QvBaiIS1luQ43XqiO90zN+DFzt/oM4b2ciB/ePf9YnOM5QR2N966qwGK/hOaSaIqapmHNZv+i1sWVekht0XhGuW2sCeNA6Ltx8wrAqezVM3Z/TkBcMgRuhBLj9J1AU=_0_1',

    postHogApiKey: '',
    postHogHost: 'https://stage-dtic.minsky.ai/events',
    postHogId: '41982',

    // Pendo
    enable_pendo : false,
    pendoApiKey: 'b2aea183-6627-480e-4b26-671e68991433',
    //MUI
    muiLicenseKey: '00519f62efcc7ed3680a93afc0aaf885Tz03ODg4MSxFPTE3MzE3NzI1ODEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
    image_proxy: 'https://img.minsky.ai/200',
    image_proxy_v2: 'https://img.minsky.ai/v2/insecure',
    check_version_timeout: 60000
  };

  if (PROD_VERSION) {
    baseConfig = {
      ...baseConfig,
      hostUrl: 'https://dtic.minsky.ai',
      enabled_waf: true,
      enable_ga : true,
      enable_pendo : true,
      enable_doorbell: true,

      awsChallengeUrl: 'https://079a5c49674a.us-east-1.sdk.awswaf.com/079a5c49674a/deee56b65fbc/challenge.js',
      awsCaptchaUrl: 'https://079a5c49674a.us-east-1.captcha-sdk.awswaf.com/079a5c49674a/jsapi.js',
      // eslint-disable-next-line max-len
      awsCaptchaApiKey: 'ghsoTM5UXUAxQxvVlpV+F9sjmK3JErHRqMdbEE0Np3baHlBbiOMKjV5vY2t17GhXTHMdePmSHcWPoTfao4k/kBKI8C/noqSnWpZGXK1tlfeynks3e8KhiJ8sBUrWc956vEMLj6okXftyZLjT5cJ026Ba5OvQSoDUjcEIwvvdw1sSuvhMTJLz5ZqykGhl1Bpaq9xmgI4WG6KCeGGwcOwlmluFim7gvhodYS6XFKfyv7k9q+cLkhYUx7PzryeW63tsMjeq2Z1oSlgvVO81l4YGdiDhEw90PSRxh/vz78AWyNNrwfC434RTEXwrnksx1UQCIdfmVBkTzJU6Hyxm+SeqvxjWw3UtIo+Eaw9+L6D/ipihJ/AEw6IKTrcc/0yz7s9f9PzL22htb+rvI+SjwuA0CCUw5QCFr25tT74z1z+c3Zc/JxmJy3mBS6LBFikEFhx6io+dOW2R494Pw5NkVaNLqIq8P5PLPJLxdRjz5rnD2AM+rPtoryoLU16vUtwUB3Y2WxUcTAp8QEf+9CHv9sS8b/rraNVmwHA3iNLB5z5SnfFa0QvBaiIS1luQ43XqiO90zN+DFzt/oM4b2ciB/ePf9YnOM5QR2N966qwGK/hOaSaIqapmHNZv+i1sWVekht0XhGuW2sCeNA6Ltx8wrAqezVM3Z/TkBcMgRuhBLj9J1AU=_0_1',

      trackingId: 'G-VCGMKFQX61',
      postHogHost: 'https://dtic.minsky.ai/events',
      postHogApiKey: 'phc_vjTeDXuUSt22aMJV90MYlMOD8u3eBOl44fBnFe0f5gV',
      postHogId: '41978'
    }
  }
  if (STAGE_VERSION) {
    baseConfig = {
      ...baseConfig,
      hostUrl: 'https://stage-dtic.minsky.ai',
      enable_doorbell: true,
      enable_pendo: true,
      enable_ga: true,
      trackingId: 'G-XDCRWQ2FHH',
      postHogHost: 'https://stage-dtic.minsky.ai/events',
      postHogApiKey: 'phc_NfcUhIoxNGiWTWHVskHkMXDSdfGf6HqWkyBnHoEdQcI',
      postHogId: '41982',
    }
  }

  //Override local configs here
  if (LOCAL_VERSION) {
    baseConfig = {
      ...baseConfig,
      // add config here
    }
  }

  Object.freeze(baseConfig)
  return baseConfig
}

export default config()
