import { getStorage, setStorage } from 'services/storage.service';
import { TRIAL_EMAIL_STORAGE_NAME, TRIAL_LIMIT_DOC, TRIAL_STORAGE_NAME } from 'constants/global';

export const isEndTrial = (id: string):  boolean => {
  const openedDocIds: string[] = getStorage(TRIAL_STORAGE_NAME) || [];

  if (openedDocIds.length <= TRIAL_LIMIT_DOC && !openedDocIds.includes(id)) {
    openedDocIds.push(id)
    setStorage(TRIAL_STORAGE_NAME, openedDocIds)
  }

  return !getStorage(TRIAL_EMAIL_STORAGE_NAME) && openedDocIds.length > TRIAL_LIMIT_DOC
}
