import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'
import { PDFDocument, StandardFonts, BlendMode, degrees, rgb } from 'pdf-lib'
import { COMPONENT_VARIANT } from 'utils/mui'
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined'

import API from 'api/urls'
import { baseURL } from 'api/api'
import ButtonComp from 'components/ButtonComp/ButtonCopm'
import { PDF_PRE_LINK_TEXT, QUERY_PARAMS } from 'constants/global'
import { encryptedPdf } from 'services/tracking.service'

const WATERMARK_LINK_URL = '/watermark_link.png'

type TProps = {
  id: string
}
const DownloadPDFButton = ({ id }: TProps) => {
  const { document_indicator } = useParams();
  const [searchParams] = useSearchParams()
  const search = searchParams.get(QUERY_PARAMS.QUERY)
  const [downloading, setDownloading] = useState<boolean>(false)

  const getMinskyLink = () => {
    let queryParams = '?pdf=true'
    if (search) {
      queryParams += `&query=${search}`
    }

    return window.location.href.split('?')[0] + queryParams
  }

  const addWatermark = async (originalPdf: any) => {
    const pdfDoc = await PDFDocument.load(originalPdf, { ignoreEncryption: true })

    if (pdfDoc.isEncrypted) {
      encryptedPdf(id)
      return originalPdf
    }

    const firstPage = pdfDoc.getPage(0)
    const { width: pageWidth, height: pageHeight } = firstPage.getSize()

    const imgBytes = await fetch(WATERMARK_LINK_URL).then(res => res.arrayBuffer())
    const watermarkImage = await pdfDoc.embedPng(imgBytes)
    const watermarkWidth = watermarkImage.width
    const watermarkHeight = watermarkImage.height

    const x = pageWidth - watermarkWidth - 5
    const y = (pageHeight - watermarkHeight) / 2

    firstPage.drawImage(watermarkImage, {
      x, y,
      width: watermarkWidth,
      height: watermarkHeight,
    })

    const textLink = `${window.location.host}/shared/${document_indicator}`
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)

    firstPage.drawText(PDF_PRE_LINK_TEXT, {
      x: x + 16,
      y: y + 74,
      size: 12,
      font: helveticaFont,
      color: rgb(0.18, 0.21, 0.25),
      rotate: degrees(90),
      blendMode: BlendMode.Normal,
    })

    firstPage.drawText(textLink, {
      x: x + 16,
      y: y + 200,
      size: 12,
      font: helveticaFont,
      color: rgb(0.13, 0.38, 0.61),
      rotate: degrees(90),
      blendMode: BlendMode.Normal,
    })

    // Define the URL for the link
    const linkUrl = getMinskyLink()

    const linkAnnot = pdfDoc.context.obj({
      Type: 'Annot',
      Subtype: 'Link',
      Rect: [x, y, x + watermarkWidth, y + watermarkHeight],
      Border: [0, 0, 0],
      A: {
        Type: 'Action',
        S: 'URI',
        URI: linkUrl
      }
    })
    // @ts-ignore
    firstPage.node.Annots().push(linkAnnot)
    return await pdfDoc.save()
  }

  const downloadFile = (url: string, fileName: string) => {
    const a = document.createElement('a')
    a.setAttribute('download', fileName)
    a.setAttribute('href', url)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const downloadPdf = async () => {
    setDownloading(true)
    try {
      const apiURL = baseURL + API.public.getDocumentPDFById(id)
      const resp = await fetch(apiURL)
      const buff = await resp.arrayBuffer()
      const pdfBytes = await addWatermark(buff)
      const blob = new Blob([pdfBytes], { type: 'application/octet-stream' })
      const url = URL.createObjectURL(blob)
      downloadFile(url, `document_${document_indicator}.pdf`)
    } catch (error) {
      console.error('Error processing PDF:', error)
    } finally {
      setDownloading(false)
    }
  }

  return (
    <ButtonComp loading={downloading} type={COMPONENT_VARIANT.PRIMARY} onClick={downloadPdf} startDecorator={<SimCardDownloadOutlinedIcon/>}>
      <Trans i18nKey='main.document.download_pdf' />
    </ButtonComp>
  )
}

export default React.memo(DownloadPDFButton)
