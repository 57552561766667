import React from 'react'
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setIsOpen as setIsFilterOpen } from 'state/mainFilter/actions';

import './style.scss'


const StubPage = ({ isActive }: {isActive: boolean}) => {
  const dispatch = useDispatch()

  const handleFilterClick = () => {
    dispatch(setIsFilterOpen(false))
  }

  return (
    <div className={classNames('stub_page',{ active: isActive })} onClick={handleFilterClick}>
    </div>
  )
}

export default StubPage;
