import { fetchExpandedNodeAction, setClickedNodeOrEdge } from 'state/graph/actions'
import Ogma, { EdgeList, Node } from '@linkurious/ogma'
import { ThunkDispatch } from 'state/types'
import { TClickedNodeOrEdge } from 'state/graph/types'
import { GRAPH_GROUP_BY } from 'constants/graph'
import { CLASS_NAMES, getGroupNames, getRoleNames, layoutOptions } from './graphSettings'

const prepareForRedux = (item: Node): TClickedNodeOrEdge => ({
  name: item.getData('filter_name'),
  label: item.getData('label'),
  value: item.getId(),
  type: item.getData('type'),
  group: item.getData('group'),
  group_label: item.getData('group_label'),
  nodes: item.getData('nodes'),
})

const getClickedNodeOrEdge = (target: any) => {
  const isGroup = target?.getData('isGroup')

  if (isGroup) {
    // Clicked on Group
    if (target?.isNode) {
      // Clicked on Grouped Node
      const subNodes = target.getData('nodes')
      const allNodesArray = subNodes.map((item: Node) => prepareForRedux(item))
      return {
        data: allNodesArray,
        target: {
          color: target.getAttribute('color'),
          label: target.getAttribute('text')
        }
      }
    } else {
      // Clicked on Grouped Edge
      let names: any[] = []
      const groupedNodes = target?.getExtremities().map((node: Node) => {
        names = [...names, node.getAttribute('text')]
        const subNodes = node.getData('nodes')
        return subNodes.map((item: Node) => prepareForRedux(item))
      })
      const allNodesArray = groupedNodes.flat()
      return {
        data: allNodesArray,
        target: {
          color: target.getData('groupId'),
          label: `${names[0]} -> ${names[1]}`
        }
      }
    }
  }

  // Clicked on Node
  if (target?.isNode) return {
    data: [prepareForRedux(target)],
    target: {
      color: target.getAttribute('color'),
      label: target.getData('label'),
      graph_nodes: [target.getId()]
    }
  }

  // Clicked on Edge
  const nodeList = target?.getExtremities().map((node: Node) => prepareForRedux(node))
  return {
    data: nodeList,
    target: {
      label: `${nodeList[0].label} - ${target.getData('type_label')} -> ${nodeList[1].label}`,
      graph_nodes: nodeList.map((node: TClickedNodeOrEdge) => node.value),
      relation_types: target.getData('type'),
    }
  }
}


let contextmenu: any = null

function createMenuItem(text: any, action: any) {
  const item = document.createElement('span')
  item.classList.add('item')
  item.innerText = text
  item.addEventListener('click', action)
  item.addEventListener('click', (evt) => {
    evt.preventDefault()
    evt.stopPropagation()
    destroyContextMenu()
  })
  return item
}

type TShowContextMenuProps = {
  target: any
  ogma: Ogma
  dispatch: ThunkDispatch
}

function showContextMenu({ target, ogma, dispatch }: TShowContextMenuProps) {
  const expandButton = createMenuItem('Expand Connections', (evt: any) => {
    evt.preventDefault()
    evt.stopPropagation()

    const center = ogma.view.getCenter()
    const targetPosition = target.getPosition()

    // Decide the direction based on the target's position relative to the center
    const moveX = targetPosition.x < center.x ? -50 : 50 // Move right if target is left of center, else move left
    const moveY = targetPosition.y < center.y ? -50 : 50 // Move down if target is above center, else move up

    // Now apply this with the consideration of other nodes as in the previous logic
    // ...

    // Move the target node to the new position
    target.setAttributes({ x: targetPosition.x + moveX, y: targetPosition.y + moveY })

    const existingNodes = ogma.getNodes()
    const existingEdges = ogma.getEdges()
    // @ts-ignore
    dispatch(fetchExpandedNodeAction([target.getId()], null, target.getPosition(), existingNodes, existingEdges))
  })

  const div = document.createElement('div')
  div.classList.add('context-menu')
  div.appendChild(expandButton)

  const position = target.getPosition()
  contextmenu = ogma.layers.addOverlay({
    element: div,
    position: {
      x: position.x + target.getAttribute('radius'),
      y: position.y + target.getAttribute('radius'),
    },
    scaled: false,
    // @ts-ignore
    size: { width: 200, height: 'auto' },
  })
}

function destroyContextMenu() {
  contextmenu.destroy()
  contextmenu = null
}

export const clearSelected = (ogma: Ogma) => {
  ogma.getEdgesByClassName(CLASS_NAMES.SELECTED).removeClass(CLASS_NAMES.SELECTED)
  ogma.getNodesByClassName(CLASS_NAMES.SELECTED).removeClass(CLASS_NAMES.SELECTED)
  ogma.getNodesByClassName(CLASS_NAMES.SELECTED_MAIN).removeClass(CLASS_NAMES.SELECTED_MAIN)
  ogma.getSelectedNodes().setSelected(false)
  ogma.getSelectedEdges().setSelected(false)
}

type THandleGraphClickProps = {
  dispatch: ThunkDispatch
  ogma: Ogma
}
export const handleGraphClick = ({ dispatch, ogma }: THandleGraphClickProps) => ({ target }: any) => {
  clearSelected(ogma)

  if (!target){
    dispatch(setClickedNodeOrEdge(null));
    return;
  }

  const clickedNodeOrEdge = getClickedNodeOrEdge(target)
  dispatch(setClickedNodeOrEdge(clickedNodeOrEdge))

  if (target.isNode) {
    target.addClass(CLASS_NAMES.SELECTED_MAIN)
    target.getAdjacentEdges().addClass(CLASS_NAMES.SELECTED)
    target.getAdjacentNodes().addClass(CLASS_NAMES.SELECTED)
  } else {
    target.addClass(CLASS_NAMES.SELECTED)
    target.getExtremities().addClass(CLASS_NAMES.SELECTED)
  }



  // const isContextMenuTarget = contextmenu && contextmenu.element.contains(domEvent.target)
  // if (isContextMenuTarget) return // if the target is the context menu just let its item handle the propagated click
  // if (contextmenu) return destroyContextMenu() // otherwise, if there is a context menu, destroy it
  // if (target && target.isNode) showContextMenu({ target, ogma, dispatch }) // otherwise, if the target is a node, create it
}

export type THoverState = {
  hoveredNode: null | Node;
  edgesToHighlight: null | EdgeList;
  nodesToHighlight: null | NodeList;
  nodesToDim: null | NodeList;
  edgesVisibility: boolean[];
}

// type TMouseOverProps = {
//   state: THoverState
//   ogma: Ogma
// }
type AnyState = any; // Represents the state with any structure
type AnyOgma = any; // Represents the Ogma object with any methods

export const handleGraphHover = ({ state, ogma }: { state: AnyState; ogma: AnyOgma }) => ({ target }: { target: any }) => {
  if (!target || !target.isNode) return;

  state.hoveredNode = target as any;
  state.edgesToHighlight = target.getAdjacentEdges({ filter: 'all' }) as any;


  const edgesToHighlight = state.edgesToHighlight || [] as any[];
  state.edgesToDim = edgesToHighlight.inverse()

  state.edgesVisibility = edgesToHighlight.map((edge: any) => edge.isVisible());

  const connectedNodeIds = edgesToHighlight
    .map((edge: any) => edge.getSource() === target ? edge.getTarget() : edge.getSource())
    .filter((node: any) => !node.isVirtual())
    .concat(target) // Add the target node itself to the list for highlighting
    .map((node: any) => node.getId());

  state.nodesToHighlight = ogma.getNodes(connectedNodeIds) as any;
  state.nodesToDim = state.nodesToHighlight.inverse() as any;

  // Using forEach to apply changes to each edge in the edgesToHighlight array
  edgesToHighlight.forEach((edge: any) => {
    edge.setVisible(true);
    edge.addClass('highlighted'); // Use a constant or a string directly for class names
  });

  // Apply changes to each node in the nodesToHighlight array, if it exists
  // if (state.nodesToHighlight) {
  //   state.nodesToHighlight.forEach((node: any) => node.addClass('highlighted'));
  // state.nodesToDim.forEach((node: any) => node.setVisible(false));
  // state.edgesToDim.forEach((node: any) => node.setVisible(false));

  // }

};

// export const handleGraphHover = ({ state, ogma }: TMouseOverProps) => ({ target }: any) => {
//   if (!target || !target.isNode) return
//   // console.log('Node:', target.toJSON())

//   state.hoveredNode = target;
//   state.edgesToHighlight = target.getAdjacentEdges({ filter: 'all' })
//   state.edgesVisibility = (state.edgesToHighlight || []).map(e => e.isVisible())
//   //@ts-ignore
//   state.nodesToHighlight = ogma.getNodes(
//     (state.edgesToHighlight || [])
//       .map(edge =>
//         edge.getSource() === target ? edge.getTarget() : edge.getSource()
//       )
//       .filter(node => !node.isVirtual())
//       .concat(target)
//       .map(node => node.getId())
//   );
//   if(state.nodesToHighlight){
//     // @ts-ignore
//     state.nodesToDim =   state.nodesToHighlight.inverse();

//   }


//   state.edgesToHighlight?.setVisible(true)
//   state.edgesToHighlight?.addClass(CLASS_NAMES.HIGHLIGHTED)
//   // @ts-ignore
//   state.nodesToHighlight?.addClass(CLASS_NAMES.HIGHLIGHTED)
//   // @ts-ignore
//   state.nodesToDim?.addClass(CLASS_NAMES.DIMMED)
// }

// type TMouseOutProps = {
//   state: THoverState
// }
// // export const handleMouseOut = ({ state }: TMouseOutProps) => () => {
//   if (!state.hoveredNode) return;
//   state.edgesToHighlight?.removeClass(CLASS_NAMES.HIGHLIGHTED)
//   // @ts-ignore
//   state.nodesToHighlight?.removeClass(CLASS_NAMES.HIGHLIGHTED)
//   state.edgesToHighlight?.forEach((e: Edge, i) => e.setVisible(state.edgesVisibility[i]))

//   state.edgesToHighlight = null
//   state.hoveredNode = null
// }

// export const handleGraphHover = ({ state, ogma }: { state: AnyState; ogma: AnyOgma }) => ({ target }: { target: any }) => {

export const handleMouseOut = ({ state }: { state: AnyState}) => () => {
  if (!state.hoveredNode) return;

  // Assuming CLASS_NAMES.HIGHLIGHTED is defined elsewhere and accessible
  const highlightedClass = 'highlighted'; // Replace with actual value if different


  // Check and safely call removeClass on edgesToHighlight
  if (state.edgesToHighlight) {
    state.edgesToHighlight.forEach((edge:any) => {
      edge.removeClass(highlightedClass);
      // Reset visibility based on the edgesVisibility array
      const edgeIndex = state.edgesToHighlight.indexOf(edge);
      if (state.edgesVisibility[edgeIndex] !== undefined) {
        edge.setVisible(state.edgesVisibility[edgeIndex]);
      }
    });
  }

  // Check and safely call removeClass on nodesToHighlight
  if (state.nodesToHighlight) {
    // state.nodesToDim.forEach((node:any) => node.removeClass('dimmed'));
    // state.nodesToDim.forEach((node: any) => node.setVisible(true));
    // state.edgesToDim.forEach((node: any) => node.setVisible(true));

    state.nodesToHighlight.forEach((node:any) => {
      node.removeClass(highlightedClass);
    });
  }

  // Reset state properties after clearing the highlights
  state.edgesToHighlight = null;
  state.nodesToHighlight = null;
  state.hoveredNode = null;
  state.edgesVisibility = [];
};

type TGroupingNodes = {
  ogma: Ogma,
  groupBy: GRAPH_GROUP_BY
}
export const applyGrouping = ({ ogma, groupBy }: TGroupingNodes) => {
  const transformation = ogma.transformations.addNodeGrouping({
    groupIdFunction: (node: Node) => node.getData('groupId'),
    nodeGenerator: (nodes: any, groupId: string) => {
      return ({
        id: groupId,
        data: {
          isGroup: true,
          groupId: groupId,
          nodes: nodes,
        },
        attributes: {
          //@ts-ignore
          text: `${nodes.size} ` + (groupBy === GRAPH_GROUP_BY.ROLE ? getRoleNames[groupId] || 'Other' : getGroupNames[groupId] || 'Other'),
          color: groupId,
          opacity: 0.5,
        }
      })
    },
    edgeGenerator: (edges: EdgeList, id: string) => ({
      id: id,
      data: {
        isGroup: true
      },
      attributes: {
        color: '#808080',
        width: .5,
        opacity: 0.5
      }
    }),
    showContents: metaNode => true,
    // @ts-ignore
    onCreated: (_: Node, visible: boolean, subNodes: any) => {
      if (visible) {
        subNodes.setAttributes(
          Ogma.geometry.computeCentroid(subNodes.getAttributes(['x', 'y']))
        );
        return ogma.layouts.force({
          ...layoutOptions,
          nodes: subNodes,
          duration: 0,
        })
      }
    }
  })

  return transformation.whenApplied().then(() => {
    ogma.layouts.force(layoutOptions)
  })
}

type TFilterNodes = {
  ogma: Ogma,
  filter: any,
  currentWeight?: number
}
export const applyFilters = ({ ogma, filter, currentWeight }: TFilterNodes) => {
  filter = ogma.transformations.addNodeFilter(n => {
    const weight = currentWeight === undefined ? false : n.getData('weight') >= currentWeight
    return (
      !!n.getData('isGroup') || weight
    )
  })

  return filter
}

export const getPercentOfZoom = (defaultZoom: number, zoom: number) => {
  return Math.round(100 - (zoom * 100 / defaultZoom))
}
