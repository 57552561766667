import React from 'react'

import SkeletonComp from 'components/SkeletonComp';

import './style.scss'

const SkeletonItem = () => {
  return (
    <div className="filter-skeleton-loading-item">
      <div><SkeletonComp width={150} level="h2" sx={{ mb: 1 }}/></div>
      <div>
        <div><SkeletonComp  level="body-lg"/></div>
        <div><SkeletonComp  level="body-lg"/></div>
      </div>
      <div>
        <div><SkeletonComp  level="body-lg"/></div>
        <div><SkeletonComp  level="body-lg"/></div>
      </div>
      <div>
        <div><SkeletonComp  level="body-lg"/></div>
        <div><SkeletonComp  level="body-lg"/></div>
      </div>
      <div>
        <div><SkeletonComp  level="body-lg"/></div>
        <div><SkeletonComp  level="body-lg"/></div>
      </div>
      <div>
        <div><SkeletonComp  level="body-lg" sx={{ mb: 3 }}/></div>
        <div><SkeletonComp  level="body-lg" sx={{ mb: 3 }}/></div>
      </div>
    </div>
  );
}

export default React.memo(SkeletonItem)
