import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import { TRIAL_EMAIL_STORAGE_NAME } from 'constants/global'
import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from 'utils/mui'
import ModalComp from 'components/ModalComp'
import InputComp from 'components/InputComp'
import ButtonComp from 'components/ButtonComp/ButtonCopm'
import TextComp, { BODY_LEVEL, H_LEVEL, TITLE_LEVEL } from 'components/TextComp'
import { setStorage } from 'services/storage.service'
import { userIdentify } from 'services/tracking.service'

import { sendEmailAPI, validateEmail } from './utils'

import './style.scss'

const MODAL_WIDTH = 480
const STEP = {
  one: 'one',
  two: 'two',
}

type  TProps = {
  open: boolean,
  onClose: () => void,
  onSend: () => void,
}

const ExtendTrial: React.FC<TProps> = ({ open , onClose, onSend }) => {
  const { t } = useTranslation()
  const [value, setValue] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [step, setStep] = useState<string>(STEP.one)

  const disabled = !value || !!error

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>{
    const { value } = e.target
    const errorText = !validateEmail(value) ? t('main.identify.email_validation') : ''
    setValue(value)
    setError(errorText)
  }

  const handleSend = async () => {
    if (disabled) return

    try{
      setIsLoading(true)

      await sendEmailAPI(value)
      userIdentify(value)
      setStorage(TRIAL_EMAIL_STORAGE_NAME, value)

      setIsLoading(false)
      setStep(STEP.two)
    } catch (e) {
      setIsLoading(false)
    }

  }

  const handleEnter = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await handleSend()
    }
  }

  const btnClose = () => {
    onSend()
  }

  const handleClose = () => {
    if (onClose) onClose()
  }

  const renderStepOne = <>
    <TextComp level={TITLE_LEVEL.LARGE} ><Trans i18nKey={'main.identify.modal_title'} /></TextComp>
    <TextComp level={BODY_LEVEL.MEDIUM} ><Trans i18nKey={'main.identify.modal_text_step1'} /></TextComp>
    <InputComp
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e)}
      placeholder={t('main.identify.input_placeholder')}
      onKeyDown={handleEnter}
      seleniumId="extend-trial__input"
      startDecorator={
        <TextComp level={BODY_LEVEL.X_SMALL} sx={{ display: 'flex' }}>
          <EmailOutlinedIcon />
        </TextComp>}
    />
    {(!!value && !!error) && <TextComp color="danger" level={BODY_LEVEL.X_SMALL}>{error}</TextComp>}
    <ButtonComp
      type={COMPONENT_VARIANT.PRIMARY}
      onClick={handleSend}
      loading={isLoading}
      size={COMPONENT_SIZE.LARGE}
      disabled={disabled}
      seleniumId="extend-trial__btn_continue"
    >
      <Trans i18nKey={'main.identify.btn_send'} />
    </ButtonComp></>

  const renderStepTwo = <div className="user_identify__step2">
    <TextComp level={H_LEVEL.H1} color={COMPONENT_COLOR.PRIMARY} ><CheckOutlinedIcon /></TextComp>
    <TextComp level={TITLE_LEVEL.LARGE} ><Trans i18nKey={'main.identify.modal_title_access'} /></TextComp>
    <TextComp level={BODY_LEVEL.MEDIUM} sx={{ mt:2, mb:3 }} ><Trans i18nKey={'main.identify.modal_text_step2'} /></TextComp>
    <ButtonComp
      type={COMPONENT_VARIANT.PRIMARY}
      onClick={btnClose}
      size={COMPONENT_SIZE.LARGE}
      seleniumId="extend-trial__btn_close"
    >
      <Trans i18nKey={'main.identify.btn_close'} />
    </ButtonComp>
  </div>


  return (
    <ModalComp
      open={open}
      onClose={handleClose}
      width={MODAL_WIDTH}
      keepMounted
    >
      {step === STEP.one ? renderStepOne : renderStepTwo}
    </ModalComp>
  )
}

export default React.memo(ExtendTrial)
