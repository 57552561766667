// @ts-nocheck
import Ogma from '@linkurious/ogma';
import { ENTITY_PICTURE_QUERY } from 'constants/global'
import { GRAPH_META_FILTER_TYPES } from 'constants/graph'
import { TGraphData, TGraphEdge, TGraphEdgeRaw, TGraphFilter, TGraphMetaRaw, TGraphNode, TNodePos } from 'state/graph/types'
import { uniqByKey } from './global'

// Helper function to calculate a new position
export const calculateNewPosition = (targetPosition: TNodePos) => {
  // Adjust these values to change the proximity to the target node
  const randomOffsetX = (Math.random() - 0.5) * 100
  const randomOffsetY = (Math.random() - 0.5) * 100

  const newX = targetPosition.x - randomOffsetX
  const newY = targetPosition.y - randomOffsetY

  return { x: newX, y: newY }
}

export const getNodeConnectionTypes = (edges: TGraphEdgeRaw[]): object => {
  const nodeConnectionTypes = {} // Stores counts of different connection types for each node

  edges.forEach((obj: TGraphEdgeRaw) => {
    // Initialize connection counts if necessary
    nodeConnectionTypes[obj.target] = nodeConnectionTypes[obj.target] || { intersects: 0, other: 0 }
    nodeConnectionTypes[obj.source] = nodeConnectionTypes[obj.source] || { intersects: 0, other: 0 }

    // Increment the appropriate count
    if (obj && obj.type === 'INTERSECT_WITH') {
      nodeConnectionTypes[obj.target].intersects++
      nodeConnectionTypes[obj.source].intersects++
    } else {
      nodeConnectionTypes[obj.target].other++
      nodeConnectionTypes[obj.source].other++
    }
  })

  return nodeConnectionTypes
}

export const graphTransformation = ({ edges, nodes }, targetPosition?: TNodePos): TGraphData => {
  const result = { edges: [], nodes: [] };

  // Assuming 'groupLabel' is part of node.data to determine its group
  const groupedNodes = nodes.reduce((acc, node) => {
    const groupLabel = node.group_label;
    if (!acc[groupLabel]) {
      acc[groupLabel] = [];
    }
    acc[groupLabel].push(node);
    return acc;
  }, {});

  // console.log('Grouped nodes', groupedNodes);

  // // Select top 5 nodes for each group by weight
  // const topNodes = Object.values(groupedNodes).flatMap(group =>
  //   group.sort((a, b) => b.weight - a.weight).slice(0, 15)
  // );


  // Select top nodes for each group by weight, 15 for 'org' and 'person', 3 for the rest
  const topNodes = Object.entries(groupedNodes).flatMap(([key, group]) => {
  // Determine the slice amount based on the group key
    const sliceAmount = (key === 'org' || key === 'person') ? 40 : 10;
    // Sort by weight and slice the top nodes accordingly
    return group.sort((a, b) => b.weight - a.weight).slice(0, sliceAmount);
  });

  topNodes.forEach(node => {
    let transformed = { id: node.id, data: {}, attributes: {}};

    if (targetPosition) {
      transformed = { id: node.id, data: {}, attributes: calculateNewPosition(targetPosition) };
    }

    const { id, image, ...rest } = node;
    transformed.data = rest;

    if (image) {
      transformed.data.image = `${image}${ENTITY_PICTURE_QUERY}`
    }

    result.nodes.push(transformed);
  });

  // Filter edges to include only those that connect the selected top nodes
  const topNodeIds = new Set(topNodes.map(node => node.id));
  edges.forEach(edge => {
    edge.target = edge.destination;
    delete edge.destination;

    if (topNodeIds.has(edge.source) && topNodeIds.has(edge.target)) {
      const { source, target, ...rest } = edge;
      const transformed = { source, target, data: rest };
      result.edges.push(transformed);
    }
  });

  // console.log('result', result);
  return result;
};

// export const graphTransformation = ({ edges, nodes }, targetPosition?: TNodePos): TGraphData => {
//   const result = { edges: [], nodes: [] } as TGraphData

//   // Renaming 'destination' to 'target' in edges
//   edges.forEach((edge: TGraphEdgeRaw) => {
//     edge.target = edge.destination
//     delete edge.destination
//   })

//   nodes.forEach((node: TGraphNodeRaw) => {
//     let transformed = { id: node.id, data: {}, attributes: {}}

//     if (targetPosition) {
//       transformed = { id: node.id, data: {}, attributes: calculateNewPosition(targetPosition) }
//     }

//     const { id, image, ...rest } = node
//     transformed.data = rest

//     if (image) {
//       transformed.data.image = `${image}${ENTITY_PICTURE_QUERY}`
//     }

//     result.nodes.push(transformed)
//   })

//   edges.forEach((edge: TGraphEdgeRaw) => {
//     const { source, target, ...rest } = edge
//     const transformed = { source, target, data: rest }

//     result.edges.push(transformed)
//   })

//   console.log('result', result)
//   return result
// }

export const graphTransformationFixer = (existingNodes, existingEdges) => {
  //@ts-ignore
  const combinedGraph = { edges: [], nodes: [] }
  existingNodes.forEach((node) => {
    const position = node.getPosition()
    // eslint-disable-next-line object-curly-spacing
    combinedGraph.nodes.push({ ...node.toJSON(), attributes: { ...position } })
  })
  existingEdges.forEach((edge) => {
    // eslint-disable-next-line object-curly-spacing
    combinedGraph.edges.push({ ...edge.toJSON(), attributes: { opacity: 0.2 } })
  })
  return combinedGraph
}

export const deepMerge = (target, source) => {
  // Iterate through all properties in the source object
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Array) {
      // If the value is an array, concatenate the target and source arrays
      target[key] = target[key] ? target[key].concat(source[key]) : source[key]
    } else if (source[key] instanceof Object) {
      // If the value is an object, recursively call deepMerge
      target[key] = deepMerge(target[key] || {}, source[key])
    } else {
      // For primitive values, simply assign the source value to the target
      target[key] = source[key]
    }
  }
  return target
}

export const parseGraphFilters = (filters: object, data: TGraphData): TGraphFilter[] => {
  const result = [] as TGraphFilter[]
  Object.keys(filters).forEach((key: string) => {
    if (!data.nodes.some((node: TGraphNode) => node.data.type === key || node.data.group === key)) return

    const edges = filters[key].filter((item: TGraphMetaRaw) =>
      item.type === GRAPH_META_FILTER_TYPES.EDGES &&
      data.edges.some((edge: TGraphEdge) => item.value === edge.data.type)
    )
    const roles = filters[key].filter((item: TGraphMetaRaw) =>
      item.type === GRAPH_META_FILTER_TYPES.ROLES &&
      data.nodes.some((node: TGraphNode) => node.data.roles?.some(role => role.name === item.value))
    )

    const filter = {
      nodeType: key,
      edges: uniqByKey(edges, 'value'),
      roles: uniqByKey(roles, 'value'),
    } as TGraphFilter

    result.push(filter)
  })

  return result.sort((a: TGraphFilter, b: TGraphFilter) => a.nodeType.localeCompare(b.nodeType))
}

export const resizeGraph = (ogma: Ogma | null) => {
  if (!ogma) return;
  setTimeout(() => {
    ogma.view.forceResize()
  }, 300)
}

export const filterGraphByLegend = (legend: string[], nodes: TGraphNode[], edges: TGraphEdge[]) => {
  const resultNodes = nodes.filter((node: TGraphNode) =>
    legend.includes(node.data.type) || legend.includes(node.data.group))

  const resultEdges = edges.filter((edge: TGraphEdge) =>
    resultNodes.some((node: TGraphNode) => node.id === edge.source) &&
    resultNodes.some((node: TGraphNode) => node.id === edge.target)
  )

  return { nodes: resultNodes, edges: resultEdges }
}
export const filterGraph = (graphFilter: object, nodes: TGraphNode[], edges: TGraphEdge[]) => {
  let resultNodes = [...nodes]
  let resultEdges = [...edges]

  Object.keys(graphFilter).forEach((key: string) => {
    const filterRoles = graphFilter[key]?.roles || []
    const filterEdges = graphFilter[key]?.edges || []

    if (filterRoles.length) {
      const keyNodes = resultNodes.filter((node: TGraphNode) => node.data.group === key)
      const other = resultNodes.filter((node: TGraphNode) => node.data.group !== key)
      const filteredKeyNodes = keyNodes.filter((node: TGraphNode) => node.data.roles.some(role => filterRoles.includes(role.name)))

      resultNodes = [...filteredKeyNodes, ...other]
    }

    if (filterEdges.length) {
      const { keyEdges, otherEdges } = resultEdges.reduce((acc: object, edge: TGraphEdge) => {
        const sourceNode = nodes.find(n => n.id === edge.source)
        const targetNode = nodes.find(n => n.id === edge.target)
        const dataField = key === 'tag' ? 'type' : 'group'
        const isKeyEdge = [sourceNode?.data[dataField], targetNode?.data[dataField]].includes(key)

        if (isKeyEdge) {
          acc.keyEdges.push(edge)
        } else {
          acc.otherEdges.push(edge)
        }

        return acc
      }, { keyEdges: [], otherEdges: [] })
      const filteredKeyEdges = keyEdges.filter((edge: TGraphEdge) => filterEdges.includes(edge.data.type))
      resultEdges = [...filteredKeyEdges, ...otherEdges]
    }
  })

  // filter Edges where nodeId is OUT
  resultEdges = resultEdges.filter((edge: TGraphEdge) =>
    resultNodes.some((node: TGraphNode) => node.id === edge.source)
    && resultNodes.some((node: TGraphNode) => node.id === edge.target)
  )

  return { nodes: resultNodes, edges: resultEdges }
}