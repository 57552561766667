import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import dayjs from 'dayjs'
import HighchartsReact from 'highcharts-react-official'
import Highstock from 'highcharts/highstock';
import ExportingModule from 'highcharts/modules/exporting';

import { THistogram } from 'state/liteDocuments/types'
import { setDateRange, setDateRangeMode } from 'state/mainFilter/actions'
import { selectDateRange } from 'state/mainFilter/selectors'
import { DATE_MODES } from '../Search/components/DateRange'

ExportingModule(Highstock);

type TProps = {
  data: THistogram[]
}
const Timeline: React.FC<TProps> = ({ data }) => {
  const dispatch = useDispatch()
  const chartRef = useRef(null);
  const initMin = data[0][0] ?? dayjs(data[0][0])
  const initMax = data[data.length - 1][0] ?? dayjs(data[data.length - 1][0])
  const dateRange = useSelector(selectDateRange)

  const [min, setMin] = useState<number>(initMin)
  const [max, setMax] = useState<number>(initMax)

  const memoSeries = useMemo(() => {
    return [{
      lineWidth: 0,
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false
          }
        }
      },
      data: data,
    }]
  }, []);

  useEffect(() => {
    const [start, end] = dateRange

    const navMin = start ? dayjs(start).valueOf() : initMin.valueOf()
    const navMax = end ? dayjs(end).valueOf() : initMax.valueOf()
    setMin(navMin)
    setMax(navMax)

    setTimeout(() => {
      //@ts-ignore
      chartRef?.current?.chart.xAxis[0].setExtremes(navMin, navMax)
    }, 500)

  }, [JSON.stringify(dateRange)])

  const afterSetExtremes = (e: any) => {
    if (e.trigger === 'navigator') {
      dispatch(setDateRangeMode(DATE_MODES.CUSTOM))
      dispatch(setDateRange([dayjs(e.min), dayjs(e.max)]))
    }
  }

  const options = {
    chart: {
      style: { fontFamily: '"Roboto Medium", sans-serif', },
      height: 42,
      margin: 0,
      padding: 0,
      animation: false,
    },
    title: { text: '' },
    accessibility: { enabled: false },
    exporting: { enabled: false, },
    tooltip: { enabled: false },
    credits: { enabled: false },
    legend: { enabled: false, },
    rangeSelector: { enabled: false },
    scrollbar: { enabled: false },
    yAxis: {
      height: 0,
      gridLineWidth: 0,
      labels: { enabled: false }
    },
    xAxis: {
      min,
      max,
      lineWidth: 0,
      tickLength: 0,
      labels: {
        enabled: false
      },
      events: {
        afterSetExtremes,
      },
    },
    navigator: {
      top: 2,
      maskFill: 'rgba(46,54,63,0.10)',
      handles: {
        lineWidth: 1,
        width: isMobile ? 25 : 10,
        height: 25,
      }
    },
    series: memoSeries
  }

  return (
    <div className={'wrapper_highchart'}>
      <HighchartsReact
        ref={chartRef}
        allowChartUpdate
        highcharts={Highstock}
        constructorType="stockChart"
        options={options}
      />
    </div>
  )
}

export default React.memo(Timeline)
