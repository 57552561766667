import React from 'react'
import { formatNumber } from 'utils/global'

import { isR2ProjectCostItem } from 'utils/document';
import { TDocumentLite } from 'state/liteDocuments/types'
import TextComp, { BODY_LEVEL, TITLE_LEVEL } from 'components/TextComp'
import { DOCUMENT_TYPES } from 'constants/document'
import { ReactComponent as TickIco } from 'images/icons/tick.svg'
import { ReactComponent as DollarIco } from 'images/icons/dollar.svg'
import CostItem from '../CostItem'
import AccordionComp, { TListForAccordionComp } from 'components/AccordionComp'
import Container from 'components/Container'

import './styles.scss'

type TProps = {
  title: string,
  documentType: string
  list: TDocumentLite[]
}

export default function CollapseDoc({ title, documentType, list }: TProps) {
  const isCostItem = documentType === DOCUMENT_TYPES.R2_COST_ITEM

  const label = (item: TDocumentLite) => {
    const icon = isCostItem ? <DollarIco/> : <TickIco/>

    const title = isCostItem ? item.details.name : item.title
    const total = isCostItem && formatNumber(item.details.prior_year_amount_norm + item.details.current_year_amount_norm)
    return (
      <div className='title-container'>
        <div className='title-container_label'>
          <span>{icon}</span>
          <span>{title}</span>
        </div>
        {total && (
          <div className='title-container_total'>
            <TextComp level={TITLE_LEVEL.LARGE}>{total}</TextComp>
          </div>
        )}
      </div>
    )
  }

  const content = (item: TDocumentLite) => {
    if (isCostItem) {
      const { details } = item
      const total = formatNumber(details.prior_year_amount_norm + details.current_year_amount_norm)

      return <CostItem
        performingActivity={details.performing_activity}
        priorYearAmount={details.prior_year_amount_norm}
        currentYearAmount={details.current_year_amount_norm}
        total={total}
      />
    }

    return <TextComp level={BODY_LEVEL.MEDIUM}>{item.text}</TextComp>
  }

  const items: TListForAccordionComp[] = list.map((item: TDocumentLite) => {
    return (
      {
        key: item.document_id,
        title: label(item),
        text: content(item),
        isContentNotExist: isR2ProjectCostItem(item.type)
          ? false
          : !item.text?.length,
      }
    )
  })

  return (
    <Container spacing={1}>
      <TextComp level={TITLE_LEVEL.LARGE}>{title}</TextComp>
      <AccordionComp list={items} />
    </Container>
  )
}
