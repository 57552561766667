import React from 'react'
import { Helmet } from 'react-helmet'

type TProps = {
  sources: string[]
}
export default function MetaScript({ sources }: TProps) {
  return (
    <Helmet>
      {sources.map((src: string) =>
        <script key={src} src={src} type="text/javascript" defer/>
      )}
    </Helmet>
  )
}