import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatNumber } from 'utils/global'
import TextComp, { TITLE_LEVEL } from 'components/TextComp'

import './styles.scss'

type TProps = {
  performingActivity: string
  priorYearAmount: number
  currentYearAmount: number
  total: string
}
export default function CostItem({ performingActivity, priorYearAmount, currentYearAmount, total }: TProps) {
  const { t } = useTranslation()
  return (
    <div className='const_item'>
      <div className='const_item__wrap const_item__border'>
        <div className='const_item__left'>
          <TextComp>{t('main.document.performing_activity')}</TextComp>
          <TextComp level={TITLE_LEVEL.LARGE}>{performingActivity}</TextComp>
        </div>
      </div>
      <div className='const_item__wrap'>
        <div className='const_item__right'>
          <TextComp>{t('main.document.prior_year')}</TextComp>
          <TextComp>{formatNumber(priorYearAmount)}</TextComp>
        </div>
        <div className='const_item__right'>
          <TextComp>{t('main.document.current_year')}</TextComp>
          <TextComp>{formatNumber(currentYearAmount)}</TextComp>
        </div>
        <div className='const_item__right'>
          <TextComp level={TITLE_LEVEL.LARGE}>{t('main.document.total')}</TextComp>
          <TextComp level={TITLE_LEVEL.LARGE}>{total}</TextComp>
        </div>
      </div>
    </div>
  )
}