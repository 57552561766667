import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { DOCUMENTS_LIMIT, DOCUMENTS_PREVIEW_LIMIT } from 'constants/global'
import {
  selectDateRange,
  selectMltSelector,
  selectMltModalSelector,
  selectSearchSelector,
  selectSelectedFilterItemsSelector,
} from 'state/mainFilter/selectors'
import { setMlt, setShowMltModal } from 'state/mainFilter/actions'
import { TDocumentLite } from 'state/liteDocuments/types'
import {
  selectDocumentListLoadingSelector,
  selectMltListSelector,
  selectOpenedDocumentIdSelector,
} from 'state/liteDocuments/selectors'
import { fetchMltListAction, generateLink, setFullDocLoading, setMltList, TFetchMltListActionPayload } from 'state/liteDocuments/actions'
import SkeletonLoading from 'pages/Public/MainPage/Documents/List/SkeletonLoading'
import DocumentItemLite from 'pages/Public/MainPage/Documents/List/Item'
import { DetailsHeaderComponent } from 'pages/Public/MainPage/Documents/Details/сomponents/DetailsHeaderComponent'
import ModalComp from 'components/ModalComp'
import Spinner from 'components/LoadingComp'
import NoResultsFound from 'components/NoResultsFound'
import Container from 'components/Container';
import notificator from '../../../../../services/notifications.service'

const MODAL_WIDTH = 1280

const MltModal = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const mlt = useSelector(selectMltSelector)
  const search = useSelector(selectSearchSelector)
  const filter = useSelector(selectSelectedFilterItemsSelector)
  const dateRange = useSelector(selectDateRange)
  const showMltModal = useSelector(selectMltModalSelector)
  const isLoading = useSelector(selectDocumentListLoadingSelector)
  const total = 10//while don't need InfiniteScroll
  const documents = useSelector(selectMltListSelector) || []
  const documentId = useSelector(selectOpenedDocumentIdSelector)

  const [page, setPage] = useState<number>(0)

  const fetchMltList = async () => {
    const payload: TFetchMltListActionPayload = {
      query: search,
      cancelToken: undefined,
      page,
      limit: DOCUMENTS_LIMIT,
      filter,
      dateRange,
      mlt,
    }
    // @ts-ignore
    dispatch(fetchMltListAction(payload))
  }

  const handleClose = () => {
    dispatch(setMlt(null))
    dispatch(setShowMltModal(false))
    dispatch(setMltList([]))
  }

  const handleClick = async (documentId: string) => {
    dispatch(setShowMltModal(false))
    dispatch(setFullDocLoading(true))
    const data = await dispatch(generateLink({ documentId }))
    if (!data?.three_words?.length) return notificator.error()

    navigate(`/document/${data?.three_words}${window.location.search}`)
  }

  const fetchData = () => {
    if (isLoading) return;
    const nextPage = page + 1
    setPage(nextPage)
    fetchMltList()
  }

  useEffect(() => {
    if (!mlt) return;
    const source = axios.CancelToken.source();
    fetchMltList()

    return () => {
      if (source) {
        source.cancel('Documents component unmounted');
      }
    }
  }, [JSON.stringify(mlt)])

  useEffect(() => {
    if (mlt && !window.location.pathname.includes('document')) {
      dispatch(setShowMltModal(true))
    }
  },[documentId, JSON.stringify(mlt)])

  const hasMore = total ? documents.length < total : true
  const noData = !documents.length && !isLoading
  const title = `${t('main.document.similar_documents_to')}: "${mlt?.title as string}".`

  return(
    <>
      <ModalComp
        onClose={handleClose}
        open={showMltModal}
        width={MODAL_WIDTH}
        fullContent
        grayBackground
      >
        <>
          <DetailsHeaderComponent
            label={title}
            onClose={handleClose}
            seleniumId={'show_similar'}
          />

          <Container className='modal_content'>
            <Container justifyContent={'center'} direction={'row'} className='documents-list-lite'>
              {noData && <NoResultsFound title={t('main.document.mlt_no_results_title')} />}
              {isLoading
                ? <SkeletonLoading/>
                : <InfiniteScroll
                  dataLength={documents.length}
                  next={fetchData}
                  hasMore={hasMore}
                  loader={isLoading && <Spinner/>}
                  scrollableTarget="scrollableDiv"
                >
                  {documents.map((item: TDocumentLite) =>
                    <DocumentItemLite
                      key={item.document_id}
                      document={item}
                      onClick={handleClick}
                      limit={DOCUMENTS_PREVIEW_LIMIT}
                      mlt={mlt}
                    />)}
                </InfiniteScroll>
              }
            </Container>
          </Container>
        </>
      </ModalComp>
    </>
  )
}

export default MltModal
