import dayjs from 'dayjs'
import { ThunkAction } from 'state/types'
import axiosInstance from 'api/api'
import API from 'api/urls'
import { GRAPH_GROUP_BY } from 'constants/graph'
import { GRAPH_NODE_LIMIT } from 'constants/global'
import { deepMerge, graphTransformation, graphTransformationFixer, parseGraphFilters } from 'utils/graph'
import { TMainFilterItem } from '../mainFilter/types'
import {
  GRAPH_FILTER,
  serializeDateRangeQuery,
  serializeFilterToQuery,
  serializeGraphFilterToQuery,
  serializeSortToQuery
} from 'utils/mainFilter'
import ActionTypes from './action-types'
import { TClickedNodeOrEdgeState, TGraphData, TGraphFilter } from './types'

export const setIsLoading = (value: boolean) => ({
  type: ActionTypes.setIsLoading,
  payload: value,
})

export const setIsOpen = (value: boolean) => ({
  type: ActionTypes.setIsOpen,
  payload: value,
})

export const setData = (value: any) => ({
  type: ActionTypes.setData,
  payload: value,
})
export const setGraphDistribution = (value: any) => ({
  type: ActionTypes.setGraphDistribution,
  payload: value,
})

export const fetchExpandedNodeAction = (
  ids: string[],
  cancelToken: any,
  targetPosition: any,
  existingNodes: any,
  existingEdges: any
): ThunkAction<Promise<void>>  => {
  return async (dispatch) => {
    try {
      const existingPositions = {}
      if (!ids.length) return
      // @ts-ignore
      existingNodes.forEach((node) => (existingPositions[node.getId()] = node.getPosition()))
      console.log('fetch ids', ids)
      //todo: add context of query
      const response = await axiosInstance.get(API.public.getGraph, {
        params: {
          node_id: ids.join(','),
          // query: query.trim(),
          // limit,
          // offset: page,
        },
        // paramsSerializer: paramsSerializerGraphAction({ dateRange, filter, selectedNodes, selectedEdges }),
        cancelToken
      })
      console.log('response', response)
      const { nodes, edges } = response.data
      if (!nodes.length) return

      const oldGraph = graphTransformationFixer(existingNodes, existingEdges)
      console.log('oldGraph', oldGraph)
      const newGraph = graphTransformation({ nodes, edges }, targetPosition)
      console.log('newGraph', newGraph)

      // Get graph state and merge the new data
      const newData = deepMerge({ ...oldGraph }, newGraph)
      dispatch(setData(newData))
    } catch (e) {
      console.log('ERROR', e)
      /* empty */
    }
  }
}

// @ts-ignore
const paramsSerializerGraphAction = ({ dateRange, filter, selectedNodes, selectedEdges, selectedRoles }) => (params: any) => {
  const query = Object.entries(params).flatMap(([key, value]) =>
    Array.isArray(value)
      ? value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
      // @ts-ignore
      : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
  ).join('&')

  return query
    + serializeDateRangeQuery(dateRange)
    + serializeFilterToQuery(filter)
    + serializeGraphFilterToQuery(selectedNodes, GRAPH_FILTER.NODES)
    + serializeGraphFilterToQuery(selectedEdges, GRAPH_FILTER.EDGES)
    + serializeGraphFilterToQuery(selectedRoles, GRAPH_FILTER.ROLES)
}

// @ts-ignore
const paramsSerializerFullGraphAction = ({ filter, dateRange, sort }) => (params: any) => {
  const query = Object.entries(params).flatMap(([key, value]) =>
    Array.isArray(value)
      ? value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`)
      // @ts-ignore
      : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
  ).join('&')

  return query
    + serializeFilterToQuery(filter)
    + serializeDateRangeQuery(dateRange)
    + serializeSortToQuery(sort)

}

export const setGraphData = (data: TGraphData) => ({
  type: ActionTypes.setData,
  payload: data,
})

export const setFilter = (data: TGraphFilter[]) => ({
  type: ActionTypes.setGraphFilter,
  payload: data,
})

export const setSelectedFilter = (data: object) => ({
  type: ActionTypes.setSelectedGraphFilter,
  payload: data,
})

export const setSelectedNodes = (data: string[]) => ({
  type: ActionTypes.setSelectedNodesFilter,
  payload: data,
})

export const setSelectedEdges = (data: string[]) => ({
  type: ActionTypes.setSelectedEdgesFilter,
  payload: data,
})

export const setSelectedRoles = (data: string[]) => ({
  type: ActionTypes.setSelectedRolesFilter,
  payload: data,
})


export const setNodesLimitation = (data: number) => ({
  type: ActionTypes.setNodesLimit,
  payload: data,
})

export const setClickedNodeOrEdge = (payload: TClickedNodeOrEdgeState | null) => ({
  type: ActionTypes.setClickedNodeOrEdgeAction,
  payload: payload,
})

export const setGrouping = (data: GRAPH_GROUP_BY | string) => ({
  type: ActionTypes.setGroupBy,
  payload: data,
})

type TFetchGraphActionProps = {
  query: string,
  cancelToken: any,
  page: number,
  limit: number,
  filter: TMainFilterItem[],
  dateRange: dayjs.ConfigType[],
  selectedNodes: string[],
  selectedEdges: string[],
  selectedRoles: string[],
  nodeQuantity: number
}
export const fetchGraphAction = ({
  query = '',
  cancelToken,
  page = 0,
  limit = GRAPH_NODE_LIMIT,
  filter = [],
  dateRange,
  selectedNodes = [],
  selectedEdges = [],
  selectedRoles = [],
  nodeQuantity
}: TFetchGraphActionProps): ThunkAction<Promise<void>> => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true))

      const response = await axiosInstance.get(API.public.getGraph, {
        params: {
          query: query.trim(),
          node_quantity: nodeQuantity,
          // limit,
          // offset: page,
          // facet_limit: FACET_LIMIT_ARRAY,
        },
        paramsSerializer: paramsSerializerGraphAction({ dateRange, filter, selectedNodes, selectedEdges, selectedRoles }),
        cancelToken
      })

      if (response.data) {
        const { nodes, edges, metadata } = response.data
        const data = graphTransformation({ nodes, edges })
        const filter = parseGraphFilters(metadata?.nodes, data)

        dispatch(setGraphData(data))
        dispatch(setFilter(filter))
      }
    } catch (e) {
      // empty
    }
    finally {
      dispatch(setIsLoading(false))
    }
  }
}



// @ts-ignore
function transformMinMaxWeights({ nodes, edges }) {
  const minMaxWeights = { nodes: {}, edges: {}};
  // console.log('nodes inside transformMinMaxWeights', nodes)
  // @ts-ignore
  minMaxWeights['nodes']['count'] = nodes.length;
  // Process nodes
  // @ts-ignore
  nodes.forEach(node => {
    const groupLabel = node.data.group_label;
    const weight = node.data.weight;
    // @ts-ignore
    if (!minMaxWeights.nodes[groupLabel]) {
      // @ts-ignore
      minMaxWeights.nodes[groupLabel] = { min: weight, max: weight };
    } else {
      // @ts-ignore
      minMaxWeights.nodes[groupLabel].min = Math.min(minMaxWeights.nodes[groupLabel].min, weight);
      // @ts-ignore
      minMaxWeights.nodes[groupLabel].max = Math.max(minMaxWeights.nodes[groupLabel].max, weight);
    }
  });

  // Process edges
  // @ts-ignore
  edges.forEach(edge => {
    const groupLabel = edge.data.type;
    const weight = edge.data.weight;
    // @ts-ignore
    if (!minMaxWeights.edges[groupLabel]) {
      // @ts-ignore
      minMaxWeights.edges[groupLabel] = { min: weight, max: weight };
    } else {
      // @ts-ignore
      minMaxWeights.edges[groupLabel].min = Math.min(minMaxWeights.edges[groupLabel].min, weight);
      // @ts-ignore
      minMaxWeights.edges[groupLabel].max = Math.max(minMaxWeights.edges[groupLabel].max, weight);
    }
  });

  // console.log('minMaxWeights', minMaxWeights)
  return minMaxWeights;
}


// function transformNodesAndTrackMinMaxWeights(data = []) {
//   const minMaxWeights = { nodes: {}};
//   // @ts-ignore
//   data.forEach(obj => {
//     // const obj = node.getData(); // Assuming getData without arguments returns the full data object
//     const groupLabel = obj['group_label'];
//     const weight = obj['weight'];

//     // @ts-ignore
//     // Track min and max weights by group_label for nodes
//     if (!minMaxWeights.nodes[groupLabel]) {
//       // @ts-ignore
//       minMaxWeights.nodes[groupLabel] = { min: weight, max: weight };
//       // @ts-ignore
//     } else {
//       // @ts-ignore
//       minMaxWeights.nodes[groupLabel].min = Math.min(minMaxWeights.nodes[groupLabel].min, weight);
//       // @ts-ignore
//       minMaxWeights.nodes[groupLabel].max = Math.max(minMaxWeights.nodes[groupLabel].max, weight);
//     }
//   });

//   // Here you can return the result or work with it as needed
//   console.log(minMaxWeights);
//   return minMaxWeights;
// }

type TFetchFullGraphActionProps = {
  query: string,
  cancelToken: any,
  filter: TMainFilterItem[],
  dateRange: dayjs.ConfigType[]
  sort: string
}
export const fetchFullGraphAction = ({
  query = '',
  cancelToken,
  filter = [],
  dateRange,
  sort
}: TFetchFullGraphActionProps): ThunkAction<Promise<void>> => {
  return async (dispatch) => {
    try {
      dispatch(setIsLoading(true))
      const response = await axiosInstance.get(API.public.getFullGraph, {
        params: { query: query.trim() },
        paramsSerializer: paramsSerializerFullGraphAction({ filter, dateRange, sort }),
        cancelToken
      })

      if (response.data) {
        const { nodes, edges, metadata } = response.data
        const data = graphTransformation({ nodes, edges })
        const graphWeightDistribution = transformMinMaxWeights(data)
        const filter = parseGraphFilters(metadata?.nodes, data)
        const selectedFilter = filter.map(f => ({ [f.nodeType]: { roles: [], edges: [] }}))

        dispatch(setGraphDistribution(graphWeightDistribution))
        dispatch(setSelectedFilter(selectedFilter))
        dispatch(setSelectedNodes(filter.map(f => f.nodeType)))
        dispatch(setGraphData(data))
        dispatch(setFilter(filter))
      }
    } catch (e) {
      // empty
    }
    finally {
      dispatch(setIsLoading(false))
    }
  }
}
