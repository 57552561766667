import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { Dropdown, Menu, MenuButton, MenuItem } from '@mui/joy'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'

import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from 'utils/mui'
import { selectFilterSelector, selectGraphFilterSelector, selectSelectedNodesFilterSelector } from 'state/graph/selectors'
import { setSelectedFilter, setSelectedNodes } from 'state/graph/actions'
import TagComp from 'components/TagComp'
import Container from 'components/Container'
import TextComp, { BODY_LEVEL } from 'components/TextComp'
import ButtonComp from 'components/ButtonComp/ButtonCopm'
import CheckboxComp from 'components/CheckboxComp'
import { TGraphData, TGraphFilter, TGraphMetaRaw, TGraphNode } from 'state/graph/types'

type TProps = {
  graphData?: TGraphData
}
const GraphFilter = ({ graphData }: TProps) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState<string | null>(null)
  const filters = useSelector(selectFilterSelector)
  const selectedGraphFilter = useSelector(selectGraphFilterSelector)
  const selectedNodes = useSelector(selectSelectedNodesFilterSelector)
  const handleNode = (e: React.ChangeEvent<HTMLInputElement>, node: string) => {
    e.stopPropagation()
    e.preventDefault()

    const selected = [...selectedNodes]
    if (selected.length === 1 && selected.includes(node)) return

    const list = selected.includes(node) ? selected.filter((it: string) => it !== node) : [...selected, node]
    dispatch(setSelectedNodes(list))
  }

  const handleEdge = (e: any, nodeType: string, edge: string) => {
    e.stopPropagation()

    const graphFilter = { ...selectedGraphFilter }

    // @ts-ignore
    const target = graphFilter[nodeType]

    if (target) {
      const selected = target?.edges || []
      // @ts-ignore
      graphFilter[nodeType].edges = selected.includes(edge) ? selected.filter((it: string) => it !== edge) : [...selected, edge]
    } else {
      // @ts-ignore
      graphFilter[nodeType] = { edges: [edge] }
    }

    dispatch(setSelectedFilter(graphFilter))
  }

  const handleRole = (e: any, nodeType: string, role: string) => {
    e.stopPropagation()
    const graphFilter = { ...selectedGraphFilter }

    // @ts-ignore
    const target = graphFilter[nodeType]

    if (target) {
      const selected = target?.roles || []
      // @ts-ignore
      graphFilter[nodeType].roles = selected.includes(role) ? selected.filter((it: string) => it !== role) : [...selected, role]
    } else {
      // @ts-ignore
      graphFilter[nodeType] = { roles: [role] }
    }

    dispatch(setSelectedFilter(graphFilter))
  }

  const handleReset = (e: any, nodeType: string, field: 'edges' | 'roles') => {
    e.stopPropagation()
    const graphFilter = { ...selectedGraphFilter }

    // @ts-ignore
    if (!graphFilter[nodeType]) return
    // @ts-ignore
    graphFilter[nodeType][field] = []
    dispatch(setSelectedFilter(graphFilter))
  }

  const getNodeColor = (node: string) => {
    switch (node) {
      case 'person':
        return COMPONENT_COLOR.AZURE
      case 'org':
        return COMPONENT_COLOR.SALMON
      case 'tag':
        return COMPONENT_COLOR.JADE
      default:
        return COMPONENT_COLOR.NEUTRAL
    }
  }

  const handleOpen = (nodeType: string) => {
    if (nodeType === open) {
      setOpen(null)
    } else {
      setOpen(nodeType)
    }
  }

  if (!filters.length) return null

  const filterDisplayName: any = {
    'org': {
      singular: 'Organization',
      plural: 'Organizations',
    },
    'person': {
      singular: 'Person',
      plural: 'People',
    },
    'tag': {
      singular: 'Tag',
      plural: 'Tags',
    }
  }

  const getLabel = (nodeType: string ) => {
    const count = graphData?.nodes.filter((node: TGraphNode) => [node.data.group, node.data.type].includes(nodeType)).length || 0
    if (count > 1) return `${count} ${filterDisplayName[nodeType].plural}`
    if (count === 1) return `${count} ${filterDisplayName[nodeType].singular}`

    return filterDisplayName[nodeType].plural
  }

  return (<>
    {filters.map((f: TGraphFilter) => {
      // @ts-ignore
      const selectedEdges = selectedGraphFilter[f.nodeType]?.edges || []
      // @ts-ignore
      const selectedRoles = selectedGraphFilter[f.nodeType]?.roles || []
      const selectedSize = selectedEdges.length + selectedRoles.length
      const arrowIcon = open === f.nodeType ? <ArrowDropUp/> : <ArrowDropDown />

      return (
        <Dropdown
          key={f.nodeType}
          open={open === f.nodeType}
          onOpenChange={() => handleOpen(f.nodeType)}
        >
          <MenuButton
            size={COMPONENT_SIZE.SMALL}
            endDecorator={arrowIcon}
            sx={{ backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between', border: 'none' }}
          >
            <Container spacing={1} direction={'row'} alignItems={'center'}>
              <CheckboxComp
                color={getNodeColor(f.nodeType)}
                variant={COMPONENT_VARIANT.PRIMARY}
                onClick={e => e.stopPropagation()}
                seleniumId={`graph-filter-checkbox_${f.nodeType}`}
                checked={selectedNodes.includes(f.nodeType)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNode(e, f.nodeType)}
              />

              <Container alignItems={'start'}>
                <TextComp color={selectedNodes.includes(f.nodeType) ? undefined : COMPONENT_COLOR.NEUTRAL} level={BODY_LEVEL.MEDIUM}>
                  {getLabel(f.nodeType)}
                </TextComp>
                {!!selectedSize && <TextComp color={COMPONENT_COLOR.NEUTRAL} level={BODY_LEVEL.X_SMALL}>Filter Applied</TextComp>}
              </Container>
            </Container>
              
          </MenuButton>
          <Menu placement={'bottom-start'} sx={{ width: 400, padding: 1 }}>
            <MenuItem sx={{
              '&:not(.Mui-selected):hover': {
                cursor: 'default',
                backgroundColor: 'white',
              },
            }}
            >
              <Container spacing={2} sx={{ width: '100%' }}>
                <Container spacing={1}>
                  {f.roles.length > 0 && (
                    <Container spacing={1}>
                      <Container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <TextComp color={COMPONENT_COLOR.NEUTRAL} level={BODY_LEVEL.MEDIUM}>
                          <Trans i18nKey='main.graph.roles'/>
                        </TextComp>
                        <ButtonComp
                          seleniumId={'graph-filter_reset-roles'}
                          color={COMPONENT_COLOR.SUCCESS}
                          type={COMPONENT_VARIANT.LINK}
                          size={COMPONENT_SIZE.SMALL}
                          onClick={(e) => handleReset(e, f.nodeType, 'roles')}
                        >
                          <Trans i18nKey='global.reset'/>
                        </ButtonComp>
                      </Container>
                      <div>
                        {f.roles.map((role: TGraphMetaRaw) => (
                          <TagComp
                            seleniumId={`graph-filter_${f.nodeType}_${role.value}`}
                            key={`${f.nodeType}_${role.value}`}
                            className={'mr-4 mb-4'}
                            size={COMPONENT_SIZE.MEDIUM}
                            color={COMPONENT_COLOR.NEUTRAL}
                            variant={selectedRoles.includes(role.value) ? COMPONENT_VARIANT.PRIMARY : COMPONENT_VARIANT.DEFAULT}
                            onClick={(e) => handleRole(e, f.nodeType, role.value)}
                          >
                            {role.label}
                          </TagComp>
                        ))}
                      </div>
                    </Container>
                  )}
                  <Container spacing={1}>
                    <Container direction={'row'} justifyContent={'space-between'}>
                      <TextComp color={COMPONENT_COLOR.NEUTRAL} level={BODY_LEVEL.MEDIUM}>
                        <Trans i18nKey='main.graph.relationships'/>
                      </TextComp>
                      <ButtonComp
                        seleniumId={'graph-filter_reset_edges'}
                        color={COMPONENT_COLOR.SUCCESS}
                        type={COMPONENT_VARIANT.LINK}
                        size={COMPONENT_SIZE.SMALL}
                        onClick={(e) => handleReset(e, f.nodeType, 'edges')}
                      >
                        <Trans i18nKey='global.reset'/>
                      </ButtonComp>
                    </Container>
                    <div>
                      {f.edges.map((edge: TGraphMetaRaw) => (
                        <TagComp
                          className={'mr-4 mb-4'}
                          size={COMPONENT_SIZE.MEDIUM}
                          seleniumId={`graph-filter_${f.nodeType}_${edge.value}`}
                          color={COMPONENT_COLOR.NEUTRAL}
                          variant={selectedEdges.includes(edge.value) ? COMPONENT_VARIANT.PRIMARY : COMPONENT_VARIANT.DEFAULT}
                          key={`${f.nodeType}_${edge.value}`}
                          onClick={(e) => handleEdge(e, f.nodeType, edge.value)}
                        >
                          {edge.label}
                        </TagComp>
                      ))}
                    </div>
                  </Container>
                </Container>
              </Container>
            </MenuItem>
          </Menu>
        </Dropdown>    
      )})}
  </>
  )
}

export default GraphFilter
