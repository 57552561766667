import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { Divider } from '@mui/joy'

import { FILTER_ITEMS_LIMIT, FILTER_SHOW_SEARCH_LIMIT } from 'constants/global'
import { formatNumber, uniqByKey } from 'utils/global'
import { COMPONENT_COLOR, COMPONENT_VARIANT } from 'utils/mui'
import { isR2Document, isR2Program, isR2Project, isR2ProjectAccomplishment } from 'utils/document'
import { R2_PROGRAM } from 'utils/mainFilter'
import InputComp from 'components/InputComp'
import { TMainFilterItem, TMainFilterType } from 'state/mainFilter/types'
import { selectFilterSearchSelector, selectIsFilterLoadingSelector } from 'state/mainFilter/selectors'
import { setFilterSearch } from 'state/mainFilter/actions'
import NoMatches from 'components/NoMatchesComp/NoMatches'
import ButtonComp from 'components/ButtonComp/ButtonCopm'
import LoadingComp from 'components/LoadingComp'
import CheckboxComp from 'components/CheckboxComp'
import TextComp, { BODY_LEVEL, H_LEVEL } from 'components/TextComp'
import Container from 'components/Container'
import TooltipText from 'components/TooltipTextComp'

import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

import NestedCheckboxes from './nestedCheckboxes'

import './style.scss'

type TProps = {
  title: string
  items: TMainFilterType[]
  selectedItems: TMainFilterItem[]
  setSelectedItems: (items: TMainFilterItem[]) => void
}
const FilterItem: React.FC<TProps> = ({ title, items, selectedItems, setSelectedItems }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [loadingType, setLoadingType] = useState<string| null>(null)
  const [loadingSearchType, setLoadingSearchType] = useState<string| null>(null)
  const filterSearch = useSelector(selectFilterSearchSelector)
  const filterLoading = useSelector(selectIsFilterLoadingSelector)

  useEffect(()=> {
    if (!filterLoading) {
      setLoadingType(null)
      setLoadingSearchType(null)
    }
  }, [JSON.stringify(filterLoading)])

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>, type: TMainFilterType) => {
    const { value } = event.target
    setLoadingSearchType(type.name)
    const newSearch = { ...filterSearch }
    //@ts-ignore
    newSearch[type.name] = { ...newSearch[type.name], search: value }
    dispatch(setFilterSearch(newSearch))
  }

  const handleMore = (e: MouseEvent, type: TMainFilterType) => {
    e.preventDefault()
    setLoadingType(type.name)
    const newSearch = { ...filterSearch }
    const limit = type.items.length + FILTER_ITEMS_LIMIT
    //@ts-ignore
    newSearch[type.name] = { ...newSearch[type.name], limit }
    dispatch(setFilterSearch(newSearch))
  }

  const handleCollapse = (e: MouseEvent, type: TMainFilterType) => {
    e.preventDefault()
    setLoadingType(type.name)
    const newSearch = { ...filterSearch }
    //@ts-ignore
    delete newSearch[type.name]['limit']
    dispatch(setFilterSearch(newSearch))
  }

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>, item: TMainFilterItem) => {
    const { checked } = e.target
    const selected = [...selectedItems]

    if (checked) {
      selected.push(item)
      setSelectedItems(selected)
    } else {
      const filtered = selected.filter((it: TMainFilterItem) => it.value !== item.value)
      setSelectedItems(filtered)
    }
  }

  const handleFilterArrayChange = (e: React.ChangeEvent<HTMLInputElement>, items: TMainFilterItem[]) => {
    const { checked } = e.target
    const selected = [...selectedItems]

    if (checked) {
      const updated = uniqByKey([...selected, ...items], 'value')
      setSelectedItems(updated)
    } else {
      const filtered = selected.filter((it: TMainFilterItem) => !items.find(ite => ite.value === it.value))
      setSelectedItems(filtered)
    }
  }

  const getSelectedProgramObject = (data: TMainFilterItem[]) => {
    const program = JSON.parse(JSON.stringify(R2_PROGRAM))

    const selectedProgram = data.find(it => isR2Program(it.value))
    if (selectedProgram) {
      program.count = selectedProgram.count
    }

    const selectedProject = data.find(it => isR2Project(it.value))
    if (selectedProject) {
      program.children[0].count = selectedProject.count
    }

    const selectedAccomplishment = data.find(it => isR2ProjectAccomplishment(it.value))
    if (selectedAccomplishment) {
      program.children[0].children[0].count = selectedAccomplishment.count
    }

    return program
  }

  const nestedCheckbox = (data: TMainFilterItem[]) => {
    const program = getSelectedProgramObject(data)

    return (
      <NestedCheckboxes
        key={program.value}
        parent={program}
        onChange={handleFilterArrayChange}
        selectedItems={selectedItems}
      />
    )
  }

  const checkbox = (item: TMainFilterItem) => {
    return (
      <Container key={item.value} className={'filter-item__wrap'}>
        <label className={'checkbox-label'} selenium-id={`main-filter-item-label_${item.label}`}>
          <Container spacing={1} direction={'row'} alignItems={'center'}>
            <CheckboxComp
              id={item.value}
              checked={selectedItems.some((it: TMainFilterItem) => it.value === item.value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFilterChange(e, item)}
            />
            <TooltipText maxWidth={280}>
              <span>{item.label}</span>
            </TooltipText>
          </Container>
        </label>
        <TextComp seleniumId={`main-filter-item-count_${item.label}`} color={COMPONENT_COLOR.NEUTRAL} level={BODY_LEVEL.LARGE}>
          {formatNumber(item.count)}
        </TextComp>
      </Container>
    )
  }

  const showCollapse = (type: TMainFilterType): boolean => {
    if (loadingType === type.name) return false
    // @ts-ignore
    return filterSearch[type.name]?.limit
  }

  const empty = items.every(it => !it.items.length)

  return (
    <Container spacing={1} className="filter-item mb-12">
      {!empty && <TextComp level={H_LEVEL.H4} className="filter-item__title">{title}</TextComp>}
      {items.map((type: TMainFilterType) => {
        // @ts-ignore
        const inputText = filterSearch[type.name]?.search
        const showSearch = type.items?.length >= FILTER_SHOW_SEARCH_LIMIT || inputText

        // Simple items
        const selected = selectedItems.filter((item: TMainFilterItem) => item.name === type.name && !isR2Document(item.value))
        const itemForSelect = type.items.filter(item => !isR2Document(item.value) && !selected.some(it => it.value === item.value))

        // Subtypes items
        const selectedR2Documents = selectedItems.filter((item: TMainFilterItem) => item.name === type.name && isR2Document(item.value))
        const r2Document = !selectedR2Documents.length && type.items.find(item => isR2Document(item.value))

        const typeLoading = loadingSearchType === type.name
        const hasMore = (type.items.length < type.total) && (type.name !== 'type') // need to handle children count
        const noData = !typeLoading && !type.items.length

        if (noData && !inputText) return null
        return (
          <Container spacing={1} key={type.label} seleniumId={`main-filter-item_${type.label}`}>
            <Container spacing={1} >
              <TextComp level={H_LEVEL.H4}>{type.label}</TextComp>
              {showSearch &&
                <InputComp
                  seleniumId={`main-filter-item-search_${type.label}`}
                  value={inputText}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e, type)}
                  placeholder={t('global.search')}
                />
              }
              {selected.map((item: TMainFilterItem) => checkbox(item))}
              {!!selectedR2Documents.length && nestedCheckbox(type.items)}

              {selected.length > 0 && <Divider />}
              {noData && <NoMatches />}
              {typeLoading
                ? <LoadingComp />
                : (
                  <>
                    {itemForSelect.map((item: TMainFilterItem) => checkbox(item))}
                    {r2Document && nestedCheckbox(type.items)}
                  </>
                )
              }
            </Container>
            {loadingType === type.name ? <LoadingComp /> : (
              <Container direction={'row'} spacing={1}>
                {!loadingSearchType && hasMore && (
                  <ButtonComp
                    seleniumId={`main-filter-item-show_more_${type.label}`}
                    color={COMPONENT_COLOR.SUCCESS}
                    type={COMPONENT_VARIANT.LINK}
                    size={'xs'}
                    onClick={(e: MouseEvent) => handleMore(e, type)}
                    startDecorator={<ExpandMoreOutlinedIcon sx={{ fontSize: 20 }} />}
                  >
                    <Trans i18nKey={'main.filter.show_more'}></Trans>
                  </ButtonComp>
                )}
                {showCollapse(type) && (
                  <ButtonComp
                    seleniumId={`main-filter-item-show_less_${type.label}`}
                    type={COMPONENT_VARIANT.LINK}
                    color={COMPONENT_COLOR.SUCCESS}
                    size={'xs'}
                    onClick={(e: MouseEvent) => handleCollapse(e, type)}
                    startDecorator={<ExpandLessOutlinedIcon sx={{ fontSize: 20 }} />}
                  >
                    <Trans i18nKey={'main.filter.show_less'} />
                  </ButtonComp>
                )}
              </Container>
            )}
          </Container>
        )
      })}
    </Container>
  )
}

export default FilterItem
