import React from 'react'
import { Outlet } from 'react-router-dom'

export default function PrivatePage() {
  return (
    <>
      User Pages and routing
      <Outlet />
    </>
  )
}
