import React from 'react'
import { Navigate } from 'react-router-dom'

import { ROUTES_CONST } from './consts'
import { TRole, TUser } from 'types/types'
import { getSession } from 'services/storage.service'
import { AccessDeniedPage } from 'pages/Public/AccessDeniedPage'
import { USER_SESSION_NAME } from 'constants/global'

interface TProps {
  component: React.ComponentType
  path?: string
  roles: TRole[]
}
export const PrivateRoute: React.FC<TProps> = ({ component: RouteComponent, roles }) => {
  const user = (getSession(USER_SESSION_NAME) as TUser) || undefined

  if (!user) return <Navigate to={`/${ROUTES_CONST.LOGIN.link}`} />
  const { roles: userRoles } = user
  const userHasRole = userRoles.some((item) => roles.includes(item))

  if (userHasRole) {
    return <RouteComponent />
  }
  return <AccessDeniedPage />
}
