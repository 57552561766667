import config from 'configuration'
export function getWAFEnv () {
  return {
    'JSAPI_URL': config.awsCaptchaUrl,
    'CAPTCHA_API_KEY': config.awsCaptchaApiKey
  }
}

export function loadScript () {
  if (document.getElementById('AwsWAFScript')) return

  const AwsWafScript = document.createElement('script')
  AwsWafScript.id = 'AwsWAFScript'
  AwsWafScript.async = false
  AwsWafScript.src = getWAFEnv().JSAPI_URL
  document.head.appendChild(AwsWafScript)
}

export function showCaptcha (reFetchAll) {
  document.body.style.cursor = 'wait'
  document.getElementById('modalOverlay').style.display = 'block'
  document.getElementById('modal').style.display = 'block'
  return new Promise(() => {
    window.AwsWafCaptcha.renderCaptcha(document.getElementById('captchaForm'), {
      onSuccess: () => {
        document.getElementById('modalOverlay').style.display = 'none'
        document.getElementById('modal').style.display = 'none'
        reFetchAll ? reFetchAll() : window.location.reload()
      },
      onLoad: () => {
        document.body.style.cursor = 'default'
      },
      apiKey: getWAFEnv().CAPTCHA_API_KEY
    })
  })
}