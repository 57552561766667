import React from 'react'
import { Tooltip, TooltipProps } from '@mui/joy'
import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from '../../utils/mui'

type TProps = {
  title?: React.ReactNode
  children: React.ReactElement
  variant?: COMPONENT_VARIANT
  size?: COMPONENT_SIZE
  color?: COMPONENT_COLOR
}
export default function TooltipComp({ title, children, variant, size, color, placement, open }: TProps & TooltipProps) {
  return (
    <Tooltip
      open={open}
      title={title}
      variant={variant}
      size={size}
      color={color}
      placement={placement}
    >
      {children}
    </Tooltip>
  )
}