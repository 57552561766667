import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { selectDocumentListLoadingSelector, selectTotalDocsSelector } from 'state/liteDocuments/selectors'
import { selectIsOpenGraphSelector } from 'state/graph/selectors';
import { setIsOpen } from 'state/liteDocuments/actions'
import { selectSearchSelector } from 'state/mainFilter/selectors'

import { formatNumber } from 'utils/global'
import SkeletonComp from 'components/SkeletonComp'
import Container from 'components/Container'
import TextComp, { H_LEVEL } from 'components/TextComp'
import CloseButtonComp from 'components/CloseButtonComp/CloseButtonCopm'
import Sorting from './components/Sorting'

import './style.scss'


const DocumentsHeaderLite: React.FC = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const docsAmount = useSelector(selectTotalDocsSelector)
  const isLoading = useSelector(selectDocumentListLoadingSelector)
  const isOpenGraph = useSelector(selectIsOpenGraphSelector)
  const search = useSelector(selectSearchSelector)

  const handleCloseDocuments = () => {
    dispatch(setIsOpen(false))
  }

  const totalDocs = docsAmount ? formatNumber(docsAmount) : ''
  const evidenceTitle = search ? 'main.graph.evidence' : 'main.graph.recent_evidence'

  return (
    <Container direction={'row'} justifyContent={'space-between'} alignItems={'center'} className={'documents-header-lite'}>
      <TextComp level={H_LEVEL.H4}>
        <Trans i18nKey={evidenceTitle} />
      </TextComp>
      <Container direction={'row'} alignItems={'center'} spacing={1}>
        {search && <Sorting />}
        {isLoading ? (
          <SkeletonComp width={70} />
        ) : (
          <Container direction={'row'} justifyContent={'space-between'} alignItems={'center'} className={'documents-header-lite'}>
            <TextComp seleniumId={'documents_total'} level={H_LEVEL.H4}>
              {totalDocs}
            </TextComp>
          </Container>
        )}
        {isOpenGraph && <CloseButtonComp seleniumId={'documents_close'} hint={t('main.document.close')} onClick={handleCloseDocuments} />}
      </Container>
    </Container>
  )
}

export default React.memo(DocumentsHeaderLite)
