import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonGroup } from '@mui/joy'
import Container from 'components/Container'
import TextComp from 'components/TextComp'
import { COMPONENT_COLOR, COMPONENT_VARIANT } from 'utils/mui'
import CloseButtonComp from 'components/CloseButtonComp/CloseButtonCopm'

import './style.scss'

type TProps = {
  label: string
  isPdf?: boolean
  setIsPdf?: (value: boolean) => void
  onClose: () => void
  showPdfButton?: boolean
  seleniumId?: string
}
export function DetailsHeaderComponent({ label, showPdfButton, isPdf, setIsPdf = () => {}, onClose, seleniumId = 'header' }: TProps) {
  const { t } = useTranslation()
  return (
    <Container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className='modal_header'
    >
      <TextComp seleniumId={`${seleniumId}_title`} className={'modal_header__label'}>{label}</TextComp>
      {showPdfButton ? (
        <Container spacing={1} direction='row' alignItems='center'>
          <ButtonGroup color={COMPONENT_COLOR.PRIMARY} aria-label="outlined button group">
            <Button
              selenium-id={`${seleniumId}_abstract-mode`}
              size={'xs'}
              variant={isPdf ? COMPONENT_VARIANT.DEFAULT : COMPONENT_VARIANT.PRIMARY}
              onClick={() => setIsPdf(false)}
            >
              {t('main.document.abstract')}
            </Button>
            <Button
              selenium-id={`${seleniumId}_pdf-mode`}
              size={'xs'}
              variant={isPdf ? COMPONENT_VARIANT.PRIMARY : COMPONENT_VARIANT.DEFAULT}
              onClick={() => setIsPdf(true)}
            >
              {t('main.document.full_text')}
            </Button>
          </ButtonGroup>
          <CloseButtonComp seleniumId={`${seleniumId}_close`} hint={t('global.close')} onClick={onClose}/>
        </Container>
      ) : (
        <CloseButtonComp seleniumId={`${seleniumId}_close`} hint={t('global.close')} onClick={onClose}/>
      )}
    </Container>
  )
}
