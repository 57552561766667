export const DTIC_LOGO_URL = 'https://discover.dtic.mil/'
export const USER_SESSION_NAME = 'userInfo'

export const DEBOUNCE_DELAY = 1000

export const DATE_TIME_LOCALE_FORMAT = 'MMM D, YYYY, hh:mm a Z'
export const DATE_LOCALE_FORMAT = 'MMM DD, YYYY'
export const DATE_YEAR_FORMAT = 'YYYY'

export const DOCUMENTS_LIMIT = 20
export const DOCUMENTS_PREVIEW_LIMIT = 600

export const GRAPH_NODE_LIMIT = 250
export const GRAPH_FILTER_NODES_LIMIT = 200

export const FILTER_ITEMS_LIMIT = 50
export const FILTER_SHOW_SEARCH_LIMIT = 9

export const DOCUMENTS_TAGS_LIMIT = 10
export const DOCUMENTS_TAGS_MAX_WIDTH = 320

export const FILTER_VALUE_DIVIDER = '%3A' // it's ":"
export const ALL_TIME = 'All time'

export const QUERY_PARAMS = {
  QUERY: 'query',
  START: 'start',
  END: 'end',
  DATE_RANGE_MODE: 'dateRangeMode',
  FILTER: 'filter',
  MLT: 'mlt',
  DOCUMENTS_OFFSET: 'documentsOffset',
  PDF: 'pdf',
  SNIPPET_ID: 'snippetId',
  NODES: 'nodes',
  EDGES: 'edges',
  ROLES: 'roles',
  CLICKED_NODE_OR_EDGE: 'clickedNodeOrEdge',
  NODES_LIMIT: 'nodesLimit',
  SORT: 'sort',
  GROUP_BY: 'groupBy',
}

export const TRIAL_LIMIT_DOC = 3
export const TRIAL_STORAGE_NAME = 'trial_limit'
export const TRIAL_EMAIL_STORAGE_NAME = 'trial_email'
export const ENTITY_PICTURE_QUERY = '%3Fwidth=300px'

export const IMAGE_PROXY_PARAMS_ORG = 'rs:fit:200:200:1:1/background:ffffff/pd:20'
export const IMAGE_PROXY_PARAMS_GPE = 'rs:fill:200:200:1:1/gravity:ce'
export const IMAGE_PROXY_PARAMS = 'rs:fill:200:200:1:1/gravity:no'

export const PDF_PRE_LINK_TEXT = 'For online access visit:'

export const FACET_LIMIT_ARRAY = [
  'ORG.entity.minsky_ids:250',
  'PERSON.entity.minsky_ids:250',
  'dmetrics_mpa.tag.ids:50',
  'dmetrics_fos.tag.ids:50',
  'dmetrics_topics.tag.ids:50',
]
