import { factoryAction } from '../utils'

const ACTION = factoryAction('userSettings')

// ACTION TYPES
const setUser = ACTION('setUser')
const setIsLoading = ACTION('setIsLoading')

const TYPES = {
  setUser,
  setIsLoading,
}

export default TYPES
