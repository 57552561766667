import React from 'react'

import SkeletonComp from 'components/SkeletonComp';
import { SKELETON_VARIANT } from 'components/SkeletonComp/types';

import './style.scss'

const SkeletonItem = () => {
  return (
    <div className="documents-skeleton-loading-item documents-lite-item">
      <SkeletonComp width={160} level="h2" sx={{ mb: 1 }}/>
      <SkeletonComp  level="h1" sx={{ mb: 1 }}/>
      <div className="info-line">
        <div><SkeletonComp width={60} level="body-lg" /></div>
        <div><SkeletonComp width={60} level="body-lg" /></div>
      </div>
      <SkeletonComp width={100} level="h2" sx={{ mb: 1 }}/>
      <SkeletonComp height={105} variant={SKELETON_VARIANT.RECT} level="body-lg" sx={{ mb: 1 }}/>
      <SkeletonComp width={250} level="h2" sx={{ mb: 1 }}/>
      <SkeletonComp width="40%" level="body-lg" />
    </div>
  );
}

export default React.memo(SkeletonItem)
