import { TMainFilter, TMainFilterItem, TMainFilterState } from './types'
import dayjs from 'dayjs'
import { DATE_MODES } from 'pages/Public/MainPage/Search/components/DateRange'

const initState: TMainFilterState = {
  search: '',
  dateRangeMode: DATE_MODES.ALL,
  dateRange: [] as dayjs.ConfigType[],
  list: [] as TMainFilter[],
  filterSearch: {},
  isLoading: true,
  isOpen: false,
  selectedItems: [] as TMainFilterItem[],
  mlt: null,
  showMltModal: false,
  metaData: {},
  appliedFiltersCollapse: true,
}

export default initState
