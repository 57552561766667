import React, { useEffect, useRef, useState } from 'react'
import { Tooltip, TypographyProps } from '@mui/joy'
import { COMPONENT_VARIANT } from '../../utils/mui'

import './index.scss'

type TProps = {
  children: any
  maxWidth?: number
}
const TooltipText: React.FC<TProps & TypographyProps> = ({
  children,
  maxWidth = 200,
}) => {
  const textElementRef = useRef(null);

  const compareSize = () => {
    // @ts-ignore
    const compare = textElementRef?.current?.scrollWidth > textElementRef?.current?.clientWidth;
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener('resize', compareSize);

    return () => window.removeEventListener('resize', compareSize);
  }, [])

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);
  return (
    <Tooltip
      title={children}
      disableHoverListener={!hoverStatus}
      placement={'top-start'}
      variant={COMPONENT_VARIANT.DEFAULT}
    >
      <div
        ref={textElementRef}
        className={`overflow-ellipsis ${hoverStatus ? 'cursor-pointer' : ''}`}
        style={{ maxWidth }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default TooltipText;