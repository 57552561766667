export const encoded = (objects: object[]): string => {
  if (!objects.length) return ''
  const json = JSON.stringify(objects)
  return btoa(encodeURIComponent(json))
}

export const decoded = (encodedString: string) => {
  const json = decodeURIComponent(atob(encodedString))
  return JSON.parse(json)
}

export const formatNumber = (num: number = 0, digits: number = 0) => {
  return num?.toLocaleString(undefined, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
}

export const generateRandom = (min: number, max: number, step: number) => {
  const randomNum = min + Math.random() * (max - min);
  return Math.round(randomNum / step) * step;
}

export const groupBy: object = (list: object[], fieldName: string, nestedField?: string) => {
  return list.reduce((acc: object, item: object) => {
    if (nestedField) {
      // @ts-ignore
      (acc[item[fieldName][nestedField]] = acc[item[fieldName][nestedField]] || []).push(item);
    } else {
      // @ts-ignore
      (acc[item[fieldName]] = acc[item[fieldName]] || []).push(item);
    }


    return acc
  }, {})
}

export const onlyUnique = (value: any, index: number, array: any[]) => {
  return array.indexOf(value) === index;
}

export const uniqByKey = (array: any[], key: string) => {
  return [...new Map(array.map(item => [item[key], item])).values()]
}

export const getQueryString = (params: object) =>
  //@ts-ignore
  Object.entries(Object.assign({}, params)).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');


export const writeClipboardText = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (e: any) {
    console.error(e.message);
  }
}