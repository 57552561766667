import React from 'react'
import { COMPONENT_SIZE, COMPONENT_VARIANT } from 'utils/mui'
import { CloseButtonCompProps } from './type'
import TooltipComp from '../TooltipComp'
import { ReactComponent as CloseIco } from 'images/icons/doc_close.svg'
import { IconButton } from '@mui/joy'
import './style.scss'

export default function CloseButtonComp({
  hint,
  onClick,
  size = COMPONENT_SIZE.SMALL,
  seleniumId
}: CloseButtonCompProps) {

  return (
    <IconButton
      selenium-id={seleniumId}
      className="close"
      type={COMPONENT_VARIANT.LINK}
      size={size}
      onClick={onClick}

    >
      <TooltipComp title={hint} placement='top'>
        <CloseIco />
      </TooltipComp>
    </IconButton>

  )
}
