export const setStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getStorage = (key: string) => {
  const value = localStorage.getItem(key)
  return value ? JSON.parse(value) : undefined
}

export const setSession = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value))
}

export const getSession = (key: string) => {
  const value = sessionStorage.getItem(key)
  return value ? JSON.parse(value) : undefined
}
