import { factoryAction } from '../utils'

const ACTION = factoryAction('liteDocuments')

// ACTION TYPES
const setIsLoading = ACTION('setIsLoading')
const setList = ACTION('setList')
const setIsOpen = ACTION('setIsOpen')
const setLang = ACTION('setLang')
const setViewType = ACTION('setViewType')
const setTotal = ACTION('setTotal')
const setDocument = ACTION('setDocument')
const setIsDocLoading = ACTION('setIsDocLoading')
const setIsHistogramLoading = ACTION('setIsHistogramLoading')
const setHistogramData = ACTION('setHistogramData')
const setFirstLoading = ACTION('setFirstLoading')
const setMltList = ACTION('setMltList')
const setFetchedDocumentsCount = ACTION('setFetchedDocumentsCount')
const setPdfMode = ACTION('setPdfMode')
const setPositiveFeedback = ACTION('setPositiveFeedback')
const setNegativeFeedback = ACTION('setNegativeFeedback')
const setSnippetDocumentId = ACTION('setSnippetDocumentId')
const setDocumentSorting = ACTION('setDocumentSorting')
const setOpenedDocumentId = ACTION('setOpenedDocumentId')

const TYPES = {
  setIsLoading,
  setList,
  setIsOpen,
  setLang,
  setViewType,
  setTotal,
  setDocument,
  setIsDocLoading,
  setIsHistogramLoading,
  setHistogramData,
  setFirstLoading,
  setMltList,
  setFetchedDocumentsCount,
  setPdfMode,
  setPositiveFeedback,
  setNegativeFeedback,
  setSnippetDocumentId,
  setDocumentSorting,
  setOpenedDocumentId
}

export default TYPES
