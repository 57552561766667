import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Container from 'components/Container';
import TextComp, { BODY_LEVEL } from 'components/TextComp';
import { COMPONENT_COLOR } from 'utils/mui';
import ActionButtonsGroupComp from 'pages/Public/MainPage/Documents/components/ActionButtonsGroupComp';
import { TDocumentLite } from 'state/liteDocuments/types'
import { writeClipboardText } from 'utils/global'
import { QUERY_PARAMS } from 'constants/global'
import { documentShared } from 'services/tracking.service'
import { isR2ProjectAccomplishment } from 'utils/document'

type TAboveHeaderDocumentProps = {
  document: TDocumentLite,
}

const AboveHeaderDocument = ({
  document,
}: TAboveHeaderDocumentProps) => {
  const { document_indicator } = useParams()
  const [copied, setCopied] = useState<boolean>(false)

  const handleCopy = () => {
    setCopied(true)
    const params = document.document_id && isR2ProjectAccomplishment(document.type)
      ? `?${QUERY_PARAMS.SNIPPET_ID}=${document.document_id}` : ''
    const link = `${window.location.origin}/shared/${document_indicator}${params}`
    writeClipboardText(link)
    documentShared(document.document_id, link)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <Container justifyContent={'space-between'} direction={'row'}>
      <Container className={'mr-6'} spacing={1} direction={'row'} alignItems={'center'} sx={{ height: 32 }}>
        <TextComp
          seleniumId={'document_details_source-id'}
          color={COMPONENT_COLOR.NEUTRAL}
          level={BODY_LEVEL.MEDIUM}
        >
          {document.details.source_id}
        </TextComp>
      </Container>
      <ActionButtonsGroupComp
        documentId={document.document_id}
        handleCopy={handleCopy}
        copied={copied}
      />
    </Container>
  )
}

export default AboveHeaderDocument

