import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { QUERY_PARAMS } from 'constants/global'
import { writeClipboardText } from 'utils/global'
import notificator from 'services/notifications.service'
import { documentShared } from 'services/tracking.service'
import { isR2ProjectAccomplishment, isR2ProjectSubTypes } from 'utils/document'
import { textHighlighting } from 'utils/highlightText'
import { TDocumentLite } from 'state/liteDocuments/types'
import { TSetMltProps } from 'state/mainFilter/types'
import { generateLink, setSnippetDocumentId } from 'state/liteDocuments/actions'
import { DOCUMENT_DESCRIPTION, DOCUMENT_HIGHLIGHTS_FIELDS } from 'constants/document'
import TextComp, { BODY_LEVEL, H_LEVEL } from 'components/TextComp'
import Container from 'components/Container'
import ActionButtonsGroupComp from 'pages/Public/MainPage/Documents/components/ActionButtonsGroupComp'
import DocumentContent from '../../components/Content'
import TagsTabsComponent from '../../components/TagsTabs'
import DocumentInfo from '../../components/Info'

import './style.scss'


type TProps = {
  document: TDocumentLite,
  onClick: (id: string) => void,
  limit: number,
  mlt?: TSetMltProps | null,
}

const DocumentItemLite: React.FC<TProps> = ({ document, onClick, limit , mlt }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [copied, setCopied] = useState<boolean>(false)
  const [copiedLoading, setCopiedLoading] = useState<boolean>(false)

  const { root_document, type, title, creation_date } = document
  const tags = [...document.entities, ...document.tags]

  const descriptionLabel = DOCUMENT_DESCRIPTION[type]
  const contentLabel = descriptionLabel ? t(descriptionLabel) : undefined
  const documentId = isR2ProjectAccomplishment(document.type) ? document.root_document!.document_id : document.document_id
  const docSelector = document.source_id || document.document_id
  const seleniumIdPrefix = `document-list_item_${document.source_id}`

  const handleOpenDocument = () => {
    onClick(documentId)

    if (isR2ProjectAccomplishment(document.type)) {
      dispatch(setSnippetDocumentId(document.document_id))
    }
  }

  const handleCopy = async () => {
    if (copiedLoading || copied) return;
    setCopiedLoading(true)
    const data = await dispatch(generateLink({ documentId }))
    if (!data?.three_words?.length) return notificator.error()
    setCopiedLoading(false)
    setCopied(true)

    const params = document.document_id && isR2ProjectAccomplishment(document.type)
      ? `?${QUERY_PARAMS.SNIPPET_ID}=${document.document_id}` : ''
    const link = `${window.location.origin}/shared/${data.three_words}${params}`
    writeClipboardText(link)
    documentShared(document.document_id, link)
    setTimeout(() => setCopied(false), 2000)
  }

  const highlightedTitle = <TextComp seleniumId={`document-list_item_title_${docSelector}`} level={H_LEVEL.H2} sx={{ wordBreak: 'break-word' }}>
    {textHighlighting({
      raw: document.title,
      highlights: document.meta?.highlights,
      field: DOCUMENT_HIGHLIGHTS_FIELDS.TITLE,
      level: H_LEVEL.H2,
      seleniumId: `document-list_item_title_${docSelector}`
    })}
  </TextComp>

  const highlightedContent = <TextComp seleniumId={`document-list_item_content_${docSelector}`} level={BODY_LEVEL.LARGE}>
    {textHighlighting({
      raw: document.text,
      highlights: document.meta?.highlights,
      field: DOCUMENT_HIGHLIGHTS_FIELDS.TEXT,
      level: BODY_LEVEL.LARGE,
      limit,
      seleniumId: `document-list_item_content_${docSelector}`
    })}
  </TextComp>

  return (
    <Container
      seleniumId={`document-list_item_${docSelector}`}
      spacing={1}
      className='documents-lite-item'
      onClick={handleOpenDocument}
    >
      <Container justifyContent={'space-between'} direction={'row'} sx={{ height: '32px' }}>
        <TextComp>{document.type_label}</TextComp>
        <ActionButtonsGroupComp
          documentId={document.document_id}
          handleCopy={handleCopy}
          seleniumIdPrefix={seleniumIdPrefix}
          copied={copied}
          copiedLoading={copiedLoading}
        />
      </Container>
      <Container spacing={1}>
        {highlightedTitle}
        {root_document?.title &&
          <TextComp seleniumId={`document-list_item_program_${docSelector}`} level={BODY_LEVEL.MEDIUM}>
            {`Program: ${root_document.title}`}
          </TextComp>
        }
        {isR2ProjectSubTypes(document.type) && root_document?.children &&
          <TextComp seleniumId={`document-list_item_project_${docSelector}`} level={BODY_LEVEL.MEDIUM}>
            {`Project: ${root_document.children[0].title}`}
          </TextComp>
        }
      </Container>
      <DocumentInfo
        documentId={document.document_id}
        details={document.details}
        creationDate={creation_date}
        type={type}
        title={title}
        showMltButton={!mlt && document.details.mlt}
      />

      {document.text && (
        <DocumentContent title={contentLabel} text={highlightedContent}/>
      )}

      <TagsTabsComponent
        seleniumId={`document-list_item_${docSelector}_tags`}
        documentType={document.type}
        list={tags}
      />
    </Container>
  )
}

export default React.memo(DocumentItemLite)
