import { createReducer } from '../utils'
import ActionTypes from './action-types'
import initState from './initState'
import { TMainFilter, TMainFilterItem, TMainFilterState, TSetMltProps } from './types'
import dayjs from 'dayjs'

const mapReducerRaw = createReducer<TMainFilterState, string, any>(initState, {
  [ActionTypes.setSearch](state, { payload }: { payload: string }) {
    return {
      ...state,
      search: payload,
    }
  },
  [ActionTypes.setIsLoading](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      isLoading: payload,
    }
  },
  [ActionTypes.setList](state, { payload }: { payload: TMainFilter[] }) {
    return {
      ...state,
      list: payload,
    }
  },
  [ActionTypes.setIsOpen](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      isOpen: payload,
    }
  },
  [ActionTypes.setSelectedItems](state, { payload }: { payload: TMainFilterItem[] }) {
    return {
      ...state,
      selectedItems: payload,
    }
  },
  [ActionTypes.setFilterSearch](state, { payload }: { payload: object }) {
    return {
      ...state,
      filterSearch: payload,
    }
  },
  [ActionTypes.setDateRangeMode](state, { payload }: { payload: string }) {
    return {
      ...state,
      dateRangeMode: payload,
    }
  },
  [ActionTypes.setDateRange](state, { payload }: { payload: dayjs.ConfigType[] }) {
    return {
      ...state,
      dateRange: payload,
    }
  },
  [ActionTypes.setMlt](state, { payload }: { payload: TSetMltProps | null }) {
    return {
      ...state,
      mlt: payload,
    }
  },
  [ActionTypes.showMltModal](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      showMltModal: payload,
    }
  },
  [ActionTypes.setMetadata](state, { payload }: { payload: object }) {
    return {
      ...state,
      metaData: payload,
    }
  },
  [ActionTypes.setAppliedFiltersCollapse](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      appliedFiltersCollapse: payload,
    }
  },
})

export default mapReducerRaw
