import React from 'react'
import { Chip } from '@mui/joy';
import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from '../../utils/mui'

type TProps = {
  children: React.ReactNode
  className?: string
  color?: COMPONENT_COLOR
  variant?: COMPONENT_VARIANT
  size?: COMPONENT_SIZE
  onClick?: (e: any) => void
  endDecorator?: React.ReactNode
  seleniumId?: string
  disabled?: boolean
  sx?: object
}
export default function TagComp(
  {
    seleniumId,
    children,
    className,
    color = COMPONENT_COLOR.NEUTRAL,
    variant = COMPONENT_VARIANT.DEFAULT,
    size = COMPONENT_SIZE.LARGE,
    endDecorator,
    onClick,
    disabled,
    sx,
  }: TProps
) {
  return (
    <Chip
      sx={sx}
      selenium-id={seleniumId}
      className={className}
      color={color}
      onClick={onClick}
      variant={variant}
      size={size}
      disabled={disabled}
      endDecorator={endDecorator}
    >
      {children}
    </Chip>
  )
}
