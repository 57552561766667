// @ts-nocheck
import { TMainFilter, TMainFilterItem, TMainFilterType, TSetMltProps } from '../state/mainFilter/types'
import dayjs from 'dayjs'
import { FILTER_VALUE_DIVIDER } from 'constants/global'
import { DATE_MODES } from 'pages/Public/MainPage/Search/components/DateRange'
import { DOCUMENT_TYPES } from 'constants/document'
import { TClickedNodeOrEdge, TClickedNodeOrEdgeState } from 'state/graph/types';

export const QUERY_PREFIX = {
  FILTER: 'filter',
  FACET_QUERY: 'facet_query',
  FACET_LIMIT: 'facet_limit',
  SORT: 'sort'
}

export enum GRAPH_FILTER {
  NODES = 'node_groups',
  EDGES = 'relation_types',
  ROLES = 'node_roles'
}

export const R2_PROGRAM = {
  count: 0,
  details: {},
  group: null,
  label: 'R2 Budgetary Justification',
  label_plural: 'R2 Budgetary Justifications',
  name: 'type',
  type: 'document_type',
  value: DOCUMENT_TYPES.R2_PROGRAM_ELEMENT,
  children: [{
    count: 0,
    details: {},
    group: null,
    label: 'Project',
    label_plural: 'Projects',
    name: 'type',
    type: 'document_type',
    value: DOCUMENT_TYPES.R2_PROJECT,
    children: [{
      count: 0,
      details: {},
      group: null,
      label: 'Accomplishment',
      label_plural: 'Accomplishments',
      name: 'type',
      type: 'document_type',
      value: DOCUMENT_TYPES.R2_ACCOMPLISHMENT_PLANNED_PROGRAM
    }]
  }]
} as TMainFilterItem

export const deserializeMainFilter = (data: object):TMainFilter[] => {

  const filter = [] as TMainFilter[]
  Object.keys(data).sort().forEach(name => {

    if (name === 'metadata') return

    if (Array.isArray(data[name].data)) {
      const item = {
        label: '',
        types: [{
          label: data[name].label || name,
          name: data[name].name,
          total: data[name].total,
          items: data[name].data as TMainFilterItem[]
        }]
      } as TMainFilter

      filter.push(item)
    } else {
      const types = data[name]
      const newTypes = [] as TMainFilterType[]
      Object.keys(types).sort().forEach(typeName => {
        const total = types[typeName].total
        const pluralName = types[typeName].label_plural || types[typeName].label || typeName
        const name = types[typeName].label || typeName

        const newType = {
          label: total > 1 ? pluralName : name,
          name: types[typeName].name,
          items: types[typeName].data as TMainFilterItem[],
          total
        }
        newTypes.push(newType)
      })

      const item = {
        label: name,
        types: newTypes
      } as TMainFilter

      filter.push(item)
    }
  })

  return filter
}

export const serializeFilterByName = (filter: TMainFilterItem[]) => {
  const grouped = filter.reduce((acc: object, item: TMainFilterItem) => {
    if (item.name in acc) {
      acc[item.name] = [...acc[item.name], item.value]
    } else {
      acc[item.name] = [item.value]
    }

    return acc
  }, {})

  return grouped
}

export const serializeClickedNodeEdgeToQueryOld = (filter: TClickedNodeOrEdge[]): string => {//TODO remove after MINSKY-9086 will be done
  let query = ''
  const prefix = `&${QUERY_PREFIX.FILTER}=`
  filter.forEach((node: TClickedNodeOrEdge) => {
    query += `${prefix}${node.name}${FILTER_VALUE_DIVIDER}${node.value}`
  })

  return query
}

export const serializeClickedNodeEdgeToQuery = (clickedNodeOrEdge: TClickedNodeOrEdgeState | null | undefined): string => {
  if (!clickedNodeOrEdge) return '';
  const prefix = `&${QUERY_PREFIX.FILTER}=`
  const relation_types = clickedNodeOrEdge.target?.relation_types;
  const graph_nodes = clickedNodeOrEdge.target?.graph_nodes?.join();

  let query = `&graph_nodes=${graph_nodes}`

  if (relation_types) {
    query += `${prefix}relation_types:${relation_types}`;
  }

  return query
}

export const serializeFilterToQuery = (filter: TMainFilterItem[] | TClickedNodeOrEdge[]): string => {
  const grouped = serializeFilterByName(filter)
  let query = ''
  const prefix = `&${QUERY_PREFIX.FILTER}=`
  Object.keys(grouped).forEach((name: string) => {
    query += `${prefix}${name}${FILTER_VALUE_DIVIDER}${grouped[name].join(',')}`
  })

  return query
}

export const serializeFilterSearchToQuery = (terms: object): string => {
  let query = ''
  const prefix = `&${QUERY_PREFIX.FACET_QUERY}=`
  Object.keys(terms).forEach(item => {
    query += terms[item].search ? `${prefix}${item}${FILTER_VALUE_DIVIDER}${terms[item].search.trim()}` : ''
  })

  return query
}

export const serializeFilterSearchLimitToQuery = (terms: object): string => {
  let query = ''
  const prefix = `&${QUERY_PREFIX.FACET_LIMIT}=`
  Object.keys(terms).forEach(item => {
    query += terms[item].limit ? `${prefix}${item}${FILTER_VALUE_DIVIDER}${terms[item].limit}` : ''
  })

  return query
}

export const serializeFilterMltToQuery = (mlt: TSetMltProps | null): string => {
  const prefix = `&${QUERY_PREFIX.FILTER}=`
  return mlt?.documentId ? `${prefix}mlt${FILTER_VALUE_DIVIDER}${mlt.documentId}` : ''
}

export const serializeDateRangeQuery = (range: dayjs.ConfigType[]): string => {
  if (!range.length) return ''
  let query = ''
  const [start, end] = range
  const prefix = `&${QUERY_PREFIX.FILTER}=`
  query += `${prefix}creation_date.gt${FILTER_VALUE_DIVIDER}${dayjs(start).startOf('day').unix()}`
  query += `${prefix}creation_date.lt${FILTER_VALUE_DIVIDER}${dayjs(end).endOf('day').unix()}`

  return query
}

export const serializeGraphFilterToQuery = (list: string[], type: GRAPH_FILTER): string => {
  if (!list.length) return ''
  const query = `&${QUERY_PREFIX.FILTER}=${type}${FILTER_VALUE_DIVIDER}`
  return query + list.join(',')
}

export const serializeSortToQuery = (sort: string): string => {
  const prefix = `&${QUERY_PREFIX.SORT}=`
  return sort ? `${prefix}${sort},desc` : ''
}

export const getDateRangePeriod = (mode: DATE_MODES) => {
  if (mode === DATE_MODES.ALL) return []

  const yearsNumber = mode.split('_')[0]
  const end = dayjs().endOf('day')
  const start = end.subtract(yearsNumber, 'years').startOf('day')
  return [start, end]
}

export const getUpdatedSelectedItems = (selectedItems: TMainFilterItem[], data: TMainFilter[]) => {
  return selectedItems.map((item: TMainFilterItem) => {
    const updated = { ...item }
    const value = data.slice(0).reduce((prev, filter, i, arr) => {
      const type = filter.types.find((it: TMainFilterType) => it.name === item.name)
      const findItem = prev || type?.items.find((it: TMainFilterItem) => it.value === item.value)
      if (typeof findItem !== 'undefined') arr.splice(1); // ejects early
      return findItem;
    }, undefined);
    updated.count = value ? value.count : 0

    return updated
  })
}
