import React from 'react'
import { COMPONENT_COLOR, COMPONENT_SIZE } from 'utils/mui';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ButtonComp from 'components/ButtonComp/ButtonCopm';

import './style.scss'

type TFullHeaderButtonProps = {
  onClick(): void,
  fullHeader: boolean
}

const FullHeaderButton = ({
  onClick,
  fullHeader,
}: TFullHeaderButtonProps) => (
  <ButtonComp
    className={'full_header_button'}
    size={COMPONENT_SIZE.SMALL}
    onClick={onClick}
    color={COMPONENT_COLOR.NEUTRAL}
    selenium-id={'full_header_button'}
  >
    {fullHeader
      ? <KeyboardArrowUpIcon fontSize={'large'}/>
      : <SearchIcon />
    }
  </ButtonComp>
)

export default FullHeaderButton;
