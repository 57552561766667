import React from 'react'

import DocumentsListLite from './List'
import DocumentsHeaderLite from './Header'
import AppliedFilterTags from './components/AppliedFiltersTags'
import Container from 'components/Container'
import { Divider } from '@mui/joy';

import './style.scss'

const DocumentsLite: React.FC = () => {
  return (
    <Container>
      <Container className={'content__title padding-8'} justifyContent={'center'}>
        <DocumentsHeaderLite />
      </Container>
      <Divider />
      <Container className='content__documents-content'>
        <Container className='documents-lite' id="scrollableDiv">
          <AppliedFilterTags />
          <DocumentsListLite />
        </Container>
      </Container>
    </Container>
  )
}

export default React.memo(DocumentsLite)
