export enum GRAPH_META_FILTER_TYPES {
  EDGES = 'relation',
  ROLES = 'node_role',
}

export enum GRAPH_GROUP_BY {
  GROUP = 'group',
  ROLE = 'role',
}


export const ANIMATION_DURATION = 250;