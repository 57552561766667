import { TRootState } from '../types'

export const selectGEntityTypesSelector = (state: TRootState) => state.liteDocuments

// List
export const selectListSelector = (state: TRootState) => selectGEntityTypesSelector(state).list
export const selectDocumentListLoadingSelector = (state: TRootState) => selectGEntityTypesSelector(state).isLoading
export const selectTotalDocsSelector = (state: TRootState) => selectGEntityTypesSelector(state).total
export const selectFetchedDocsCountSelector = (state: TRootState) => selectGEntityTypesSelector(state).fetchedDocumentCount
export const selectIsOpenDocsSelector = (state: TRootState) => selectGEntityTypesSelector(state).isOpen
export const selectFirstLoadingSelector = (state: TRootState) => selectGEntityTypesSelector(state).firstLoading
export const selectSortingSelector = (state: TRootState) => selectGEntityTypesSelector(state).sorting

// Full view
export const selectFullDocumentSelector = (state: TRootState) => selectGEntityTypesSelector(state).fullDocument
export const selectIsDocLoadingSelector = (state: TRootState) => selectGEntityTypesSelector(state).isDocLoading
export const selectIsPdfModeSelector = (state: TRootState) => selectGEntityTypesSelector(state).isPdfMode
export const selectSnippetDocumentIdSelector = (state: TRootState) => selectGEntityTypesSelector(state).snippetDocumentId

// Histogram
export const selectIsHistogramLoadingSelector = (state: TRootState) => selectGEntityTypesSelector(state).histogramLoading
export const selectHistogramSelector = (state: TRootState) => selectGEntityTypesSelector(state).histogram

// Actions group
export const selectMltListSelector = (state: TRootState) => selectGEntityTypesSelector(state).mltList
export const selectPositiveFeedbackSelector = (state: TRootState) => selectGEntityTypesSelector(state).positiveFeedback
export const selectNegativeFeedbackSelector = (state: TRootState) => selectGEntityTypesSelector(state).negativeFeedback
export const selectOpenedDocumentIdSelector = (state: TRootState) => selectGEntityTypesSelector(state).openedDocumentId
