import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider } from '@mui/joy'

import {
  selectIsFilterLoadingSelector,
  selectIsOpenSelector,
  selectListSelector,
  selectSelectedFilterItemsSelector
} from 'state/mainFilter/selectors'
import { setIsOpen, setSelectedItems } from 'state/mainFilter/actions'
import { TMainFilter, TMainFilterItem } from 'state/mainFilter/types'
import { setClickedNodeOrEdge } from 'state/graph/actions';
import FilterHeader from './components/Header'
import SkeletonLoading from './components/SkeletonLoading';
import FilterItem from './components/FilterItem';
import FilterBlock from '../ContentLite/components/Block'

import './style.scss'

const MainFilter: React.FC = () => {
  const dispatch = useDispatch();
  const isFilterOpen = useSelector(selectIsOpenSelector)
  const filterItems = useSelector(selectListSelector)
  const isLoading = useSelector(selectIsFilterLoadingSelector)
  const selectedItems = useSelector(selectSelectedFilterItemsSelector)
  const filtersCount = selectedItems.length;

  const handleCloseFilter = () => {
    dispatch(setIsOpen(false))
  }

  const handleSelectedItems = (items: TMainFilterItem[]) => {
    dispatch(setSelectedItems(items))
    dispatch(setClickedNodeOrEdge(null))
  }

  return (
    <div className="filter_container">
      {isFilterOpen
        ? <FilterHeader onClose={handleCloseFilter} selectedCount={filtersCount} onClear={() => handleSelectedItems([])}/>
        : <FilterBlock />}
      <Divider />
      <div className="filter_container_body">
        {isLoading && !filterItems.length
          ? <SkeletonLoading />
          : (filterItems.map((item: TMainFilter) =>
            <FilterItem
              key={item.label}
              title={item.label}
              items={item.types}
              selectedItems={selectedItems}
              setSelectedItems={handleSelectedItems}
            />)
          )
        }
      </div>
    </div>
  )
}

export default MainFilter
