import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TDocumentTag } from 'state/liteDocuments/types'
import { groupBy, uniqByKey } from 'utils/global'
import TagComp from 'components/TagComp'
import { COMPONENT_COLOR, COMPONENT_VARIANT } from 'utils/mui'
import TextComp, { H_LEVEL, TITLE_LEVEL } from 'components/TextComp'
import Container from 'components/Container'
import TagsTabsItemComponent from './TagsTabsItem'
import { DOCUMENT_TYPES } from 'constants/document';

import './styles.scss'

type TProps = {
  list: TDocumentTag[]
  seleniumId?: string,
  documentType: string
}
export default function TagsTabsComponent({ list, seleniumId, documentType }: TProps) {
  const { t } = useTranslation()
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  // @ts-ignore
  const groupedByType = groupBy(list, 'type_label_plural')
  const names = Object.keys(groupedByType)

  const handleChange = (e: any, tag: string) => {
    e.stopPropagation()
    const value = tag === selectedTag ? null : tag
    setSelectedTag(value)
  };

  const selectedTagList = selectedTag
    ? uniqByKey(groupedByType[selectedTag], 'id').sort((a: TDocumentTag, b: TDocumentTag) => b.score - a.score)
    : []

  const title = (documentType === DOCUMENT_TYPES.R2_PROJECT)
    ? t('main.document.mentioned_tags_project')
    : t('main.document.mentioned_tags');

  return (
    <Container sx={{
      '&:not(style):not(style)': {
        marginTop: '0',
      }}}>
      <Container seleniumId={seleniumId} spacing={1}>
        <TextComp level={H_LEVEL.H4} sx={{
          '&:not(style):not(style)': {
            marginTop: '8px',
          }}}
        >{title}</TextComp>
        <div>
          {names.map((tag: string) => {
            // const total = groupedByType[tag].length//TODO return when filter will be on server side @Denn
            const array = uniqByKey(groupedByType[tag], 'id')
            const total = array.length
            let label = tag
            if (total <= 1) {
              const item = list.find((it: TDocumentTag) => it.type_label_plural === tag)
              label = item ? item.type_label : label
            }

            return (
              <TagComp
                seleniumId={`${seleniumId}_item-${label}`}
                className={'mr-4 mb-4'}
                key={tag}
                variant={selectedTag === tag ? COMPONENT_VARIANT.PRIMARY : COMPONENT_VARIANT.DEFAULT}
                color={selectedTag === tag ? COMPONENT_COLOR.PRIMARY : undefined}
                onClick={e => handleChange(e, tag)}
              >
                {total} {label}
              </TagComp>
            )
          })}
        </div>
      </Container>
      <Container spacing={1}>
        <TextComp seleniumId={`${seleniumId}_selected`} level={TITLE_LEVEL.LARGE}>{selectedTag}</TextComp>
        <TagsTabsItemComponent seleniumId={seleniumId} list={selectedTagList} />
      </Container>
    </Container>
  )
}
