import React from 'react'
import { ChipDelete } from '@mui/joy'

type TProps = {
  onDelete: () => void
  children?: React.ReactNode
  seleniumId?: string
}
export default function DeleteButton({ onDelete, children, seleniumId }: TProps) {
  return (
    <ChipDelete selenium-id={seleniumId} onDelete={onDelete}>{children}</ChipDelete>
  )
}