import { TRootState } from '../types'

export const selectGEntityTypesSelector = (state: TRootState) => state.graph

export const selectDataSelector = (state: TRootState) => selectGEntityTypesSelector(state).data
export const selectGraphDistSelector = (state: TRootState) => selectGEntityTypesSelector(state).graphDistribution
export const selectIsLoadingSelector = (state: TRootState) => selectGEntityTypesSelector(state).isLoading
export const selectIsOpenGraphSelector = (state: TRootState) => selectGEntityTypesSelector(state).isOpen
export const selectFilterSelector = (state: TRootState) => selectGEntityTypesSelector(state).filters
export const selectGraphFilterSelector = (state: TRootState) => selectGEntityTypesSelector(state).selectedGraphFilter
export const selectSelectedNodesFilterSelector = (state: TRootState) => selectGEntityTypesSelector(state).selectedNodes
export const selectSelectedEdgesFilterSelector = (state: TRootState) => selectGEntityTypesSelector(state).selectedEdges
export const selectSelectedRolesFilterSelector = (state: TRootState) => selectGEntityTypesSelector(state).selectedRoles
export const selectSelectedNodesLimitSelector = (state: TRootState) => selectGEntityTypesSelector(state).nodesLimit
export const selectClickedNodeOrEdgeSelector = (state: TRootState) => selectGEntityTypesSelector(state).clickedNodeOrEdge
export const selectGroupBySelector = (state: TRootState) => selectGEntityTypesSelector(state).groupBy
