export const DOCUMENT_TYPES = {
  LIS_TR_TRECMS: 'LIS_TR_TRECMS',
  DOD_GRANT: 'DOD_GRANT',
  R2_PROGRAM_ELEMENT: 'R2_PROGRAM_ELEMENT',
  R2_PROJECT: 'R2_PROJECT',
  R2_ACCOMPLISHMENT_PLANNED_PROGRAM: 'R2_ACCOMPLISHMENT_PLANNED_PROGRAM',
  R2_COST_ITEM: 'R2_COST_ITEM',
}

export const DOCUMENT_DESCRIPTION = {
  [DOCUMENT_TYPES.R2_PROGRAM_ELEMENT]: 'main.document.program_description',
  [DOCUMENT_TYPES.R2_PROJECT]: 'main.document.project_description',
}

export enum DOCUMENT_HIGHLIGHTS_FIELDS {
  TITLE = 'title',
  TEXT = 'text'
}

export enum DOCUMENT_SORTING {
  RECENT = 'published',
  RELEVANT = 'relevance'
}
