import React, { useEffect, useState } from 'react'
import Ogma from '@linkurious/ogma'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
// import ZoomOutMapRoundedIcon from '@mui/icons-material/ZoomOutMapRounded'
// import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded'
// import RotateRightRoundedIcon from '@mui/icons-material/RotateRightRounded'

import {
  selectDataSelector,
  selectGroupBySelector,
  selectGraphDistSelector,
  selectIsLoadingSelector,
  selectSelectedNodesFilterSelector,
  selectGraphFilterSelector,
  selectIsOpenGraphSelector,
  selectClickedNodeOrEdgeSelector,
} from 'state/graph/selectors'
import LoadingComp from 'components/LoadingComp'
import Container from 'components/Container'
import {
  TGraphData,
  TGraphNode,
} from 'state/graph/types'
import { selectIsOpenDocsSelector } from 'state/liteDocuments/selectors';
import { selectIsOpenSelector } from 'state/mainFilter/selectors';
import { filterGraph, filterGraphByLegend, resizeGraph } from 'utils/graph'

// import GroupBy from './components/GraphGrouping'
import NoResultsFound from 'components/NoResultsFound'
import GraphFilter from './components/GraphFilter'
import {
  applyFilters,
  applyGrouping, clearSelected, getPercentOfZoom,
  handleGraphClick,
  handleGraphHover,
  handleMouseOut,
  THoverState,
} from './graphEventListeners'
import {
  edgeRule,
  dynamicNodeRule,
  getColor,
  highlightedClass,
  // nodeRule,
  ogmaOptions,
  dimmedClass,
  layoutOptions, CLASS_NAMES, classClickedElement, classClickedNodeMain,
} from './graphSettings'
import { COLORS } from 'theme/settings'

import './style.scss'

const GraphLite: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [noData, setNoData] = useState<boolean>(false)
  const [localOgma, setLocalOgma] = useState<Ogma | null>(null)
  const [graphData, setGraphData] = useState<TGraphData>()

  const hoveredNodeState = {
    hoveredNode: null,
    edgesToHighlight: null,
    nodesToHighlight: null,
    edgesToDim: null,
    nodesToDim: null,
    edgesVisibility: [],

  } as THoverState

  let currentZoom = 0
  let currentWeight = 50
  let defaultZoomLevel = 0.02

  const data = useSelector(selectDataSelector)
  const isLoading = useSelector(selectIsLoadingSelector)
  const groupBy = useSelector(selectGroupBySelector)
  const weightDistribution = useSelector(selectGraphDistSelector)
  const legend = useSelector(selectSelectedNodesFilterSelector)
  const selectedGraphFilter = useSelector(selectGraphFilterSelector)
  const isOpenDocuments = useSelector(selectIsOpenDocsSelector)
  const isOpenGraph = useSelector(selectIsOpenGraphSelector)
  const isFilterOpen = useSelector(selectIsOpenSelector)
  const clickedNodeOrEdge = useSelector(selectClickedNodeOrEdgeSelector)

  useEffect(() => {
    if (isLoading || !data || !data.nodes.length) return

    let filter: any

    let filteredNodes = [...data.nodes]
    let filteredEdges = [...data.edges]

    // filter By legend
    if (legend.length > 0) {
      const { nodes, edges } = filterGraphByLegend(legend, filteredNodes, filteredEdges)
      filteredNodes = nodes
      filteredEdges = edges
    }

    if (Object.keys(selectedGraphFilter).length > 0) {
      const { nodes, edges } = filterGraph(selectedGraphFilter, filteredNodes, filteredEdges)
      filteredNodes = nodes
      filteredEdges = edges
    }

    if (groupBy) {
      // colorize by group | roles
      filteredNodes = filteredNodes.map((item: TGraphNode) => {
        const newItem = { ...item }
        // @ts-ignore
        newItem.attributes.color = getColor(item.data[groupBy]).nodeColor

        return newItem
      })
    }

    const parsedData = { nodes: filteredNodes, edges: filteredEdges } as TGraphData

    // Call the function with 'ogma' as the argument, assuming 'ogma' is your Ogma instance
    // transformNodesAndTrackMinMaxWeights(ogma);

    (async () => {
      const ogma = new Ogma(ogmaOptions)
      ogma.setOptions({ renderer: 'canvas' })
      setNoData(false)

      if (!parsedData.nodes.length) {
        setNoData(true)
        return
      }

      setGraphData(parsedData)

      const resetFilter = () => {
        //@ts-ignore
        ogma.getNodes().forEach(n => n.getData('isGroup') && n.setAttributes({ icon: { content: '...', color: COLORS.GRAY }}))
        if (filter) filter.destroy()
        filter = applyFilters({ ogma, filter })
        filter.whenApplied().then(() => {
          currentWeight = 50
        })

        ogma.view.locateGraph({
          easing: 'linear',
          duration: 300
        }).then(() => {
          currentZoom = ogma.view.getZoom()
          defaultZoomLevel = ogma.view.getZoom()
        });
      }
      ogma.setGraph(parsedData)
        // .then(() => new MinimapControl(ogma, {}))
        .then(() => {
          if (!groupBy) {
            // const countPerGroup = new Map();
            // ogma.transformations.addEdgeFilter(n => {
            //   const groupLabel = n.getData('type');

            //   const count = countPerGroup.get(groupLabel) || 0;

            //   // Allow the node if we have less than 5 for this group
            //   if (count < 6) {
            //     countPerGroup.set(groupLabel, count + 1); // Increment the count for the group
            //     return true; // Keep the node
            //   }
            //   return false; // Exclude the node if it doesn't meet basic criteria
            // });

            // ogma.transformations.addNodeFilter(n => {
            //   return (
            //     n.getData('weight') >= 11
            //   );
            // });
            ogma.layouts.force(layoutOptions)
            return
          }

          const byColor = ogma
            .getNodes()
            .getAttribute('color')
            //@ts-ignore
            .reduce((acc: object, color: string) => {
            //@ts-ignore
              acc[color] = []
              return acc
            }, {})

          //@ts-ignore
          ogma.getNodes().forEach(n => byColor[n.getAttribute('color')].push(n.getId()))
          //@ts-ignore
          Object.keys(byColor).forEach(color => ogma.getNodes(byColor[color]).fillData('groupId', color))

          applyGrouping({ ogma, groupBy }).then(() => {
            resetFilter()
          })
        })
      //@ts-ignore
      ogma.styles.addEdgeRule(edgeRule(weightDistribution))
      //@ts-ignore
      ogma.styles.addNodeRule(dynamicNodeRule(weightDistribution))

      resizeGraph(ogma)
      setLocalOgma(ogma)
      ogma.styles.createClass(highlightedClass)
      ogma.styles.createClass(dimmedClass)
      ogma.styles.setSelectedNodeAttributes({ outline: {
        enabled: false,
      }})

      // for click by nodes/edges
      if (!ogma.styles.getClass(CLASS_NAMES.SELECTED)) {
        ogma.styles.createClass(classClickedElement);
      }
      // for click by nodes
      if (!ogma.styles.getClass(CLASS_NAMES.SELECTED_MAIN)) {
        ogma.styles.createClass(classClickedNodeMain);
      }

      ogma.events.on('mouseover', handleGraphHover({ state: hoveredNodeState, ogma }))
      ogma.events.on('mouseout', handleMouseOut({ state: hoveredNodeState }))
      ogma.events.on('click', handleGraphClick({ dispatch, ogma }))

      // // Actions
      // document.getElementById('zoom-reset')!.addEventListener('click', () => {
      //   resetFilter()
      // })
      // document.getElementById('rotate-left-button')!.addEventListener('click', () => {
      //   ogma.view.rotate(Math.PI / 2, { duration: 600 })
      // })
      // document.getElementById('rotate-right-button')!.addEventListener('click', () => {
      //   ogma.view.rotate(-Math.PI / 2, { duration: 600 })
      // })

      // Catch zoom level
      ogma.events.on('viewChanged', () => {
        const zoom = ogma.view.getZoom()
        // console.log('zoom percent', getPercentOfZoom(zoom, defaultZoomLevel))
        const zoomInPercent = ogma.getNodes().size <= 2 ? 10 : 20

        const zoomIn = getPercentOfZoom(zoom, defaultZoomLevel) > zoomInPercent
        const zoomOUT = getPercentOfZoom(zoom, defaultZoomLevel) < 0

        // zoom IN
        if (currentZoom && zoomIn) {
          currentZoom = zoom
          if (filter) filter.destroy()
          filter = applyFilters({ ogma, filter, currentWeight: 0 })
          filter.whenApplied().then(() => {
            currentWeight = 0
            ogma.getNodes().setAttributes({ icon: '' })
          })
          // if (currentWeight === 50) {
          //   ogma.getNodes().setAttributes({ icon: '' })
          //   filter = applyFilters({ ogma, filter, currentWeight })
          //   filter.whenApplied().then(() => currentWeight = 30)
          // }
          //
          // if (currentWeight === 30) {
          //   filter = applyFilters({ ogma, filter, currentWeight })
          //   filter.whenApplied().then(() => currentWeight = 10)
          // }
          //
          // if (currentWeight <= 10) {
          //   filter = applyFilters({ ogma, filter, currentWeight })
          //   filter.whenApplied().then(() => currentWeight = 0)
          // }

        }
        // zoom OUT
        if (currentZoom && zoomOUT) {
          currentZoom = zoom
          if (filter) filter.destroy()
          filter = applyFilters({ ogma, filter })
          filter.whenApplied().then(() => {
            currentWeight = 50
            ogma.getNodes().forEach(n => n.getData('isGroup') && n.setAttributes({ icon: { content: '...', color: COLORS.GRAY }}))
          })

          // if (currentWeight >= 50) {
          //   ogma.getNodes().forEach(n => n.getData('isGroup') && n.setAttributes({ icon: { content: '...', color: COLORS.GRAY }}))
          //   filter = applyFilters({ ogma, filter })
          //   filter.whenApplied().then(() => {
          //     currentWeight = 50
          //   })
          // }
          //
          // if (currentWeight === 30) {
          //   filter = applyFilters({ ogma, filter, currentWeight: 50 })
          //   filter.whenApplied().then(() => currentWeight = 50)
          // }
          //
          // if (currentWeight === 10) {
          //   filter = applyFilters({ ogma, filter, currentWeight: 30 })
          //   filter.whenApplied().then(() => currentWeight = 30)
          // }
          //
          // if (currentWeight === 0) {
          //   filter = applyFilters({ ogma, filter, currentWeight: 10 })
          //   filter.whenApplied().then(() => currentWeight = 10)
          // }
        }
      })
    })()

  }, [
    legend,
    isLoading,
    groupBy,
    JSON.stringify(data),
    JSON.stringify(selectedGraphFilter)
  ])

  useEffect(() => {
    if (!localOgma || !isOpenGraph) return;
    resizeGraph(localOgma)
  }, [isFilterOpen, isOpenGraph, isOpenDocuments])

  const handleResize = () => resizeGraph(localOgma)

  useEffect(() => {
    if (!localOgma) return;
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [localOgma])

  useEffect(() => {
    if (localOgma && !clickedNodeOrEdge) {
      clearSelected(localOgma)
    }
  }, [clickedNodeOrEdge])


  const noResults = noData || data && !data.nodes.length
  return (
    <div className='content__content'>
      <Container spacing={1} selenium-id='graph-filter' className='graph_filter'>
        {/*<GroupBy />*/}
        <GraphFilter graphData={graphData} />
      </Container>
      {/*<Container spacing={1} className='graph_actions' selenium-id='graph_actions'>*/}
      {/*  <ZoomOutMapRoundedIcon id={'zoom-reset'}/>*/}
      {/*  <RotateLeftRoundedIcon id={'rotate-left-button'}/>*/}
      {/*  <RotateRightRoundedIcon id={'rotate-right-button'}/>*/}
      {/*</Container>*/}
      {isLoading  && <LoadingComp className={'mt-32'} />}
      {!isLoading && noResults && <div className={'no-results'}><NoResultsFound title={t('main.document.no_results_title')} /></div>}
      {!isLoading && <div id='graph-container' style={{ height: '100%' }}></div>}
    </div>
  )
}

export default React.memo(GraphLite)
