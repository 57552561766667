import { TRootState } from '../types'

export const selectGEntityTypesSelector = (state: TRootState) => state.mainFilter

export const selectSearchSelector = (state: TRootState) => selectGEntityTypesSelector(state).search
export const selectListSelector = (state: TRootState) => selectGEntityTypesSelector(state).list
export const selectIsFilterLoadingSelector = (state: TRootState) => selectGEntityTypesSelector(state).isLoading
export const selectIsOpenSelector = (state: TRootState) => selectGEntityTypesSelector(state).isOpen
export const selectSelectedFilterItemsSelector = (state: TRootState) => selectGEntityTypesSelector(state).selectedItems
export const selectMltSelector = (state: TRootState) => selectGEntityTypesSelector(state).mlt
export const selectMltModalSelector = (state: TRootState) => selectGEntityTypesSelector(state).showMltModal
export const selectFilterSearchSelector = (state: TRootState) => selectGEntityTypesSelector(state).filterSearch
export const selectDateRangeMode = (state: TRootState) => selectGEntityTypesSelector(state).dateRangeMode
export const selectDateRange = (state: TRootState) => selectGEntityTypesSelector(state).dateRange
export const selectMetaData = (state: TRootState) => selectGEntityTypesSelector(state).metaData
export const selectAppliedFiltersCollapse = (state: TRootState) => selectGEntityTypesSelector(state).appliedFiltersCollapse
