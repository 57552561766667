import { Skeleton, TypographySystem } from '@mui/joy'
import React from 'react'

import { SKELETON_VARIANT } from './types';


type TProps = {
  children?: React.ReactElement,
  width?: number | string,
  height?: number | string,
  level?:  keyof TypographySystem | 'inherit' ,
  variant?: SKELETON_VARIANT
  sx?: any
  loading?: boolean,
}

const SkeletonComp: React.FC<TProps> = ({
  children,
  width,
  height,
  level,
  variant = SKELETON_VARIANT.TEXT ,
  sx,
  loading
}) => {
  return (
    children
      ? <Skeleton width={width} height={height} level={level} variant={variant} sx={sx} loading={loading}>{children}</Skeleton>
      : <Skeleton width={width} height={height} level={level} variant={variant} sx={sx}/>
  )
}

export default React.memo(SkeletonComp)
