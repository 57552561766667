import React from 'react'
import { Select, Option } from '@mui/joy';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { COMPONENT_SIZE, COMPONENT_VARIANT } from '../../utils/mui'

export type TSelectOption = {
  value: string | number,
  label: string,
  object?: object
}
type TProps = {
  seleniumId?: string
  value: string
  options: TSelectOption[],
  onChange: (e: React.SyntheticEvent | null, value: string | null) => void
  size?: COMPONENT_SIZE
  variant?: COMPONENT_VARIANT
  startDecorator?: any
  endDecorator?: any
}
export default function SelectComp({
  seleniumId,
  options,
  value,
  onChange,
  size,
  variant,
  // placeholder,
  startDecorator,
  endDecorator
}: TProps
) {
  return (
    <Select
      selenium-id={seleniumId}
      indicator={<KeyboardArrowDown />}
      value={value}
      onChange={onChange}
      size={size}
      variant={variant}
      // placeholder={placeholder}
      startDecorator={startDecorator}
      endDecorator={endDecorator}
    >
      {options.map((it: TSelectOption) =>
        <Option key={it.value} value={it.value}>{it.label}</Option>
      )}
    </Select>
  )
}