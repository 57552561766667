import axiosInstance from 'api/api';
import API from 'api/urls';

export const sendEmailAPI = async (email: string)=>{
  await axiosInstance.post(API.public.identify, { email })
}

export const validateEmail = (email: string) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}
