import React, { useEffect, useState } from 'react'
import { TSearchProps } from './TSearchProps'
import { Button, Input } from '@mui/joy'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { isMobile } from 'react-device-detect'
import { COMPONENT_VARIANT, COMPONENT_SIZE, COMPONENT_COLOR } from 'utils/mui'

const SearchComp: React.FC<TSearchProps> = ({
  className,
  disabled,
  value,
  onSearch,
  placeholder,
  seleniumId
}) => {
  const [input, setInput] = useState('')

  useEffect(() => {
    setInput(value)
  }, [value])

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(input)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value)
  }

  const handleClear = () => {
    setInput('')
    onSearch('')
  }

  return (
    <Input
      selenium-id={seleniumId}
      sx={{ '--Input-decoratorChildHeight': '34px' }}
      className={className}
      disabled={disabled}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={() => onSearch(input)}
      value={input}
      fullWidth
      onKeyUp={handleKeyPress}
      endDecorator={
        <>
          <Button
            selenium-id={`${seleniumId}_button-clear`}
            size={COMPONENT_SIZE.SMALL}
            variant={COMPONENT_VARIANT.LINK}
            color={COMPONENT_COLOR.NEUTRAL}
            sx={{ margin: 0, borderRadius: 0 }}
            onClick={handleClear}
          >
            <ClearIcon />
          </Button>
          <Button
            selenium-id={`${seleniumId}_button`}
            size={COMPONENT_SIZE.SMALL}
            variant={COMPONENT_VARIANT.PRIMARY}
            sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            onClick={() => onSearch(input)}
          >
            <SearchOutlinedIcon />
          </Button>

        </>
      }
      autoFocus={!isMobile}
    />
  )
}

export default SearchComp
