import React from 'react'
import dayjs from 'dayjs'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';

type TProps = {
  value: [dayjs.ConfigType, dayjs.ConfigType]
  onChange: (date: [dayjs.ConfigType, dayjs.ConfigType]) => void
  minDate?: dayjs.ConfigType
  maxDate?: dayjs.ConfigType
  className?: string,
}
export default function RangePickerComp(
  {
    value,
    onChange,
    className,
    minDate,
    maxDate
  }: TProps) {

  const handleChange = (dates: [dayjs.ConfigType, dayjs.ConfigType], error: any) => {
    const errors = error.validationError.some((e: string) => e === 'invalidDate')
    const notBothDates = dates.some(d => d === null)

    if (errors || notBothDates) return
    onChange(dates)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateRangePicker
        slots={{ field: SingleInputDateRangeField }}
        className={className}
        value={value.length ? value : [null, null]}
        onChange={handleChange}
        // @ts-ignore
        minDate={minDate}
        // @ts-ignore
        maxDate={maxDate}
        // disableFuture
      />
    </LocalizationProvider>
  )
}