import { Node, Edge } from '@linkurious/ogma';
import Person from 'images/icons/graph/Person.svg';
import Company from 'images/icons/graph/Company.svg';
import Location from 'images/icons/graph/Location.svg';
import IGO from 'images/icons/graph/IGO.svg';
import Product from 'images/icons/graph/Product.svg';
import Weapon from 'images/icons/graph/Weapon.svg';
import { COLORS } from 'theme/settings'
import config from 'configuration'
import { IMAGE_PROXY_PARAMS, IMAGE_PROXY_PARAMS_ORG } from 'constants/global'

const fontName = 'Font Awesome 6 Free';
const COLOR_SELECTED = 'red';

const getBadgeIcon = (type: string) => {
  switch (type) {
    case 'person':
      return '\uF007'
    case 'org':
      return '\uF1ad'
    default:
      return null
  }
}

const getIcon = (type: string) => {
  switch (type) {
    case 'person':
      return Person
    case 'org':
      return Company
    case 'Location':
      return Location
    case 'IGO':
      return IGO
    case 'Product':
      return Product
    case 'Weapon':
      return Weapon
    default:
      return undefined
  }
}

export enum NODE_GROUPS {
  dmetrics_mpa = 'dmetrics_mpa',
  dmetrics_fos = 'dmetrics_fos',
  dmetrics_topics = 'dmetrics_topics',
  dtic_coi_all = 'dtic_coi_all',
  dtic_thes = 'dtic_thes',
}
export enum NODE_ROLES {
  coauthor = 'coauthor',
  lead_researcher = 'lead_researcher',
  author = 'author',
  affiliated_person = 'affiliated_person',
  executing_organisation = 'executing_organisation',
  funding_agency = 'funding_agency',
  service_agency = 'service_agency',
  organization = 'organization',
  host_organisation = 'host_organisation',
  cohost = 'cohost',
  recipient_organization = 'recipient_organization',
  performing_activity = 'performing_activity',
}

export const getGroupNames = {
  'rgba(239, 141, 93, 1)': 'Organizations',
  'rgba(116, 171, 226, 1)': 'People',
  'rgba(58, 181, 194, 0.4)': 'Technology Areas',
  'rgba(169, 125, 216,  0.4)': 'Fields of Study',
  'rgba(240, 106, 147, 0.4)': 'Readers',
  'rgba(63, 182, 142, 0.4)': 'Communities of Interest',
  'rgba(191, 255, 228, 0.4)': 'DTIC Thesaurus Topics',
}

export const getRoleNames = {
  'rgba(239, 141, 93, 1)': 'Funding Agencies',
  'rgba(116, 171, 226, 1)': 'Service Agencies',
  'rgba(58, 181, 194, 0.4)': 'Coauthors',
  'rgba(169, 125, 216,  0.4)': 'Lead Researchers',
  'rgba(240, 106, 147, 0.4)': 'Authors',
  'rgba(63, 182, 142, 0.4)': 'Affiliated People',
  'rgba(191, 255, 228, 0.4)': 'Executing Organisations',
  'rgba(105, 115, 246, 0.4)': 'Organizations',
  'rgba(140, 162, 171,  0.4)': 'Host Organisations',
  'rgba(205, 89, 177, 0.4)': 'Cohosts',
  'rgba(243, 135, 135, 0.4)': 'Performance Activities',
  'rgba(77, 120, 162, 0.4)': 'Recipient Organizations',
}


export const getEdgeColor = (type: string) => {
  switch (type) {
    case 'person':
    case 'author':
    case 'coauthor':
    case 'lead_researcher':
      return {
        nodeColor: 'rgba(116, 171, 226, 1)',//azure
        haloColor: 'rgba(116, 171, 226, 0.1)',
      }
    case 'org':
    case NODE_ROLES.funding_agency:
    case NODE_ROLES.recipient_organization:
    case NODE_ROLES.service_agency:
    case NODE_ROLES.cohost:
      return {
        nodeColor: 'rgba(239, 141, 93, 1)',//salmon
        haloColor: 'rgba(239, 141, 93, 0.1)',
      }
    default:
      return {
        nodeColor: 'rgba(63, 182, 142, 1)',//jade
        haloColor: 'rgba(63, 182, 142, 0.1)',
      }

  }
}


export const getColor = (type: string) => {
  switch (type) {
    case 'org':
    case NODE_ROLES.funding_agency:
      return {
        nodeColor: 'rgba(239, 141, 93, 1)',//salmon
        haloColor: 'rgba(239, 141, 93, 0.1)',
      }
    case 'person':
    case NODE_ROLES.service_agency:
      return {
        nodeColor: 'rgba(116, 171, 226, 1)',//azure
        haloColor: 'rgba(116, 171, 226, 0.1)',
      }

    case NODE_GROUPS.dmetrics_mpa:
    case NODE_ROLES.coauthor:
      return {
        nodeColor: 'rgba(58, 181, 194, 0.4)',
        haloColor: 'rgba(58, 181, 194, 0.1)',
        borderColor: 'rgba(58, 181, 194, 1)',
      }
    case NODE_GROUPS.dmetrics_fos:
    case NODE_ROLES.lead_researcher:
      return {
        nodeColor: 'rgba(169, 125, 216,  0.4)',
        haloColor: 'rgba(169, 125, 216,  0.1)',
        borderColor: 'rgba(169, 125, 216, 1)',
      }
    case NODE_GROUPS.dmetrics_topics:
    case NODE_ROLES.author:
      return {
        nodeColor: 'rgba(240, 106, 147, 0.4)',
        haloColor: 'rgba(240, 106, 147, 0.1)',
        borderColor: 'rgba(240, 106, 147, 1)',
      }
    case NODE_GROUPS.dtic_coi_all:
    case NODE_ROLES.affiliated_person:
      return {
        nodeColor: 'rgba(63, 182, 142, 0.4)',
        haloColor: 'rgba(63, 182, 142, 0.1)',
        borderColor: 'rgba(63, 182, 142, 1)',
      }
    case NODE_GROUPS.dtic_thes:
    case NODE_ROLES.executing_organisation:
      return {
        nodeColor: 'rgba(191, 255, 228, 0.4)',
        haloColor: 'rgba(191, 255, 228, 0.1)',
        borderColor: 'rgba(191, 255, 228, 1)',
      }
    case NODE_ROLES.organization:
      return {
        nodeColor: 'rgba(105, 115, 246, 0.4)',
        haloColor: 'rgba(105, 115, 246,0.1)',
        borderColor: 'rgba(105, 115, 246, 1)',
      }
    case NODE_ROLES.host_organisation:
      return {
        nodeColor: 'rgba(140, 162, 171,  0.4)',
        haloColor: 'rgba(140, 162, 171,  0.1)',
        borderColor: 'rgba(140, 162, 171, 1)',
      }
    case NODE_ROLES.cohost:
      return {
        nodeColor: 'rgba(205, 89, 177, 0.4)',
        haloColor: 'rgba(205, 89, 177, 0.1)',
        borderColor: 'rgba(205, 89, 177, 1)',
      }
    case NODE_ROLES.performing_activity:
      return {
        nodeColor: 'rgba(243, 135, 135, 0.4)',
        haloColor: 'rgba(243, 135, 135, 0.1)',
        borderColor: 'rgba(243, 135, 135, 1)',
      }
    case NODE_ROLES.recipient_organization:
      return {
        nodeColor: 'rgba(77, 120, 162, 0.4)',
        haloColor: 'rgba(77, 120, 162, 0.1)',
        borderColor: 'rgba(77, 120, 162, 1)',
      }
    default:
      return {
        nodeColor: 'rgba(63, 182, 142, 1)',//jade
        haloColor: 'rgba(63, 182, 142, 0.1)',
      }
  }
}

const getBadge = (node: Node) => {
  const color = getColor(node.getData('group')).nodeColor;
  const icon = getBadgeIcon(node.getData('group'));
  const imageUrl = node.getData('image')

  return (imageUrl && !imageUrl?.includes('null')) ? {
    color,
    text: {
      font: fontName,
      style: 'bold',
      content: icon,
    },
    stroke: {
      color,
      width: 1,
    },
    scale: 0.4,
  } : undefined
}

export const prepareImg = (url: string, group: string) => {
  // TODO: Uncomment when BE provide logos
  if (group === 'org') return `${config.image_proxy_v2}/${IMAGE_PROXY_PARAMS_ORG}/plain/${url}@png`
  // if (group === 'gpe') return `${config.image_proxy_v2}/${IMAGE_PROXY_PARAMS_GPE}/plain/${url}`

  return `${config.image_proxy_v2}/${IMAGE_PROXY_PARAMS}/plain/${url}`
}

const getImage = (node: Node) => {
  const group = node.getData('group')
  const imageUrl = node.getData('image')

  const url = imageUrl
    ? prepareImg(imageUrl, group)
    : getIcon(group)

  return { url }
}

enum EDGE_TYPE {
  DEPENDENCY = 'dependency',
  ASSOCIATION = 'association',
  FLOW = 'flow',
}

export enum CLASS_NAMES {
  HIGHLIGHTED = 'highlighted',
  DIMMED = 'dimmed',
  SELECTED = 'selected',
  SELECTED_MAIN = 'selected_main',
}

const EDGE_STYLE = {
  DEPENDENCY: {
    shape: {
      body: 'line',
      style: 'plain',
      head: 'arrow',
      tail: null
    },
    color: 'rgba(46, 54, 63, 0.8)',
    text: {
      size: 20,
      color: 'rgba(46, 54, 63, 0.8)'
    }
  },
  ASSOCIATION: {
    shape: {
      body: 'line',
      style: 'dotted',
      head: 'arrow',
      tail: 'arrow'
    },
    color: 'rgba(46, 54, 63, 0.54)',
    text: {
      size: 16,
      color: 'rgba(46, 54, 63, 0.54)'
    }
  },
  FLOW: {
    shape: {
      body: 'line',
      style: 'dotted',
      head: 'arrow',
      tail: null
    },
    color: 'rgba(46, 54, 63, 0.8)',
    text: {
      size: 16,
      color: 'rgba(46, 54, 63, 0.8)'
    }
  }
}

const getEdgeStyle = (type: string) => {
  switch (type) {
    case EDGE_TYPE.DEPENDENCY:
      return EDGE_STYLE.DEPENDENCY
    case EDGE_TYPE.ASSOCIATION:
      return EDGE_STYLE.ASSOCIATION
    case EDGE_TYPE.FLOW:
      return EDGE_STYLE.FLOW
    default:
      return EDGE_STYLE.DEPENDENCY
  }
}

export const ogmaOptions = {
  container: 'graph-container',
  options: {
    // interactions: { zoom: { onDoubleClick: true }}
  },
}

export const layoutOptions = {
  locate: true,
  gpu: true,
  gravity: 0.07,
  charge: 10,
  steps: 300,
}

type NodeType = 'dtic_thes' | 'org' | 'dmetrics_topics' | 'dtic_coi_all' | 'person' | 'dmetrics_mpa' | 'dmetrics_fos';
type EdgeType = 'WORKS_ON' | 'WRITES_ON' | 'FUNDED' | 'GRANT_AWARDED_BY' | 'CO_AUTHOR_WITH' | 'CO_HOST_WITH';

const normalizeNodeRadius = (weight: number, type:NodeType, dist: { [x: string]: { max: any; min: any; }; }) => {
  const nodeCount = dist['count']
  //@ts-ignore
  const nodeMinHit = nodeCount >= 20 ? true : false;
  // console.log(nodeMinHit, nodeCount, 'nodeMinHit')

  if (!dist[type]) {
    console.log('Unknown type', type)
    return 5
  }
  let minSize;
  let maxSize;

  //TODO - We should have this logic be filter aware, filters can change the nodecount
  // With very few nodes, we want to decrease the size of the nodes
  switch (type) {
    case 'person':
      minSize = nodeMinHit ? 4 : 3
      maxSize = nodeMinHit ? 8 : 5

      break;
    case 'org':
      minSize = nodeMinHit ? 5 : 3
      maxSize = nodeMinHit ? 10 : 6
      break;
    default:
      minSize = 2
      maxSize = 5
      break;
  }
  // Min and max sizes for the node


  // Min and max expected weights
  const minWeight = dist[type].min;
  const maxWeight = dist[type].max;

  weight = Math.max(minWeight, Math.min(weight, maxWeight));

  // Scale the weight to the node size range
  const scale = (weight - minWeight) / (maxWeight - minWeight);
  const size = minSize + scale * (maxSize - minSize);

  return size;
}

// type EdgeType = string; // Assuming EdgeType is defined elsewhere

const normalizeEdgeProperties = (weight: number, type: EdgeType, dist: { [x: string]: { max: number; min: number; }; }) => {
  const nodeCount = dist['count']
  //@ts-ignore
  const nodeMinHit = nodeCount >= 20 ? true : false;
  if (!dist[type]) {
    return { width: 5, opacity: 0.2 }; // Return default values if the type is unknown
  }

  // Min and max sizes for the edge width
  const minSize = nodeMinHit ? 0.1 : 0.3;
  const maxSize = 1;

  // Min and max opacity values
  const minOpacity = 0.4;
  const maxOpacity = 1.0;

  // Min and max expected weights
  const minWeight = dist[type].min;
  const maxWeight = dist[type].max;

  // Ensure weight is within expected bounds
  weight = Math.max(minWeight, Math.min(weight, maxWeight));

  // Scale the weight to the edge width range
  const scaleWidth = (weight - minWeight) / (maxWeight - minWeight);
  const width = minSize + scaleWidth * (maxSize - minSize);

  // Scale the weight to the opacity range
  const scaleOpacity = (weight - minWeight) / (maxWeight - minWeight);
  const opacity = minOpacity + scaleOpacity * (maxOpacity - minOpacity);

  return { width, opacity };
};

// const normalizeEdgeWidth = (weight: number, type:EdgeType, dist: { [x: string]: { max: any; min: any; }; }) => {
//   console.log('The edge', dist)
//   if (!dist[type]) {
//     console.log('Unknown type', type)
//     return 5
//   }

//   // Min and max sizes for the node
//   const minSize = 0.6;
//   const maxSize = 1.5;

//   // Min and max expected weights
//   const minWeight = dist[type].min;
//   const maxWeight = dist[type].max;
//   // Ensure weight is within expected bounds
//   weight = Math.max(minWeight, Math.min(weight, maxWeight));

//   // Scale the weight to the edge width range
//   const scale = (weight - minWeight) / (maxWeight - minWeight);
//   const width = minSize + scale * (maxSize - minSize);

//   return width;
// }

// export const nodeRule = {
//   radius: (node: Node) => normalizeNodeRadius(node.getData('weight'), node.getData('group_label')),
//   text: {
//     color: COLORS.TEXT,
//     size: 12,
//     minVisibleSize: 8,
//     backgroundColor: '#fff',
//     content: (node: Node) => {
//       return node.getData('label')
//     },
//   },
//   halo: {
//     color: (node: Node) => getColor(node.getData('group')).haloColor,
//     width: 0,
//   },
//   innerStroke: {
//     color: (node: Node) => {
//       const type = node.getData('type')
//       if (type !== 'entity') return getColor(node.getData('group')).borderColor;
//       return getColor(node.getData('group')).nodeColor
//     },
//     width: 4,
//     minVisibleSize: 5,
//   },
//   badges: {
//     bottomRight: getBadge,
//   },
//   image: getImage,
// }
const nodeLabelSize = (group: string) => {
  if (group === 'org') return 35;
  if (group === 'person') return 30;
  return 15;

}
export const dynamicNodeRule = (dist: any) => {
  return {
    radius: (node: Node) => dist['nodes'] ? normalizeNodeRadius(node.getData('weight'), node.getData('group_label'),  dist['nodes']) : 1,
    text: {
      color: COLORS.TEXT,
      size: 12,
      minVisibleSize:  (node: Node) =>  nodeLabelSize(node.getData('group_label')),
      backgroundColor: '#fff',
      content: (node: Node) => {
        return node.getData('label')
      },
    },
    halo: {
      color: (node: Node) => getColor(node.getData('group')).haloColor,
      width: 0,
    },
    color: (node: Node) => {
      // const isGroup = node.getData('isGroup');
      const groupId = node.getData('groupId');
      return groupId ? groupId :getColor(node.getData('group')).nodeColor
    },
    innerStroke: {
      color: (node: Node) => {
        const type = node.getData('type')
        if (type !== 'entity') return getColor(node.getData('group')).borderColor;
        return getColor(node.getData('group')).nodeColor
      },
      width: 2,
      minVisibleSize: 5,
    },
    badges: {
      bottomRight: getBadge,
    },
    image: getImage,
  }
}

export const edgeRule = (dist: any) => {

  return  {
    width: (edge: Edge) => {
      const edgeData = edge.getData();
      const { width } = normalizeEdgeProperties(edgeData['weight'], edgeData['type'], dist['edges']);
      return width;
    },
    opacity: (edge: Edge) => {
      const edgeData = edge.getData();
      const { opacity } = normalizeEdgeProperties(edgeData['weight'], edgeData['type'], dist['edges']);
      return opacity;
    },
    shape: (edge: Edge) => getEdgeStyle(edge.getData('group')).shape,
    // color: (node: Node) => getColor(node.getData('group')).nodeColor,

    color: (edge: Edge) => {
      const edgeData = edge.getData('additional_fields.source_role');
      const color = getEdgeColor(edgeData).nodeColor
      return color
    },
    text: {
      maxLineLength: 140,
      size: (edge: Edge) => getEdgeStyle(edge.getData('group')).text.size,
      color: (edge: Edge) => getEdgeStyle(edge.getData('group')).text.color,
      minVisibleSize: 3,
      align: 'center',
      backgroundColor: 'white',
      position: 'centered',
      content: (edge: Edge) => edge.getData('type_label'),
    },
  }
}


export const highlightedClass = {
  name: CLASS_NAMES.HIGHLIGHTED,
  nodeAttributes: {
    outerStroke: {
      width: 4,
      color: COLOR_SELECTED
    }
  },
  edgeAttributes: { color: COLOR_SELECTED, opacity: 1 }
}

export const dimmedClass = {
  name: CLASS_NAMES.DIMMED,
  nodeAttributes: {
    color:'rgba(0,0,0,0.1)',
  },
}

export const classClickedElement = {
  name: CLASS_NAMES.SELECTED,
  nodeAttributes: {
    outerStroke: {
      width: 2,
      color: COLOR_SELECTED
    },
  },
  edgeAttributes: { color: COLOR_SELECTED, opacity: 1 }
}

export const classClickedNodeMain = {
  name: CLASS_NAMES.SELECTED_MAIN,
  nodeAttributes: {
    outerStroke: {
      width: 4,
      color: COLOR_SELECTED
    },
  },
}
