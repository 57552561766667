// @ts-nocheck
import { createLogger } from 'redux-logger'
import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ReactGA from 'react-ga4'
import { pdfjs } from 'react-pdf'

import rootReducer from './state/reducer'
import languagesList from './locales'
import config, { DEV_VERSION } from './configuration'
import { LicenseInfo } from '@mui/x-license-pro'

import 'react-toastify/dist/ReactToastify.css'
import '@fortawesome/fontawesome-free/css/all.css'

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import 'pdfjs-dist/web/pdf_viewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

// Google Analytics
if (config.enable_ga) {
  ReactGA.initialize(config.trackingId);
}

// PENDO IO
if (config.enable_pendo) {
  (function(apiKey){
    // eslint-disable-next-line
    (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];

      v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){

        // eslint-disable-next-line prefer-rest-params
        o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);

      y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';

      z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  })(config.pendoApiKey);

  pendo.initialize({
    visitor: {
      id: 'VISITOR-UNIQUE-ID',
    },
    account: {
      id: 'ACCOUNT-UNIQUE-ID',
    },
  })
}

if (config.enable_doorbell) {
  window.doorbellOptions = {
    attachments: [792,456,15],
    hideButton: true,
    id: config.doorbell_id,
    appKey: config.doorbell_apikey
  };
  (function(w, d, t) {
    let hasLoaded = false;
    function l() {
      if (hasLoaded) { return; }
      hasLoaded = true;
      window.doorbellOptions.windowLoaded = true;
      const g = d.createElement(t);
      g.id = 'doorbellScript';
      g.type = 'text/javascript';
      g.crossorigin = 'anonymous';
      g.async = true;
      g.src = 'https://embed.doorbell.io/button/'+window.doorbellOptions['id']+'?t='+(new Date().getTime());
      (d.getElementsByTagName('head')[0]||d.getElementsByTagName('body')[0]).appendChild(g);
    }
    if (w.attachEvent) { w.attachEvent('onload', l); } else if (w.addEventListener) { w.addEventListener('load', l, false); } else { l(); }
    if (d.readyState == 'complete') { l(); }
  }(window, document, 'script'));

}

const initSwitchFav = () => {
  const favicon = document.getElementById('faviconTag');
  const darkMatch = window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');
  const isDarkMode = darkMatch?.matches || false;

  function switchIcon(isDark) {
    favicon.href = `/favicon/${isDark ? 'favicon-dark-scheme.svg' : 'favicon-light-scheme.svg'}`
  }

  switchIcon(isDarkMode);
  darkMatch?.addEventListener('change', (e) => switchIcon(e.matches));
}

document.addEventListener('DOMContentLoaded', initSwitchFav);

const reduxLogger = createLogger({
  collapsed: true,
  colors: {
    title: () => '#18A558',
  },
})

const middlewares = [thunkMiddleware, ...(DEV_VERSION ? [reduxLogger] : [])]

export const store = createStore(rootReducer, compose(applyMiddleware(...middlewares)))

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false
  }
}
i18n.use(initReactI18next).init({
  resources: languagesList,
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  returnNull: false,
})

// MUI-X
LicenseInfo.setLicenseKey(config.muiLicenseKey)

export default function initFunction() {
  return { store }
}
