import { combineReducers } from 'redux'

import userSettings from './userSettings/reducer'
import liteDocuments from './liteDocuments/reducer'
import mainFilter from './mainFilter/reducer'
import graph from './graph/reducer'

const reducerMap = {
  userSettings,
  liteDocuments,
  mainFilter,
  graph,
}

export default combineReducers(reducerMap)
