import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect';
import classnames from 'classnames'
import { Divider } from '@mui/joy'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import config from 'configuration'
import { setMainFilter, setSearch } from 'state/mainFilter/actions'
import { selectSearchSelector } from 'state/mainFilter/selectors'
import { setClickedNodeOrEdge } from 'state/graph/actions';
import SearchComp from 'components/SearchComp'
import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from 'utils/mui'
import TooltipComp from 'components/TooltipComp'
import Container from 'components/Container'
import ButtonComp from 'components/ButtonComp/ButtonCopm'
import FilterButton from 'pages/Public/MainPage/mobile/FilterButton'
import FullHeaderButton from 'pages/Public/MainPage/mobile/FullHeaderButton'

import { ReactComponent as PoweredBy } from 'images/powered_by.svg'
import Logo from 'images/PubLogo.png'
import { DTIC_LOGO_URL } from 'constants/global'
import DateRange from './components/DateRange'

import 'styles/doorbell.scss'
import './style.scss'

type TSearchLiteProps = {
  handleRoot(): void
}

const SearchLite: React.FC<TSearchLiteProps> = ({ handleRoot }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [fullHeader, setFullHeader] = useState(!isMobile);
  const search = useSelector(selectSearchSelector)
  const enableDoorbell = config?.enable_doorbell ?? false;

  const handleSearch = (value: string) => {
    if (search === value) return

    dispatch(setClickedNodeOrEdge(null))
    dispatch(setMainFilter([]))
    dispatch(setSearch(value))
  }

  const handleFeedback = () => {
    // @ts-ignore
    if (!window.doorbell) return;
    // @ts-ignore
    window.doorbell.show();
  }

  const handleClickFullHeader = () => {
    setFullHeader(!fullHeader)
  }

  return (
    <Container className={classnames('search-lite', { 'full_header': fullHeader })}
      spacing={1} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Container className={'search-lite-logo-wrap'} direction={'row'} spacing={1} justifyContent={'space-between'} alignItems={'center'}>
        <div className='search-lite-logo'>
          <a href={DTIC_LOGO_URL} target='_blank' rel='noopener noreferrer'>
            <img src={Logo} alt='' />
          </a>
        </div>
        <FullHeaderButton onClick={handleClickFullHeader} fullHeader={fullHeader}/>
      </Container>
      <Container className='search-lite-search' direction={'row'} spacing={1} >
        <FilterButton />
        <SearchComp
          className='search-lite-input'
          seleniumId='search-lite-input'
          placeholder={t('main.search_bar.input_placeholder')}
          value={search}
          onSearch={handleSearch}
          enterButton
          allowClear
        />
      </Container>
      <Container className={'search-lite-extra'} direction={'row'} alignItems={'center'}>
        <DateRange />
        {!isMobile &&
          <>
            <Divider orientation={'vertical'}/>
            {enableDoorbell &&
              <ButtonComp
                seleniumId='feedback-button'
                size={COMPONENT_SIZE.SMALL}
                type={COMPONENT_VARIANT.LINK}
                color={COMPONENT_COLOR.SUCCESS}
                onClick={handleFeedback}
              >
                <TooltipComp
                  title={t('main.search_bar.feedback_nav')}
                  placement={'bottom'}
                >
                  <SmsOutlinedIcon color={COMPONENT_COLOR.SUCCESS} />
                </TooltipComp>
              </ButtonComp>
            }
            <ButtonComp size={COMPONENT_SIZE.SMALL} type={COMPONENT_VARIANT.LINK} color={COMPONENT_COLOR.NEUTRAL} onClick={handleRoot}>
              <PoweredBy />
            </ButtonComp>
          </>
        }
      </Container>
    </Container>
  )
}

export default React.memo(SearchLite)
