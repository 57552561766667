import React from 'react'
import TextComp, { H_LEVEL } from 'components/TextComp'
import Container from 'components/Container'

type TProps = {
  title?: string
  text: React.ReactNode
}

export default function DocumentContent({ title = undefined, text }: TProps) {
  return (
    <Container spacing={1}>
      {title && <TextComp level={H_LEVEL.H4}>{title}</TextComp>}
      <Container>
        {text}
      </Container>
    </Container>
  )
}
