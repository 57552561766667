import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PostHogProvider } from 'posthog-js/react'
import config from 'configuration'
import { ToastContainer } from 'react-toastify'

import packageJson from '../../package.json';
import initializer from '../init'
import RoutesPage from '../routes'
import ThemeProviderMUI from 'app/ThemeProviderMUI';
import MetaScript from '../meta/MetaScript'
import { AWSWAFCaptchaModal } from '../utils/aws-waf-captcha'
import LoadingComp from '../components/LoadingComp'
import { useAWSCaptchaLoading } from '../state/global/hooks'

const { store } = initializer()

export const APP_VERSION = packageJson.version;

function App() {
  console.log(`Running version ${APP_VERSION}`);
  const enableWAF = config.enabled_waf
  const { appLoading } = useAWSCaptchaLoading(enableWAF)

  const hogOptions = {
    api_host: config.postHogHost,
  }

  return (<>
    <ToastContainer/>
    <Provider store={store}>
      <AWSWAFCaptchaModal/>
      {enableWAF && <MetaScript sources={[config.awsChallengeUrl, config.awsCaptchaUrl]}/>}

      <PostHogProvider
        apiKey={config.postHogApiKey}
        options={hogOptions}
      >
        <BrowserRouter>
          <ThemeProviderMUI>
            {appLoading ? <LoadingComp/> : <RoutesPage/>}
          </ThemeProviderMUI>
        </BrowserRouter>
      </PostHogProvider>
    </Provider>
  </>
  )
}

export default App
