import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { Document, Page } from 'react-pdf'

import { fetchDocumentPDFAction } from 'state/liteDocuments/actions'
import DownloadPDFButton from './DownloadPDF'
import SkeletonLoading from './SkeletonLoading'

import './style.scss'

export const URL_REGEXP = /\b(?:https?:\/\/|www\.)\S+/gi;

function highlightPattern(text: string, pattern: any) {
  const regex = new RegExp(pattern);

  return text.replace(regex, (value) =>{
    const url = value.includes('http') ? value : `//${value}`
    return `<a target="_blank" rel="noopener noreferrer" href="${url}"><span style='cursor: pointer'>${value}</span></a>`
  }

  )
}

type TProps = {
  id: string
}
let source: any
const PdfDocumentComponent = ({ id }: TProps) => {
  const buffer = new ArrayBuffer(0);
  const [data, setData] = useState(buffer);
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState<number>();
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoading || !id) return
    fetchPdf();

    return () => {
      if (source) {
        source.cancel('Documents component unmounted');
      }
    }
  }, [id]);

  const fetchPdf = async () => {
    setIsLoading(true);
    source = axios.CancelToken.source();
    // @ts-ignore
    const pdf = await dispatch(fetchDocumentPDFAction(id, source.token))
    // @ts-ignore
    setData(pdf)
    setIsLoading(false);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setNumPages(numPages);
  }

  const getWidth = () => {
    const clientWidth = window.document.body.clientWidth;
    return clientWidth < 860
      ? clientWidth - 16
      : 800;
  }
  const textRenderer = (textItem: any) => {
    return highlightPattern(textItem.str, URL_REGEXP)
  }


  const pagesContent = useMemo(() => {
    const pages = Array.from({ length: numPages ?? 0 });
    const width = getWidth();

    return pages.map((_, index) => (
      <Page
        // renderTextLayer={false}
        // renderAnnotationLayer={false}
        key={`page_${index + 1}`}
        pageNumber={index + 1}
        width={width}
        //@ts-ignore
        customTextRenderer={textRenderer}
      />
    ));
  }, [numPages]);

  return (
    <div selenium-id={'document-pdf-view'} className='pdf-mode-content'>
      <div className='download-pdf'><DownloadPDFButton id={id} /></div>
      {isLoading
        ? <SkeletonLoading />
        : <Document loading={<SkeletonLoading />} file={data} onLoadSuccess={onDocumentLoadSuccess} onLoadError={console.error}>
          {pagesContent}
        </Document>
      }
    </div>
  );
};

export default React.memo(PdfDocumentComponent);
