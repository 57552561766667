import { isMobile } from 'react-device-detect'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios, { CancelTokenSource } from 'axios'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { searchExecuted } from 'services/tracking.service'
import { ALL_TIME, DATE_LOCALE_FORMAT, DEBOUNCE_DELAY, QUERY_PARAMS } from 'constants/global'
import { fetchDocumentsLiteAction, fetchHistogramAction } from './actions'
import { fetchFullGraphAction, fetchGraphAction } from '../graph/actions'
import { selectDateRange, selectFilterSearchSelector, selectSearchSelector, selectSelectedFilterItemsSelector } from '../mainFilter/selectors'
import { fetchMainFilterAction } from '../mainFilter/actions'
import { selectFetchedDocsCountSelector, selectSortingSelector } from './selectors'
import {
  selectClickedNodeOrEdgeSelector,
  selectSelectedEdgesFilterSelector,
  selectSelectedNodesFilterSelector,
  selectSelectedRolesFilterSelector,
  selectSelectedNodesLimitSelector,
} from '../graph/selectors'

let source: CancelTokenSource
export const useFetchDocuments = () => {
  const dispatch = useDispatch()
  const search = useSelector(selectSearchSelector)
  const filterSearch = useSelector(selectFilterSearchSelector)
  const documentsOffset = useSelector(selectFetchedDocsCountSelector)
  const filter = useSelector(selectSelectedFilterItemsSelector)
  const filterNames = filter.map((it) => it.label)
  const dateRange = useSelector(selectDateRange)
  const readableDateRange = dateRange.length ? dateRange.map((date) => dayjs(date).format(DATE_LOCALE_FORMAT)) : ALL_TIME
  const [firstRender, setFirstRender] = useState(true)
  const [searchParams] = useSearchParams()
  const paramsDocsOffset = searchParams.get(QUERY_PARAMS.DOCUMENTS_OFFSET)
  const selectedNodes = useSelector(selectSelectedNodesFilterSelector)
  const selectedEdges = useSelector(selectSelectedEdgesFilterSelector)
  const selectedRoles = useSelector(selectSelectedRolesFilterSelector)
  const nodeQuantity = useSelector(selectSelectedNodesLimitSelector)
  const clickedNodeOrEdge = useSelector(selectClickedNodeOrEdgeSelector)
  const sort = useSelector(selectSortingSelector)

  // catch first rendering
  useEffect(() => {
    if (!paramsDocsOffset) setFirstRender(false)

    if (firstRender && paramsDocsOffset) {
      if (!documentsOffset) return
      setTimeout(() => {
        // @ts-ignore
        dispatch(fetchDocumentsLiteAction({ query: search, page: 0, limit: documentsOffset, filter, dateRange, sort }))
        setFirstRender(false)
      }, DEBOUNCE_DELAY + 100)
    }
  }, [documentsOffset])

  useEffect(() => {
    if (firstRender && paramsDocsOffset) return

    source = axios.CancelToken.source()
    const delay = setTimeout(() => {
      fetchDocuments()
    }, DEBOUNCE_DELAY)

    return () => {
      clearTimeout(delay)

      if (source) {
        source.cancel('Documents component unmounted')
      }
    }
  }, [search, JSON.stringify(filterNames), JSON.stringify(dateRange), JSON.stringify(clickedNodeOrEdge), sort])

  useEffect(() => {
    if (isMobile) return

    source = axios.CancelToken.source()
    const delay = setTimeout(() => {
      fetchFullGraph()
    }, DEBOUNCE_DELAY)

    return () => {
      clearTimeout(delay)

      if (source) {
        source.cancel('Documents component unmounted')
      }
    }
  }, [
    search,
    JSON.stringify(filterNames),
    JSON.stringify(dateRange),
    sort
    // selectedNodes,
    // selectedEdges,
    // selectedRoles,
    // nodeQuantity
  ])

  useEffect(() => {
    source = axios.CancelToken.source()
    const delay = setTimeout(() => {
      fetchHistogram()
    }, DEBOUNCE_DELAY)

    return () => {
      clearTimeout(delay)

      if (source) {
        source.cancel('Documents component unmounted')
      }
    }
  }, [search, JSON.stringify(filterNames)])

  useEffect(() => {
    source = axios.CancelToken.source()
    const delay = setTimeout(() => {
      fetchFilters()
    }, DEBOUNCE_DELAY)
    return () => {
      clearTimeout(delay)

      if (source) {
        source.cancel('Documents component unmounted')
      }
    }
  }, [search, JSON.stringify(filterNames), JSON.stringify(filterSearch), JSON.stringify(dateRange)])

  const fetchAll = () => {
    fetchFilters()
    fetchHistogram()
    fetchDocuments()
    fetchFullGraph()
  }

  const fetchFilters = () => {
    // @ts-ignore
    dispatch(fetchMainFilterAction(search, source.token, filter, filterSearch, dateRange))
  }

  const fetchHistogram = () => {
    // @ts-ignore
    dispatch(fetchHistogramAction(search, source.token, filter))
  }
  const fetchGraph = () => {
    // @ts-ignore
    dispatch(fetchGraphAction({
      query: search,
      cancelToken: source.token,
      filter,
      dateRange,
      selectedNodes,
      selectedEdges,
      selectedRoles,
      nodeQuantity
    }))
  }

  const fetchFullGraph = () => {
    // @ts-ignore
    dispatch(fetchFullGraphAction({
      query: search,
      cancelToken: source.token,
      filter,
      dateRange,
      sort
    }))
  }
  const fetchDocuments = async () => {
    // @ts-ignore
    const response = await dispatch(fetchDocumentsLiteAction({
      query: search,
      cancelToken: source.token,
      page: 0,
      filter,
      dateRange,
      clickedNodeOrEdge,
      sort
    }))

    searchExecuted(search, filterNames, readableDateRange, response?.total)
  }

  return {
    fetchAll,
    fetchHistogram,
    fetchDocuments,
    fetchFilters,
    fetchGraph,
  }
}
