import ActionTypes from './action-types'
import { createReducer } from '../utils'
import initState from './initState'
import { TUserSettingsState } from './types'
import { TUser } from 'types/types'

const mapReducerRaw = createReducer<TUserSettingsState, string, any>(initState, {
  [ActionTypes.setUser](state, { payload }: { payload: TUser }) {
    return {
      ...state,
      user: payload,
    }
  },
  [ActionTypes.setIsLoading](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      isLoading: payload,
    }
  },
})

export default mapReducerRaw
