import ActionTypes from './action-types'
import { createReducer } from '../utils'
import initState from './initState'
import { TClickedNodeOrEdgeState, TGraphData, TGraphFilter, TGraphState } from './types'
import { GRAPH_GROUP_BY } from 'constants/graph'

const mapReducerRaw = createReducer<TGraphState, string, any>(initState, {
  [ActionTypes.setIsLoading](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      isLoading: payload,
    }
  },

  [ActionTypes.setIsOpen](state, { payload }: { payload: boolean }) {
    return {
      ...state,
      isOpen: payload,
    }
  },
  [ActionTypes.setData](state, { payload }: { payload: TGraphData }) {
    return {
      ...state,
      data: payload,
    }
  },
  [ActionTypes.setGraphFilter](state, { payload }: { payload: TGraphFilter[] }) {
    return {
      ...state,
      filters: payload,
    }
  },
  [ActionTypes.setSelectedGraphFilter](state, { payload }: { payload: object }) {
    return {
      ...state,
      selectedGraphFilter: payload,
      clickedNodeOrEdge: null,
    }
  },
  [ActionTypes.setGraphDistribution](state, { payload }: { payload: any }) {
    return {
      ...state,
      graphDistribution: payload,
    }
  },
  [ActionTypes.setSelectedNodesFilter](state, { payload }: { payload: string[] }) {
    return {
      ...state,
      selectedNodes: payload,
      clickedNodeOrEdge: null,
    }
  },
  [ActionTypes.setSelectedEdgesFilter](state, { payload }: { payload: string[] }) {
    return {
      ...state,
      selectedEdges: payload,
      clickedNodeOrEdge: null,
    }
  },
  [ActionTypes.setSelectedRolesFilter](state, { payload }: { payload: string[] }) {
    return {
      ...state,
      selectedRoles: payload,
      clickedNodeOrEdge: null,
    }
  },
  [ActionTypes.setClickedNodeOrEdgeAction](state, { payload }: { payload: TClickedNodeOrEdgeState }) {
    return {
      ...state,
      clickedNodeOrEdge: payload,
    }
  },
  [ActionTypes.setNodesLimit](state, { payload }: { payload: number }) {
    return {
      ...state,
      nodesLimit: payload,
      clickedNodeOrEdge: null,
    }
  },
  [ActionTypes.setGroupBy](state, { payload }: { payload: GRAPH_GROUP_BY }) {
    return {
      ...state,
      groupBy: payload,
      clickedNodeOrEdge: null,
    }
  },
})

export default mapReducerRaw
