import React from 'react'
import { createPortal } from 'react-dom'

import './styles.scss'

export function AWSWAFCaptchaModal () {
  return (createPortal(
    <div className="overlay" id="modalOverlay">
      <div className="modal" id="modal">
        <div id="captchaForm"/>
      </div>
    </div>, document.body)
  )
}
