/* eslint-disable */
import { isMobile } from 'react-device-detect';
import { GRAPH_FILTER_NODES_LIMIT } from 'constants/global'
import { GRAPH_GROUP_BY } from 'constants/graph'
import { TGraphState } from './types'

const initState: TGraphState = {
  data: null,
  isLoading: false,
  isOpen: !isMobile,
  filters: [],
  selectedGraphFilter: {},
  selectedNodes: [],
  selectedEdges: [],
  selectedRoles: [],
  clickedNodeOrEdge: null,
  nodesLimit: GRAPH_FILTER_NODES_LIMIT,
  groupBy: null,
  graphDistribution: null,
}

export default initState



