import React, { useEffect } from 'react'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/joy'
import { isMobile } from 'react-device-detect'

import { encoded, decoded } from 'utils/global'
import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from 'utils/mui'
import Container from 'components/Container'
import TooltipComp from 'components/TooltipComp'
import { documentFeedback, FEEDBACK_ACTION } from 'services/tracking.service'
import { getStorage, setStorage } from 'services/storage.service'
import { DATE_LOCALE_FORMAT, ALL_TIME } from 'constants/global'
import { TFeedbackStorage } from 'state/liteDocuments/types'
import { selectNegativeFeedbackSelector, selectPositiveFeedbackSelector } from 'state/liteDocuments/selectors'
import { setNegativeFeedback, setPositiveFeedback } from 'state/liteDocuments/actions'
import { selectDateRange, selectSearchSelector, selectSelectedFilterItemsSelector } from 'state/mainFilter/selectors'

import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined'
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined'
import LoadingComp from 'components/LoadingComp';

type TProps = {
  documentId: string
  handleCopy(): void,
  seleniumIdPrefix?: string,
  copied: boolean,
  copiedLoading?: boolean
}
const ActionButtonsGroupComp = ({
  documentId,
  handleCopy,
  seleniumIdPrefix,
  copied,
  copiedLoading = false,
}: TProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const positiveFeedbacks = useSelector(selectPositiveFeedbackSelector)
  const negativeFeedbacks = useSelector(selectNegativeFeedbackSelector)
  const search = useSelector(selectSearchSelector)
  const filter = useSelector(selectSelectedFilterItemsSelector)
  const filterNames = filter.map(it => it.label)
  const dateRange = useSelector(selectDateRange)
  const readableDateRange = dateRange.length ? dateRange.map(date => dayjs(date).format(DATE_LOCALE_FORMAT)) : ALL_TIME
  const uniqQuery = search + filterNames + readableDateRange

  useEffect(() => {
    const initialPositiveFeedback = getStorage(FEEDBACK_ACTION.POSITIVE) ? decoded(getStorage(FEEDBACK_ACTION.POSITIVE)) : []
    const initialNegativeFeedback = getStorage(FEEDBACK_ACTION.NEGATIVE) ? decoded(getStorage(FEEDBACK_ACTION.NEGATIVE)) : []
    dispatch(setPositiveFeedback(initialPositiveFeedback))
    dispatch(setNegativeFeedback(initialNegativeFeedback))
  }, [])

  const onCopy = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    handleCopy()
  }

  const setPositive = (list: TFeedbackStorage[]) => {
    dispatch(setPositiveFeedback(list))
    setStorage(FEEDBACK_ACTION.POSITIVE, encoded(list))
  }

  const setNegative = (list: TFeedbackStorage[]) => {
    dispatch(setNegativeFeedback(list))
    setStorage(FEEDBACK_ACTION.NEGATIVE, encoded(list))
  }

  const clearFeedback = (feedbacks: TFeedbackStorage[], setFeedbacks: (feedbacks: TFeedbackStorage[]) => void) => {
    const list = feedbacks.filter((item: TFeedbackStorage) => !(item.query === uniqQuery && item.documentId === documentId))
    setFeedbacks(list)
  }

  const handleFeedback = (e: any, action: FEEDBACK_ACTION) => {
    e.stopPropagation()

    // update State
    if (action === FEEDBACK_ACTION.POSITIVE) {
      updateState(action, positiveFeedbacks, setPositive)
      clearFeedback(negativeFeedbacks, setNegative)
    }
    if (action === FEEDBACK_ACTION.NEGATIVE) {
      updateState(action, negativeFeedbacks, setNegative)
      clearFeedback(positiveFeedbacks, setPositive)
    }
  }

  const matchedQuery = (item: TFeedbackStorage) => item.query === uniqQuery && item.documentId === documentId
  const updateState = (action: FEEDBACK_ACTION, feedbacks: TFeedbackStorage[], setFeedbacks: (feedbacks: TFeedbackStorage[]) => void) => {
    const target = feedbacks.find(matchedQuery)
    if (target) {
      clearFeedback(feedbacks, setFeedbacks)
    } else {
      const newItem = { documentId, query: uniqQuery } as TFeedbackStorage
      setFeedbacks([...feedbacks, newItem])
      documentFeedback(action, documentId, search, filterNames, readableDateRange)
    }
  }

  const positiveMatched = positiveFeedbacks.some(matchedQuery)
  const negativeMatched = negativeFeedbacks.some(matchedQuery)

  return (
    <Container direction={'row'} className={'action_buttons_group'}>
      <TooltipComp open={isMobile ? copied : undefined} title={copied ? t('main.document.copied') : t('main.document.share_hint')} placement={'top'}>
        <IconButton
          selenium-id={`${seleniumIdPrefix}_copy-url`}
          variant={COMPONENT_VARIANT.DEFAULT}
          color={COMPONENT_COLOR.NEUTRAL}
          size={COMPONENT_SIZE.SMALL}
          onClick={onCopy}
          sx={{ border: 'none', maxWidth: '32px' }}
        >
          {copiedLoading ? <LoadingComp /> : <ShareOutlinedIcon />}
        </IconButton>
      </TooltipComp>
      <TooltipComp title={t('main.document.thumbs_up_hint')} placement={'top'}>
        <IconButton
          selenium-id={`${seleniumIdPrefix}_feedback-positive`}
          color={positiveMatched ? COMPONENT_COLOR.PRIMARY : COMPONENT_COLOR.NEUTRAL}
          variant={positiveMatched ? COMPONENT_VARIANT.PRIMARY : COMPONENT_VARIANT.DEFAULT}
          size={COMPONENT_SIZE.SMALL}
          onClick={e => handleFeedback(e, FEEDBACK_ACTION.POSITIVE)}
          sx={{ border: 'none' }}
        >
          <ThumbUpOutlinedIcon />
        </IconButton>
      </TooltipComp>
      <TooltipComp title={t('main.document.thumbs_down_hint')} placement={'top'}>
        <IconButton
          selenium-id={`${seleniumIdPrefix}_feedback-negative`}
          color={negativeMatched ? COMPONENT_COLOR.DANGER : COMPONENT_COLOR.NEUTRAL}
          variant={negativeMatched ? COMPONENT_VARIANT.PRIMARY : COMPONENT_VARIANT.DEFAULT}
          size={COMPONENT_SIZE.SMALL}
          onClick={e => handleFeedback(e, FEEDBACK_ACTION.NEGATIVE)}
          sx={{ border: 'none' }}
        >
          <ThumbDownOutlinedIcon />
        </IconButton>
      </TooltipComp>
    </Container>
  )
}

export default ActionButtonsGroupComp
