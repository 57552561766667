/* eslint-disable */
import { TLiteDocumentsState } from './types'
import { DOCUMENT_SORTING } from '../../constants/document'

const initState: TLiteDocumentsState = {
  list: [],
  fullDocument: null,
  isLoading: true,
  firstLoading: false,
  isDocLoading: false,
  isOpen: true,
  viewType: '',
  lang: '',
  total: null,
  histogramLoading: true,
  histogram: [],
  mltList: [],
  fetchedDocumentCount: 0,
  isPdfMode: false,
  positiveFeedback: [],
  negativeFeedback: [],
  snippetDocumentId: null,
  sorting: DOCUMENT_SORTING.RELEVANT,
  openedDocumentId: null,
}

export default initState
