import React from 'react'
import TooltipText from 'components/TooltipTextComp'
import TagComp from 'components/TagComp'
import { DOCUMENTS_TAGS_MAX_WIDTH } from 'constants/global'

type TProps = {
  list: string[]
}
export default function DocumentTags({ list }: TProps) {
  return (
    <div>
      {list.map((name: string, index) =>
        <TagComp key={name + index} className={'mr-4 mb-4'}>
          <TooltipText maxWidth={DOCUMENTS_TAGS_MAX_WIDTH}>{name}</TooltipText>
        </TagComp>
      )}
    </div>
  )
}
