import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Snackbar } from '@mui/joy'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { isMobile } from 'react-device-detect'
import { Trans } from 'react-i18next'

import { fetchDocumentIdAction, setOpenedDocumentId } from 'state/liteDocuments/actions';
import { selectIsOpenSelector, selectSearchSelector } from 'state/mainFilter/selectors'
import {
  selectHistogramSelector,
  selectIsHistogramLoadingSelector,
} from 'state/liteDocuments/selectors'
import { useFetchDocuments } from 'state/liteDocuments/hooks'
import { useCheckVersion, useQueryParams } from 'state/global/hooks'

import { COMPONENT_COLOR, COMPONENT_SIZE, COMPONENT_VARIANT } from 'utils/mui'
import SkeletonComp from 'components/SkeletonComp'
import { SKELETON_VARIANT } from 'components/SkeletonComp/types'
import { ReactComponent as PoweredBy } from 'images/powered_by.svg'
import Container from 'components/Container'
import CloseButtonComp from 'components/CloseButtonComp/CloseButtonCopm'
import ButtonComp from 'components/ButtonComp/ButtonCopm'
import StubPage from 'pages/Public/MainPage/mobile/StubPage'
import MltModal from 'pages/Public/MainPage/Documents/MltModal'

import SearchLite from './Search'
import ContentLite from './ContentLite'
import MainFilter from './MainFilter'
import DocumentDetails from './Documents/Details'
import Timeline from './Timeline'
import MetaInfo from '../../../meta/MetaInfo'

import './style.scss'

const MainPage: React.FC = () => {
  const dispatch = useDispatch()
  const { document_indicator } = useParams()

  useQueryParams()
  useFetchDocuments()
  const { newVersion, setNewVersion } = useCheckVersion()

  const isFilterOpen = useSelector(selectIsOpenSelector)
  const histogramData = useSelector(selectHistogramSelector)
  const histogramLoading = useSelector(selectIsHistogramLoadingSelector)
  const search = useSelector(selectSearchSelector)

  const metaTitle = search ? `${search} - Minsky DTIC Search` : 'Minsky DTIC'
  const url = window.location.href

  const handleRoot = () => window.location.replace('/')

  const getDocumentId = () => {
    if (!document_indicator) return;
    dispatch(fetchDocumentIdAction({ document_indicator }))
  }

  useEffect(() => {
    document_indicator
      ? getDocumentId()
      : dispatch(setOpenedDocumentId(null))
  }, [document_indicator])

  return (
    <div className={classNames('main-container', { isMobile })}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={newVersion}
        variant={COMPONENT_VARIANT.DEFAULT}
        color={COMPONENT_COLOR.SUCCESS}
        onClose={() => setNewVersion(false)}
        startDecorator={<ErrorOutlineIcon/>}
        endDecorator={<CloseButtonComp hint={''} onClick={() => setNewVersion(false)} />}
      >
        <Container direction={'row'} alignItems={'center'}>
          <span className={'mr-6'}><Trans i18nKey='global.new_version' /></span>
          <ButtonComp
            type={COMPONENT_VARIANT.PRIMARY}
            color={COMPONENT_COLOR.SUCCESS}
            size={COMPONENT_SIZE.SMALL}
            onClick={() => window.location.reload()}
            sx={{ minWidth: '104px' }}
          >
            <Trans i18nKey='global.refresh' />
          </ButtonComp>
        </Container>
      </Snackbar>

      {isMobile && <StubPage isActive={isFilterOpen} />}
      <MetaInfo title={metaTitle} url={url}/>
      <header className='header'>
        <SearchLite handleRoot={handleRoot}/>
      </header>
      {/*{isMobile &&*/}
      {/*  <Container>*/}
      {/*    <Tabs onChange={handleTabChange} aria-label="mobile tabs" defaultValue={1}>*/}
      {/*      <TabList>*/}
      {/*        <Tab variant={!isOpenGraph ? 'outlined' : 'plain'}>*/}
      {/*          <Trans i18nKey={'mobile.tab_link.graph'}/>*/}
      {/*        </Tab>*/}
      {/*        <Tab variant={isOpenGraph ? 'outlined' : 'plain'}>*/}
      {/*          <Trans i18nKey={'mobile.tab_link.documents'}/>*/}
      {/*        </Tab>*/}
      {/*      </TabList>*/}
      {/*    </Tabs>*/}
      {/*  </Container>*/}
      {/*}*/}
      <Divider />

      <main className={classNames({ filterOpen: isFilterOpen })}>
        <aside className='filter'>
          <MainFilter/>
        </aside>
        <div className='body'>
          <ContentLite />

          <Divider />
          <div className='footer'>
            {histogramLoading
              ? <SkeletonComp width="100%" variant={SKELETON_VARIANT.RECT} height="75%" sx={{ mt: 1 }}/>
              : histogramData.length > 1 && <Timeline data={histogramData} />
            }
            {isMobile &&
              <Container className='footer__powered'>
                <PoweredBy onClick={handleRoot}/>
              </Container>
            }
          </div>
        </div>
      </main>
      <MltModal />
      <DocumentDetails />
    </div>
  )
}

export default MainPage
