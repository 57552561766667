const API = {
  public: {
    getDocuments: 'document/search',
    getDocumentById: (id: string) => `document/${id}`,
    getDocumentId: (document_indicator: string) => `sharing/link/${document_indicator}`,
    getDocumentPDFById: (id: string) => `document/${id}/pdf`,
    getGraph: 'document/graph',
    getFullGraph: 'graph',
    getNode: (id: string) => `document/graph/node/${id}`,
    getMainFilters: 'document/filters',
    getHistogram: 'document/histogram',
    identify: 'identify',
    generateLink: (documentId: string) => `sharing/generate_link/${documentId}`
  },

  private: {},
}

export default API
