import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'

import Spinner from 'components/LoadingComp'
import Container from 'components/Container'
import NoResultsFound from 'components/NoResultsFound'
import {
  selectDocumentListLoadingSelector,
  selectListSelector, selectSortingSelector,
  selectTotalDocsSelector,
} from 'state/liteDocuments/selectors'
import { TDocumentLite } from 'state/liteDocuments/types'
import DocumentItemLite from './Item'
import {
  fetchDocumentsLiteAction,
  generateLink,
  setFullDocLoading,
} from 'state/liteDocuments/actions'
import notificator from 'services/notifications.service';
import { DOCUMENTS_PREVIEW_LIMIT } from 'constants/global'
import {
  selectDateRange,
  selectMltSelector,
  selectSearchSelector,
  selectSelectedFilterItemsSelector,
} from 'state/mainFilter/selectors'
import SkeletonLoading from './SkeletonLoading';

import './style.scss'

const DocumentsListLite: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const search = useSelector(selectSearchSelector)
  const filter = useSelector(selectSelectedFilterItemsSelector)
  const isLoading = useSelector(selectDocumentListLoadingSelector)
  const documents = useSelector(selectListSelector) || []
  const total = useSelector(selectTotalDocsSelector)
  const dateRange = useSelector(selectDateRange)
  const mlt = useSelector(selectMltSelector)
  const sort = useSelector(selectSortingSelector)

  const [page, setPage] = useState<number>(0)
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false)

  const fetchData = async () => {
    if (isLoading) return;
    setPaginationLoading(true)
    const nextPage = page + 1
    setPage(nextPage)
    // @ts-ignore
    await dispatch(fetchDocumentsLiteAction({
      query: search,
      page: nextPage,
      filter,
      dateRange,
      sort
    }))

    setPaginationLoading(false)
  }

  const hasMore = total ? documents.length < total : true
  const navigate = useNavigate();

  const handleClick = async (documentId: string) => {
    dispatch(setFullDocLoading(true))
    const data = await dispatch(generateLink({ documentId }))
    if (!data?.three_words?.length) return notificator.error()

    navigate(`/document/${data?.three_words}${window.location.search}`)
  }

  const noData = !documents.length && !isLoading

  return (
    <Container seleniumId={'document-list'} justifyContent={'center'} direction={'row'} className='documents-list-lite'>
      {noData && <NoResultsFound title={t('main.document.no_results_title')} />}
      {isLoading
        ? <SkeletonLoading/>
        : <InfiniteScroll
          dataLength={documents.length}
          next={fetchData}
          hasMore={hasMore}
          loader={paginationLoading && <Spinner/>}
          scrollableTarget="scrollableDiv"
        >
          {documents.map((item: TDocumentLite) =>
            <DocumentItemLite
              key={item.document_id}
              document={item}
              onClick={handleClick}
              limit={DOCUMENTS_PREVIEW_LIMIT}
              mlt={mlt}
            />)}
        </InfiniteScroll>
      }
    </Container>
  )
}

export default React.memo(DocumentsListLite)
