import React from 'react'

import './style.scss'

export const NotFoundPage = () => {
  return (
    <div className='not-found-page'>
      <h1>Not Found Page (#404)</h1>
      <div className='not-found-page__desc'>go to a links or change url manually</div>
    </div>
  )
}
