import pako from 'pako';
import { AxiosResponse } from 'axios';

const DEBUG_GENERAL_NAME = 'x-Dmetrics-Debug'
const DEBUG_MYSQL_NAME = 'x-Dmetrics-Mysql'
const DEBUG_NEO4J_NAME = 'x-Dmetrics-Neo4j'
const DEBUG_TYPE_SENSE_NAME = 'X-Dmetrics-Typesense'

export default function logDebugHeader(response: AxiosResponse) {
  const isDarkMode = window?.matchMedia && window.matchMedia('(prefers-color-scheme: dark)')?.matches;
  const TEXT_COLOR = isDarkMode ? '#4287f5' : 'blue'
  // @ts-ignore
  const debugGeneralCode = response?.headers?.get(DEBUG_GENERAL_NAME);
  // @ts-ignore
  const debugMysqlCode = response?.headers?.get(DEBUG_MYSQL_NAME);
  // @ts-ignore
  const debugNeo4jCode = response?.headers?.get(DEBUG_NEO4J_NAME);
  // @ts-ignore
  const debugTypeSenseCode = response?.headers?.get(DEBUG_TYPE_SENSE_NAME);

  const getObjectFromGzip = (code: string) => {
    const strData = window.atob(code);
    const charData = strData.split('').map(x => x.charCodeAt(0));
    const binData = new Uint8Array(charData);
    const data = pako.inflate(binData);
    //@ts-ignore
    return String.fromCharCode.apply(null, new Uint16Array(data));
  };

  const renderLogs = (debugName: string, debugInfo: string) => {
    try {
      console.group(`${debugName}:`);
      console.log(`url: %c${response.request.responseURL}`, `color: ${TEXT_COLOR}`);
      console.log(`time: %c${new Date()}`, `color: ${TEXT_COLOR}`);
      console.groupCollapsed('request:');
      console.log('>> ', debugInfo);
      console.groupEnd();
      console.groupEnd();
    } catch (e) {
      console.error(e);
    }
  };

  if (debugGeneralCode) {
    try {
      renderLogs(DEBUG_GENERAL_NAME, getObjectFromGzip(debugGeneralCode));
    } catch (e) {
      console.log(`${debugGeneralCode} debug error`, e)
    }
  }

  if (debugMysqlCode) {
    try {
      renderLogs(DEBUG_MYSQL_NAME, getObjectFromGzip(debugMysqlCode));
    } catch (e) {
      console.log(`${debugMysqlCode} debug error`, e)
    }
  }

  if (debugNeo4jCode) {
    try {
      renderLogs(DEBUG_NEO4J_NAME, getObjectFromGzip(debugNeo4jCode));
    } catch (e) {
      console.log(`${debugNeo4jCode} debug error`, e)
    }
  }

  if (debugTypeSenseCode) {
    try {
      renderLogs(DEBUG_TYPE_SENSE_NAME, getObjectFromGzip(debugTypeSenseCode));
    } catch (e) {
      console.log(`${debugTypeSenseCode} debug error`, e)
    }
  }
}
