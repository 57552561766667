import { factoryAction } from '../utils'

const ACTION = factoryAction('graph')

// ACTION TYPES
const setIsLoading = ACTION('setIsLoading')
const setData = ACTION('setData')
const setIsOpen = ACTION('setIsOpen')
const setGraphFilter = ACTION('setGraphFilter')
const setSelectedGraphFilter = ACTION('setSelectedGraphFilter')
const setSelectedNodesFilter = ACTION('setSelectedNodesFilter')
const setSelectedEdgesFilter = ACTION('setSelectedEdgesFilter')
const setSelectedRolesFilter = ACTION('setSelectedRolesFilter')
const setNodesLimit = ACTION('setNodesLimit')
const setClickedNodeOrEdgeAction = ACTION('setClickedNodeOrEdgeAction')
const setGroupBy = ACTION('setGroupBy')
const setGraphDistribution = ACTION('setGraphDistribution')

const TYPES = {
  setIsLoading,
  setData,
  setIsOpen,
  setGraphFilter,
  setSelectedGraphFilter,
  setSelectedNodesFilter,
  setSelectedEdgesFilter,
  setSelectedRolesFilter,
  setNodesLimit,
  setClickedNodeOrEdgeAction,
  setGroupBy,
  setGraphDistribution
}

export default TYPES
