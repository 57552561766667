import React from 'react'
import CheckboxComp from 'components/CheckboxComp'
import { TMainFilterItem } from 'state/mainFilter/types'
import TextComp, { BODY_LEVEL } from 'components/TextComp'
import { COMPONENT_COLOR } from 'utils/mui'
import { formatNumber } from 'utils/global'
import Container from 'components/Container'

type TProps = {
  parent: TMainFilterItem
  selectedItems: TMainFilterItem[]
  onChange: (e: React.ChangeEvent<HTMLInputElement>, items: TMainFilterItem[]) => void
}
export default function NestedCheckboxes({ parent, onChange, selectedItems }: TProps) {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, item: TMainFilterItem) => {
    const all = [item, ...item.children, ...item.children[0].children]
    onChange(e, all)
  }

  const handleChildren = (e: React.ChangeEvent<HTMLInputElement>, item: TMainFilterItem) => {
    const all = item.children?.length > 0 ? [item, ...item.children] : [item]
    onChange(e, all)
  }

  const children = (parent: TMainFilterItem) => {

    return (
      parent.children.map((it: TMainFilterItem) => (
        <Container sx={{ ml: 4, mt: 1 }} key={it.value}>
          <Container justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
            <CheckboxComp
              seleniumId={`main-filter-item-label_${it.label}`}
              checked={selectedItems.some((item: TMainFilterItem) => it.value === item.value)}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChildren(e, it)}
              label={it.label}
            />
            <TextComp seleniumId={`main-filter-item-count_${it.label}`} color={COMPONENT_COLOR.NEUTRAL} level={BODY_LEVEL.LARGE}>
              {formatNumber(it.count)}
            </TextComp>
          </Container>
          {!!it.children?.length && children(it)}
        </Container>
      ))
    )
  }

  return (
    <Container sx={{ ml: 4 }}>
      <Container justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
        <CheckboxComp
          seleniumId={`main-filter-item-label_${parent.label}`}
          checked={selectedItems.some((it: TMainFilterItem) => it.value === parent.value)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e, parent)}
          label={parent.label}
        />
        <TextComp seleniumId={`main-filter-item-count_${parent.label}`} color={COMPONENT_COLOR.NEUTRAL} level={BODY_LEVEL.LARGE}>
          {formatNumber(parent.count)}
        </TextComp>
      </Container>
      {children(parent)}
    </Container>
  )
}