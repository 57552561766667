import posthog from 'posthog-js'

import { getStorage } from './storage.service'
import { TRIAL_EMAIL_STORAGE_NAME } from '../constants/global'
import { TAllTime } from '../types/types'

const EVENT_NAMES = {
  SEARCH: 'search_executed',
  DOCUMENT_VIEW: 'document_viewed',
  COPY_TEXT: 'text_copied',
  ENCRYPTED_PDF: 'encrypted_pdf',
  DOCUMENT_FEEDBACK: 'document_feedback',
  DOCUMENT_SHARED: 'document_shared',
  DOCUMENT_SHARED_OPENED: 'shared_document_opened',
}

export enum FEEDBACK_ACTION {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export const userIdentify = (email: string) => {
  posthog.identify(posthog.get_distinct_id(), { email })
}

export const searchExecuted = (query: string, filters: string[], date_range: string[] | TAllTime, total_docs: number) => {
  posthog.capture(EVENT_NAMES.SEARCH, {
    user_email: getStorage(TRIAL_EMAIL_STORAGE_NAME),
    query,
    filters,
    date_range,
    total_docs
  })
}

export const documentViewed = (document_id: string, document_type: string, document_title: string, source: string) => {
  posthog.capture(EVENT_NAMES.DOCUMENT_VIEW, {
    user_email: getStorage(TRIAL_EMAIL_STORAGE_NAME),
    document_id,
    document_type,
    document_title,
    source,
  })
}

export const textCopied = (document_id: string, text: string) => {
  posthog.capture(EVENT_NAMES.COPY_TEXT, {
    user_email: getStorage(TRIAL_EMAIL_STORAGE_NAME),
    document_id,
    text,
  })
}

export const encryptedPdf = (document_id: string) => {
  posthog.capture(EVENT_NAMES.ENCRYPTED_PDF, {
    user_email: getStorage(TRIAL_EMAIL_STORAGE_NAME),
    document_id,
  })
}

export const documentFeedback = (action: FEEDBACK_ACTION, document_id: string, query: string, filters: string[], date_range: string[] | TAllTime) => {
  posthog.capture(EVENT_NAMES.DOCUMENT_FEEDBACK, {
    user_email: getStorage(TRIAL_EMAIL_STORAGE_NAME),
    action,
    document_id,
    query,
    filters,
    date_range,
  })
}

export const documentShared = (document_id: string, shared_url: string) => {
  posthog.capture(EVENT_NAMES.DOCUMENT_SHARED, {
    user_email: getStorage(TRIAL_EMAIL_STORAGE_NAME),
    document_id,
    shared_url,
  })
}

export const documentSharedOpened = (shared_url: string) => {
  posthog.capture(EVENT_NAMES.DOCUMENT_SHARED_OPENED, {
    user_email: getStorage(TRIAL_EMAIL_STORAGE_NAME),
    shared_url,
  })
}