import React from 'react'
import { deepmerge } from '@mui/utils'
import {
  CssVarsProvider,
  ThemeVars as JoyThemeVars,
  extendTheme as extendJoyTheme,
} from '@mui/joy/styles';
import { muiTheme, joyTheme } from '../theme/settings'

type TProps = {
  children: React.ReactNode
}
const ThemeProviderMUI: React.FC<TProps> = ({ children }) => {
  const mergedTheme = ({
    ...muiTheme,
    ...joyTheme,
    colorSchemes: deepmerge(muiTheme.colorSchemes, joyTheme.colorSchemes),
    typography: {
      ...muiTheme.typography,
      ...joyTheme.typography
    }
  } as unknown) as ReturnType<typeof extendJoyTheme>;

  mergedTheme.generateCssVars = (colorScheme: any) => ({
    css: {
      ...muiTheme.generateCssVars(colorScheme).css,
      ...joyTheme.generateCssVars(colorScheme).css
    },
    vars: (deepmerge(
      muiTheme.generateCssVars(colorScheme).vars,
      joyTheme.generateCssVars(colorScheme).vars
    ) as unknown) as JoyThemeVars
  });

  mergedTheme.unstable_sxConfig = {
    ...muiTheme.unstable_sxConfig,
    ...joyTheme.unstable_sxConfig
  };

  return (
    <CssVarsProvider theme={mergedTheme}>
      {children}
    </CssVarsProvider>
  )
}

export default ThemeProviderMUI
