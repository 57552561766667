import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { TDocumentTag } from 'state/liteDocuments/types'
import ButtonComp from 'components/ButtonComp/ButtonCopm'
import { DOCUMENTS_TAGS_LIMIT } from 'constants/global'
import { COMPONENT_VARIANT } from 'utils/mui'
import DocumentTags from '../Tags'
import Container from '../../../../../../components/Container'

type TProps = {
  list: TDocumentTag[]
  seleniumId?: string
}
export default function TagsTabsItemComponent({ list, seleniumId }: TProps) {
  const [showMore, setShowMore] = useState<boolean>(false)
  const tags = showMore ? list : list.slice(0, DOCUMENTS_TAGS_LIMIT)
  const showAll = list.length > DOCUMENTS_TAGS_LIMIT

  const handleShowMore = (e: MouseEvent) => {
    e.stopPropagation()
    setShowMore(!showMore)
  }

  return (
    <Container seleniumId={`${seleniumId}_selected_list`}>
      <DocumentTags list={tags.map((it: TDocumentTag) => it.display_label)}/>
      {showAll && (
        <ButtonComp seleniumId={`${seleniumId}_show-all-tags`} type={COMPONENT_VARIANT.LINK} onClick={handleShowMore}>
          {showMore ? <Trans i18nKey={'main.document.hide'} /> : <Trans i18nKey={'main.document.show_all'} />}
        </ButtonComp>
      )}
    </Container>
  )
}