import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SelectComp, { TSelectOption } from 'components/SelectComp'
import { setSorting } from 'state/liteDocuments/actions'
import { selectSortingSelector } from 'state/liteDocuments/selectors'
import { DOCUMENT_SORTING } from 'constants/document'
import { COMPONENT_SIZE } from '../../../../../../../utils/mui'

const sortOptions = [
  {
    value: DOCUMENT_SORTING.RELEVANT,
    label: 'Most Relevant'
  },
  {
    value: DOCUMENT_SORTING.RECENT,
    label: 'Most Recent'
  }
] as TSelectOption[]

const Sorting: React.FC = () => {
  const dispatch = useDispatch()
  const sorting = useSelector(selectSortingSelector)

  const handleChange = (event: React.SyntheticEvent | null, value: string | null) => {
    if (value === null) return

    dispatch(setSorting(value))
  }

  return (
    <SelectComp value={sorting} options={sortOptions} onChange={handleChange} size={COMPONENT_SIZE.SMALL}/>
  )
}

export default React.memo(Sorting)
