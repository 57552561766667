import React from 'react'
import { DOCUMENT_TYPES } from 'constants/document'
import TextComp, { H_LEVEL } from 'components/TextComp'
import Container from 'components/Container'

import './styles.scss'

type TProps = {
  title: React.ReactNode
  text: string
  documentType: string
}
export default function DocumentTypeHeader({ title, text, documentType }: TProps ) {
  const typeHeader = (type: string) => {
    if (type === DOCUMENT_TYPES.R2_PROJECT) {
      return <span className='project-title'>{text}</span>
    }

    return <TextComp level={H_LEVEL.H4}>{text}</TextComp>
  }

  return <Container spacing={1}>{typeHeader((documentType))}{title}</Container>
}
